import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import Wire from "byzantine/src/Wire";
import Institution from "byzantine/src/Institution";
import { Button } from "@narmi/design_system";
import {
  BackCancelButton,
  ContentCard,
  ContextForm,
  FormHeaderWithIcon,
} from "cerulean";
import useActions from "../../../hooks/useActions";
import AccountContext from "../../../contexts/AccountContext";
import InstitutionSettingsContext from "../../../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import RowItem from "./RowItem";
import { useSudoContext, SudoRoutes } from "../../../SudoContext";

const errorIsElevatedAuthError = (error) =>
  error === "Elevated authentication required to confirm this action";

const WireTransferActionReview = () => {
  const { goToPrevious } = useActions();
  const { formData: data, setOriginalRoute } = useSudoContext();
  const { accounts } = useContext(AccountContext);
  const { wire_display_fee } = useContext(InstitutionSettingsContext);
  const { wire_reason_required } = useUserFeatures();
  const navigate = useNavigate();
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
  const { l10n } = useLocalization();
  const previousStep = "/wires";

  const goBack = () => {
    new Institution().getSettings().then((settings) => {
      if (!settings.sudo_mode_required_for_wires) {
        // set original route in SudoContext so SudoContext doesn't reset form
        // unique case in which we are "turning off" sudo mode in the above setting
        setOriginalRoute(previousStep);
        navigate(previousStep);
      } else {
        navigate(SudoRoutes.RequestOTP);
      }
    });
  };

  const goToTransferPage = () => navigate("/");

  const iconComponent = (
    <div className="narmi-transfer-icon-container">
      <span className="narmi-icon-solid-circle" />
      <span className="narmi-icon-wires" />
    </div>
  );

  const onSubmit = (callback) => {
    // constructs a wire with the from_account, amount, and memo fields
    // and adds the recipient details
    const { recipient, memo, ...rest } = data;
    const memoFields = Wire.splitMemo(memo);
    const wire = new Wire({ ...memoFields, ...rest }).to(recipient);
    wire.idempotencyKey = idempotencyKey;
    wire
      .submit()
      .then((response) => {
        let success_message = "success=Wire+sent.";
        if (response.wires[0].state === "awaiting_approval") {
          success_message = "success=Wire+submitted+for+approval.";
        }
        goToPrevious(success_message);
      })
      .catch((error) => {
        if (errorIsElevatedAuthError(error)) {
          callback();
          navigate(SudoRoutes.RequestOTP);
          return;
        }
        callback(error);
        navigate(previousStep);
      })
      .finally(() => {
        setIdempotencyKey(uuidv4());
      });
  };

  if (!data) {
    navigate("/transfer/wires");
    return null;
  }

  const fromDisplayValue = accounts
    .find((a) => a.id === data.from_account)
    ?.getShortDescription();

  return (
    <div className="wire-transfer-container">
      <div className="mobile-web-extra-space">
        <ContextForm data={data}>
          <FormHeaderWithIcon
            headerText={l10n.getString("header-review-your-wire-transfer")}
            iconComponent={iconComponent}
            onBack={goBack}
          />
          <div className="wire-transfer-review-card">
            <ContentCard kind="bordered">
              <RowItem rowName="From" rowValue={fromDisplayValue} />
              <RowItem rowName="To" rowValue={data.recipient?.displayValue} />
              <RowItem rowName="Amount" rowValue={data.amount} />
              {wire_display_fee && (
                <RowItem rowName="Wire fee" rowValue={wire_display_fee} />
              )}
              {wire_reason_required && (
                <RowItem rowName="Wire Reason" rowValue={data.wire_reason} />
              )}
              {data.memo && (
                <RowItem
                  rowName="Memo"
                  rowValue={Object.values(Wire.splitMemo(data.memo)).map(
                    (memoline) => (
                      <div key={memoline}>{memoline}</div>
                    )
                  )}
                  isLeftAligned={true}
                />
              )}
            </ContentCard>
          </div>
          <div className="alignChild--center--center form-buttons-with-spacing">
            <BackCancelButton onBack={goToTransferPage} backLabel="Cancel" />
            <ContextForm.Action onSubmit={onSubmit}>
              <div style={{ marginLeft: "auto" }}>
                <Button label="Make transfer" />
              </div>
            </ContextForm.Action>
          </div>
        </ContextForm>
      </div>
    </div>
  );
};

export default WireTransferActionReview;
