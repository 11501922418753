import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Address from "byzantine/src/Address";
import WireRecipient from "byzantine/src/WireRecipient";
import { Button } from "@narmi/design_system";
import {
  AccountNumberTextInput,
  ContextForm,
  Dialog,
  RoutingNumberTextInput,
  TextInput,
  useFormData,
  useNotificationContext,
  validateRoutingNumber,
} from "cerulean";
import BankingUSAddressField from "../../../address/BankingUSAddressField";
import utils from "../../../../utils";

const AddRecipientDialog = ({
  open,
  handleClose,
  recipients,
  updateTransferFormWithRecipient,
  setRecipients,
}) => {
  const { l10n } = useLocalization();
  const { formData, onChange, setFormData } = useFormData();
  const { sendNotification } = useNotificationContext();
  const [addressErrors, setAddressErrors] = useState({});

  const validateRouting = (routingNumber) =>
    validateRoutingNumber(routingNumber);

  const validateAddress = () => {
    setAddressErrors({});
    const addressFieldErrors = utils.validateAddress(formData);
    if (Object.keys(addressFieldErrors).length !== 0) {
      setAddressErrors(addressFieldErrors);
      return true;
    }
    return "";
  };

  const onCloseDialog = () => {
    setFormData({});
    setAddressErrors({});
    handleClose();
  };

  const onSubmit = (callback) => {
    if (validateAddress()) {
      return;
    }

    WireRecipient.create({
      name: formData.name,
      address: new Address({
        street_address: formData.street_address,
        street_address_2: formData.street_address_2,
        city: formData.city,
        region_code: formData.region_code,
        postal_code: formData.postal_code,
        country_code: Address.COUNTRIES.US,
      }),
      routing_number: formData.routing_number,
      account_number: formData.account_number,
    })
      .then(([recipient]) => {
        sendNotification({
          type: "success",
          text: "Recipient added.",
        });
        setRecipients([...recipients, recipient]);
        updateTransferFormWithRecipient({ recipient });
        handleClose();
        setFormData();
        callback();
      })
      .catch((err) => {
        if (err?.address) {
          setAddressErrors(err.address);
          // fedwire errors can be reported as "non_field_errors"
          if (err.address.non_field_errors) {
            setAddressErrors({ city: err.address.non_field_errors });
          }
        }
        callback(err);
      });
  };

  return (
    <Dialog
      isOpen={open}
      onUserDismiss={onCloseDialog}
      title={"Add a wire recipient"}
    >
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field required style={{ marginTop: "12px" }}>
          <TextInput
            field="name"
            label={l10n.getString("label-name", null, "Name")}
            maxLength={35}
          />
        </ContextForm.Field>
        <BankingUSAddressField
          data={formData || {}}
          errors={addressErrors}
          onChange={onChange}
          onUpdate={onChange}
          showTitle={false}
        />
        <div className="margin--bottom--l">
          <ContextForm.Field required>
            <AccountNumberTextInput
              field="account_number"
              label={l10n.getString(
                "labelAccountNumber",
                null,
                "Account number",
              )}
            />
          </ContextForm.Field>
        </div>
        <ContextForm.Field required validate={validateRouting}>
          <RoutingNumberTextInput
            field="routing_number"
            label={l10n.getString("label-routing", null, "Routing number")}
          />
        </ContextForm.Field>
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <Button
            onClick={onCloseDialog}
            kind="negative"
            type="button"
            label="Cancel"
          />
          <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
            <Button type="submit" label="Add recipient" />
          </ContextForm.Action>
        </div>
      </ContextForm>
    </Dialog>
  );
};

AddRecipientDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
  updateTransferFormWithRecipient: PropTypes.func,
  setRecipients: PropTypes.func,
};

export default AddRecipientDialog;
