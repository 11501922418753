import { useState } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { formatNumber, Tag, useBreakpoints } from "@narmi/design_system";
import Filters from "byzantine/src/filters";
import Transaction, { isABalance } from "byzantine/src/Transaction";
import NewThreadDialog from "./NewThreadDialog";
import TransactionDetailsDialog from "./TransactionDetailsDialog";
import styles from "./RecentTransactionsTable.module.scss";

interface ActivityRowProps {
  transaction: Transaction;
  replyTime: string;
}

const ActivityRow = ({ transaction, replyTime }: ActivityRowProps) => {
  const { l } = useBreakpoints();
  const {
    account,
    creation_date: creationDate,
    description,
    amount,
    resulting_balance: resultingBalance,
    posted_date: postedDate,
  } = transaction;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isReportIssueDialogOpen, setIsReportIssueDialogOpen] = useState(false);

  // need to tack on a `+` sign if the amount is positive since NDS.formatNumber does not do that for us
  const displayedAmount = `${amount > 0 ? "+" : ""}${formatNumber(
    amount / 100,
    "currency",
  )}`;
  // display either resulting balance or estimated resulting balance for pending if we can
  const displayedResultingBalance = isABalance(resultingBalance)
    ? formatNumber((resultingBalance as number) / 100)
    : null;

  const transactionTitle = (
    <div>
      {description}
      {!postedDate && (
        <span className="padding--left--xs">
          <Tag label="Pending" kind="outline" />
        </span>
      )}
    </div>
  );

  return (
    <>
      <button
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            setIsDialogOpen(true);
          }
        }}
        onClick={() => setIsDialogOpen(true)}
        className={cc([
          styles.row,
          "button--reset border--top fontColor--primary",
        ])}
      >
        <div className={styles.col1} data-testid="transaction-title">
          {!l && (
            <div className="fontColor--theme--primary fontSize--s">
              {account?.nickname || account?.name}
            </div>
          )}
          {transactionTitle}
          <div className="fontSize--s fontColor--secondary">
            {Filters.longMonthDayYear(postedDate || creationDate)}
          </div>
        </div>
        {l && (
          <div className={styles.col2}>
            {account?.nickname || account?.name}
          </div>
        )}
        <div className={styles.col3}>{displayedAmount}</div>
      </button>
      <TransactionDetailsDialog
        transaction={transaction}
        transactionTitle={transactionTitle}
        displayedAmount={displayedAmount}
        displayedResultingBalance={displayedResultingBalance}
        isSourceMultipleAccounts={true}
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        ReportIssueCallback={() => {
          setIsReportIssueDialogOpen(true);
        }}
      />
      <NewThreadDialog
        accountNumber={transaction.account?.number}
        transactionId={transaction.id}
        isDialogOpen={isReportIssueDialogOpen}
        closeDialog={() => setIsReportIssueDialogOpen(false)}
        replyTime={replyTime}
      />
    </>
  );
};

interface RecentTransactionsTableProps {
  transactions: Transaction[];
  replyTime: string;
}

/**
 * Forked from `TransactionsTable` so the Recent Activity card on the dashboard
 * may have full rendering control. Helps us avoid `display: contents` and the a11y
 * issues that come with it when the row root element is a `button`
 */
const RecentTransactionsTable = ({
  transactions,
  replyTime,
}: RecentTransactionsTableProps) => {
  const { l } = useBreakpoints();
  const mutatedTransactions = transactions.slice();
  return (
    <div className={styles.table}>
      {l && (
        <div
          className={cc([
            styles.tableHeader,
            "fontSize--xs fontWeight--bold fontColor--heading",
          ])}
        >
          <div className={styles.col1}>DESCRIPTION</div>
          <div className={styles.col2}>ACCOUNT</div>
          <div className={styles.col3}>AMOUNT</div>
        </div>
      )}
      {mutatedTransactions.map((t) => (
        <ActivityRow key={t.id} transaction={t} replyTime={replyTime} />
      ))}
    </div>
  );
};
RecentTransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction)).isRequired,
  replyTime: PropTypes.string,
};

export const EmptyTransactionState = () => (
  /* for the "wow such empty" state when there are no transactions */
  <div className="padding--y--l">
    <div className="fontWeight--bold padding--top--xs">
      You have no transactions
    </div>
    <div className="padding--top--xxs">
      Once you have recent transaction activity, it&apos;ll show up here
    </div>
  </div>
);

export default RecentTransactionsTable;
