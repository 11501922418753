import { memo, useCallback, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { FormSection } from "@narmi/design_system";
import { entities, modules } from "byzantine";
import Address from "byzantine/src/Address";
import WireRecipient from "byzantine/src/WireRecipient";
import {
  AccountNumberTextInput,
  ContextForm,
  RoutingNumberTextInput,
  TextInput,
  useFormData,
  useNotificationContext,
  validateRoutingNumber,
} from "cerulean";

import BankingUSAddressField from "../../../../../../../../components/address/BankingUSAddressField";
import utils from "../../../../../../../../utils";
import DrawerLayout from "../../../../../../../../components/DrawerLayout";
import BackButton from "../../BackButton";

import styles from "./RecipientForm.module.scss";

export interface RecipientFormType extends Partial<API.Address> {
  name: string;
  account_number?: string;
  routing_number?: string;
}

const RecipientForm = () => {
  const params = useParams();

  const { l10n } = useLocalization();
  const { formData, onChange, setFormData } = useFormData();
  const { sendNotification } = useNotificationContext();
  const [addressErrors, setAddressErrors] = useState({});
  const recipientId = params.recipientId as API.WireRecipientId | undefined;

  const existingWireRecipient = entities.wires.useOneWireRecipient(recipientId);

  const navigate = useNavigate();
  const { upsertOne } = entities.wires.useWireRecipients();

  const { setFieldValue } = modules.wireTemplates.upsertTemplateForm.useForm();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onData = useCallback(
    (recipient: API.WireRecipient) => {
      upsertOne(recipient);
      /**
       * Temporary workaround:
       * When navigating back, useEffect overrides form fields with data from Redux.
       * This is usually correct but doesn't handle ephemeral data like a newly created
       * and selected wire recipient. To address this, wait 50 milliseconds before setting
       * the new wire recipient to ensure the form updates correctly.
       */
      setTimeout(() => {
        setFieldValue("wireRecipientId", recipient.id, true);
      }, 50);
      goBack();
      return null;
    },
    [upsertOne, setFieldValue, goBack]
  );

  const validateAddress = () => {
    setAddressErrors({});
    const addressFieldErrors = utils.validateAddress(formData);
    if (Object.keys(addressFieldErrors).length !== 0) {
      setAddressErrors(addressFieldErrors);
      return true;
    }
    return "";
  };

  const onCancel = () => {
    setFormData({});
    setAddressErrors({});
    goBack();
  };

  const onSubmit = (callback?: (error?: Error) => void) => {
    setAddressErrors({});
    if (validateAddress()) {
      return;
    }
    WireRecipient.create({
      name: formData.name,
      address: new Address({
        street_address: formData.street_address,
        street_address_2: formData.street_address_2,
        city: formData.city,
        region_code: formData.region_code,
        postal_code: formData.postal_code,
        country_code: Address.COUNTRIES.US,
      }),
      routing_number: formData.routing_number,
      account_number: formData.account_number,
    })
      .then(([_, rawRecipient]) => {
        sendNotification({
          type: "success",
          text: "Recipient added.",
        });
        onData?.(rawRecipient);
        setFormData();
        callback?.();
      })
      .catch((err) => {
        if (err?.address) {
          setAddressErrors(err.address);
          // fedwire errors can be reported as "non_field_errors"
          if (err.address.non_field_errors) {
            setAddressErrors({ city: err.address.non_field_errors });
          }
        }
        callback?.(err);
      });
  };

  return (
    <div className={styles.formContainer}>
      <ContextForm nativeForm={false} data={formData} onChange={onChange}>
        <DrawerLayout
          onSave={onSubmit}
          saveLabel="Add recipient"
          onCancel={onCancel}
          isContextForm
        >
          <div className={styles.container}>
            <BackButton onClick={goBack} className={styles.back} />
            <h2 className={styles.header}>
              {existingWireRecipient ? "Edit recipient" : "New recipient"}
            </h2>
            <div className="margin--bottom--s">
              <FormSection title="Recipient Details" />
            </div>
            <ContextForm.Field required>
              <TextInput
                field="name"
                label={l10n.getString("label-name", null, "Name")}
                maxLength={35}
              />
            </ContextForm.Field>
            <BankingUSAddressField
              data={formData || {}}
              errors={addressErrors}
              onUpdate={onChange}
              showTitle={false}
            />
            <div className="margin--bottom--s">
              <FormSection title="Wire Bank Details" />
            </div>
            <div className="margin--bottom--l">
              <ContextForm.Field required>
                <AccountNumberTextInput
                  field="account_number"
                  label={l10n.getString(
                    "labelAccountNumber",
                    null,
                    "Account number"
                  )}
                />
              </ContextForm.Field>
            </div>
            <ContextForm.Field required validate={validateRoutingNumber}>
              <RoutingNumberTextInput
                field="routing_number"
                label={l10n.getString("label-routing", null, "Routing number")}
              />
            </ContextForm.Field>
          </div>
        </DrawerLayout>
      </ContextForm>
    </div>
  );
};

export default memo(RecipientForm);
