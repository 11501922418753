import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Filters from "byzantine/src/filters";
import { Button } from "@narmi/design_system";

const Document = ({ document, isHtml }) => {
  const { l10n } = useLocalization();
  const viewUrl = isHtml
    ? document.www_url
    : `${document.www_download_url}?download=0`;
  const documentTitle = `${Filters.longMonthDayYear(
    document.created_at
  )} (${Filters.titlecase(document.description)})`;

  return (
    <div className="document-card">
      <div className="document-title" data-testid="statement-date">
        {documentTitle}
      </div>
      <div
        className="alignChild--right--center"
        // to ensure that buttons are on top of each other in responsive
        style={{ flexWrap: "wrap", gap: "var(--space-s)" }}
      >
        <Button
          kind="secondary"
          as="a"
          target="_blank"
          rel="noreferrer"
          href={viewUrl}
          label={l10n.getString("button-view", null, "View")}
          testId="statement-view-button"
        />
        <Button
          kind="primary"
          as="a"
          href={document.www_download_url}
          label={l10n.getString("button-pdf", null, "Download PDF")}
          testId="statement-download-button"
        />
      </div>
    </div>
  );
};
Document.propTypes = {
  document: PropTypes.object.isRequired,
  isHtml: PropTypes.bool.isRequired,
};

export default Document;
