/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useParams, useNavigate, Route, Routes } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";
import { LoadingSkeleton } from "@narmi/design_system";
import { useNotificationContext } from "cerulean";

import ConfirmDetails from "./ConfirmDetails/ConfirmDetails";
import ReplaceReasonPage from "./ReplaceReasonPage";
import Container from "../../../Container";

const ReplaceACardContainer = () => {
  const [card, setCard] = useState<API.Card | null>(null);
  const { cardId } = useParams();
  const navigate = useNavigate();
  const { sendNotification } = useNotificationContext();

  useEffect(() => {
    const requestCard = async () => {
      try {
        const response = await ApiHttp.fetch<API.Card>(
          `cards/${cardId}`,
          { method: "GET", headers: { "API-Version": "0017" } },
          {},
        );

        if (!response) {
          throw new Error("Card not found");
        }

        setCard(response);
      } catch (error) {
        sendNotification({
          type: "negative",
          text: "There was an error fetching your card.",
        });
        navigate("/cards");
      }
    };

    requestCard();
  }, []);

  if (!card) {
    return null;
  }

  return (
    <LoadingSkeleton isLoading={!card}>
      <Container>
        <Routes>
          <Route
            path="reason"
            element={<ReplaceReasonPage cardId={card.id} />}
          />
          <Route path="confirmation" element={<ConfirmDetails card={card} />} />
        </Routes>
      </Container>
    </LoadingSkeleton>
  );
};

export default ReplaceACardContainer;
