import * as Yup from "yup";
import { DateTime } from "luxon";

import {
  generateRecurrenceRuleString,
  FREQUENCIES,
} from "../../../../Recurrence";
import { createForm } from "../../forms";
import { cents } from "../../../utils";

export type TransferFundsForm = {
  fromAccount: API.AccountId;
  toAccount: API.AccountId;
  amount: Cents;
  agreement: boolean;
  instant: boolean | undefined;
  memo: string | undefined;
  transferUuid: string | undefined;
  date: string | undefined;
  frequency: string | undefined;
};

export const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  toAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  agreement: Yup.boolean()
    .equals([true], "Must agree to transfer terms.")
    .required("Required."),
  instant: Yup.boolean().optional(),
  memo: Yup.string().max(128, "Cannot be more than 128 characters.").optional(),
  transferUuid: Yup.string().optional(),
  date: Yup.string().optional(),
  frequency: Yup.string().optional(),
});

export const initialValues: TransferFundsForm = {
  fromAccount: "" as API.AccountId,
  toAccount: "" as API.AccountId,
  amount: cents(0),
  memo: "",
  agreement: true,
  instant: false,
  transferUuid: undefined,
  date: undefined,
  frequency: undefined,
};

export const transformTransferFundsFormFieldsToApiFields = ({
  fromAccount: from_account_id,
  toAccount: to_account_id,
  amount,
  agreement,
  instant,
  transferUuid: transfer_uuid,
  date,
  frequency,
  memo,
}: TransferFundsForm) => {
  const fields = {
    amount,
    from_account_id,
    to_account_id,
    agreement,
    instant,
    transfer_uuid,
    memo,
  } as API.TransferScheduledRequest;
  const today = DateTime.now().startOf("day");
  const dtDate = date && DateTime.fromISO(date);
  let frequencyVal;
  // atlas and azul frequency selector components return key and value of FREQUENCIES in to in their onChange functions
  // This mapping logic is to support both formats so the same hook can be re-used in both.
  if (frequency && Object.keys(FREQUENCIES).includes(frequency)) {
    frequencyVal = FREQUENCIES[frequency as "ONCE"];
  } else {
    frequencyVal = frequency;
  }
  if (
    !instant &&
    dtDate &&
    (frequencyVal !== FREQUENCIES.ONCE || !dtDate.equals(today))
  ) {
    // only add the recurring rule optional body param for scheduled transfers
    // when all the scheduled fields have been filled in.
    fields.recurring_rule = generateRecurrenceRuleString(frequencyVal, date);
  }

  return fields;
};

export const TransferForm = createForm({
  initialValues,
  validationSchema,
});
