import { useBreakpoints } from "@narmi/design_system";
import { Select, TruncatedAccount } from "cerulean";
import Account from "byzantine/src/Account";

interface LoanSelectorProps {
  loans: Account[];
  onChange?: (val: unknown) => void;
  value?: string;
  error?: string;
  label?: string;
  field?: string;
}

const LoanSelector = ({ loans, onChange, value, error, label="Loan" }: LoanSelectorProps) => {
  const { m } = useBreakpoints();
  return (
    <Select label={label} onChange={onChange} value={value} errorText={error}>
      {loans.map((loan) => {
        const name = loan.getName();
        const maskedNumber = loan.getMaskedNumber();
        const ledgerBalance = loan.balances?.ledger;

        let numberAndBalance = maskedNumber;
        if (m && ledgerBalance) {
          numberAndBalance = `${maskedNumber} (${ledgerBalance})`;
        }

        return (
          <Select.Item key={loan.id} value={loan.id}>
            <TruncatedAccount name={name} lastFour={numberAndBalance} />
          </Select.Item>
        );
      })}
    </Select>
  );
};

export default LoanSelector;
