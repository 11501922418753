import { useEffect, memo } from "react";
import { LoadingSkeleton } from "@narmi/design_system";
import { modules } from "byzantine";
import Container from "../../Container";
import RecipientScreen from "./RecipientScreen";

const ACHPaymentContainer = memo(() => {
  // call all the bootstrap request hooks, specifically `useFetchRecipients` which will populate the store with recipients
  const { loading, load } = modules.achPayments.bootstrap.useBootstrap();
  useEffect(() => {
    load();
  }, [load]);

  return (
    <LoadingSkeleton isLoading={loading}>
      <Container>
        <RecipientScreen />
      </Container>
    </LoadingSkeleton>
  );
});

export default ACHPaymentContainer;
