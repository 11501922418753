import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import { Button } from "@narmi/design_system";
import { ContextForm, Dialog, TextInput, useFormData } from "cerulean";

interface EditAccountNameModalProps {
  name: string;
  dialogOpen: boolean;
  setDialogOpen: (newState: boolean) => void;
  accountUuid: string;
}

const EditAccountNameModal = ({
  name,
  dialogOpen,
  setDialogOpen,
  accountUuid,
}: EditAccountNameModalProps) => {
  const { formData, onChange } = useFormData({
    name,
  });

  const onSubmit = (callback: (error?: string) => void) => {
    ApiHttp.fetch(`accounts/${accountUuid}`, { method: "PUT" }, formData)
      .then(() => {
        window.location.assign(
          `/accounts/${accountUuid}/?success=Your+account+name+has+been+updated.`,
        );
      })
      .catch(() => {
        callback(
          "There was an error updating your account name, please try again later.",
        );
      });
  };
  const onCancel = () => {
    setDialogOpen(false);
  };

  const validateName = (value: string | null) => {
    if (value === null)
      return "New account nickname must be greater than 0 characters";
    if (value.length >= 50)
      return "New account name must be less than 50 characters";
    return null;
  };

  return (
    <Dialog
      title="Edit your account name"
      isOpen={dialogOpen}
      onUserDismiss={() => {
        setDialogOpen(false);
      }}
    >
      <div>This change will take effect everywhere.</div>
      <ContextForm data={formData} onChange={onChange} nativeForm={false}>
        <div className="margin--top--l">
          <ContextForm.Field required validate={validateName}>
            <TextInput field="name" id="name" label="Account name" />
          </ContextForm.Field>
          <div className="margin--top--xl">
            <div className="edit-modal-action-bar">
              <ContextForm.Action
                noValidation
                onSubmit={onCancel}
                dangerouslyDisableShowLoading
              >
                <Button kind="negative" label="Cancel" />
              </ContextForm.Action>
              <div className="margin--left--m">
                <ContextForm.Action onSubmit={onSubmit}>
                  <Button label="Save" />
                </ContextForm.Action>
              </div>
            </div>
          </div>
        </div>
      </ContextForm>
    </Dialog>
  );
};

EditAccountNameModal.propTypes = {
  name: PropTypes.string.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  accountUuid: PropTypes.string.isRequired,
};

export default EditAccountNameModal;
