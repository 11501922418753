import { useNavigate } from "react-router-dom";
import { ResponsiveFlex, Tooltip } from "@narmi/design_system";
import { ProgressButtons, TextInput } from "cerulean";
import { modules } from "byzantine";
import { MAX_MEMO_LENGTH } from "byzantine/src/dbbl/businessLogic/modules/achPayments";
import { useLocalization } from "@fluent/react";
import * as formAdapters from "../../../src/adapters";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import {
  ACH_PAYMENT_REVIEW_ROUTE,
  ACH_PAYMENT_DETAIL_ROUTE,
  ACH_PAYMENT_ROUTE,
} from "./ACHPaymentRoutes";
import { getTotalSteps } from "./RecipientScreen";
import { TransactionTypeSection } from "./PaymentScreen";

const AdditionalDetailsScreen = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const { establishSudo } = useSudoContext();
  const codes = modules.achPayments.useSECCodes();
  const form = modules.achPayments.ACHPaymentForm.useForm();
  const { onContinue } = modules.achPayments.useAdditionalDetails();

  const handleClickNext = () => {
    const handleNext = () => {
      navigate(`/${ACH_PAYMENT_ROUTE}/${ACH_PAYMENT_REVIEW_ROUTE}`);
    };
    const handleTrySudo = () => {
      establishSudo(`/${ACH_PAYMENT_ROUTE}/${ACH_PAYMENT_REVIEW_ROUTE}`);
    };

    onContinue(handleNext, handleTrySudo);
  };

  return (
    <>
      <ResponsiveFlex gapSize="xl">
        <HeaderWithSteps
          headerText={l10n.getString("ach-payment-payment-title")}
          step={3}
          totalSteps={getTotalSteps()}
          icon={
            <Tooltip text={l10n.getString("ach-payment-payment-title-tooltip")}>
              <i
                className="narmi-icon-info"
                style={{
                  fontSize: "var(--font-size-s)",
                }}
              />
            </Tooltip>
          }
        />

        <TransactionTypeSection codes={codes} />

        <ResponsiveFlex gapSize="m">
          <h4 className="fontSize--m nds-sans">
            {l10n.getString("ach-payment-internal-details")}
          </h4>
          <TextInput
            label={l10n.getString("label-note-optional")}
            maxLength={MAX_MEMO_LENGTH}
            {...formAdapters.input(form, "memo")}
          />
        </ResponsiveFlex>

        <ProgressButtons
          backLabel={l10n.getString("button-back")}
          nextLabel={l10n.getString("button-next")}
          onBack={() =>
            navigate(`/${ACH_PAYMENT_ROUTE}/${ACH_PAYMENT_DETAIL_ROUTE}`)
          }
          onNext={handleClickNext}
        />
      </ResponsiveFlex>
    </>
  );
};

export default AdditionalDetailsScreen;
