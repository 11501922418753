import PropTypes from "prop-types";
import { Localized } from "@fluent/react";
import { Button } from "@narmi/design_system";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import SamplePDFLink from "./SamplePDFLink";

interface PaperlessStatementFooterProps {
  setConfirmedEstatementAccess: (hasConfirmedEstatementAccess: boolean) => void;
}

const PaperlessStatementFooter = ({
  setConfirmedEstatementAccess,
}: PaperlessStatementFooterProps) => {
  const institutionSettings = useInstitutionSettings();

  return (
    <>
      <Localized
        id="paperless-statement-terms"
        elems={{
          eSignLink: (
            <Button
              as="a"
              kind="plain"
              href={institutionSettings.esign_url}
              target="_blank"
              rel="noopener noreferrer"
            ></Button>
          ),
          pdfLink: (
            <SamplePDFLink
              onConfirmedEstatementAccess={setConfirmedEstatementAccess}
            />
          ),
        }}
      >
        <>
          {`By enabling paperless statements, you acknowledge that you agree to the
              terms of the <eSignLink>e-Sign Disclosure</eSignLink>, can access the
              <pdfLink>sample PDF</pdfLink>, and that you consent to receive electronic
              communications and other notices for your accounts, as described in the disclosure.`}
        </>
      </Localized>
    </>
  );
};
PaperlessStatementFooter.propTypes = {
  setConfirmedEstatementAccess: PropTypes.func,
};

export default PaperlessStatementFooter;
