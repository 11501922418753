/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { DateTime } from "luxon";

import Address from "./Address";
import ApiHttp from "./ApiHttp";
// eslint-disable-next-line import/no-cycle
import OrganizationUser from "./OrganizationUser";

import type { AnyException } from "./dbbl/composites/request/request.helpers";
import type { ModelStr, Operation } from "./Role";

declare global {
  namespace API {
    type GenericUserFeatures = Record<
      string,
      boolean | string | string[] | Record<string, unknown>
    >;
    type UserFeatures = GenericUserFeatures & {
      wires?: boolean;
      hold_days?: {
        rdc_hold_days?: number;
      };
      allowed_std_ent_cls_codes?: API.ACHPayment.SECCode[];
    };

    type EStatementsSettings =
      | {
          paper_statements: true;
          enabled_estatements_at: null;
        }
      | {
          paper_statements: false;
          enabled_estatements_at: Timestamp;
        };

    interface PhoneNumber {
      number: string;
    }
    type UserId = Brand<string, "UserId">;

    type User = {
      id: UserId;
      email: string;
      core_provided_email: string;
      username: string;
      updated_at: Timestamp;
      last_login: Timestamp;
      first_name: string;
      last_name: string;
      org_name: string;
      // ToDo: Move this to it's own namespace definition
      org_uuid: Brand<string, "OrganizationId"> | null;
      org_role: string | null;
      institution_user_identifier: API.MembershipId;
      is_staff: boolean;
      is_superuser: boolean;
      segment: string;
      addresses: MaybeAddress[];
      phone_numbers: PhoneNumber[];
      features: UserFeatures;
      user_category: "personal" | "business";
      business_permissions: API.Permission[];
      dual_approval_required: boolean;
      is_converting: boolean;
      has_username: boolean;
      has_password: boolean;
      has_accepted_latest_terms: boolean;
      requires_enrollment_code_verification: boolean;
      has_permitted_device: boolean;
      has_backup_codes: boolean;
      paper_statements: boolean;
      enabled_estatements_at: Timestamp | null;
    };

    type AlertId = Brand<string, "Alert">; // Really, this is a uuid type.

    type DeliveryChannel = "email" | "push" | "sms";
    type TransactionAlertKey = "credit" | "debit";
    type AccountAlertKey = "available_balance" | "low_available_balance";
    type LoanAlertKey = "loan_reminder" | "loan_overdue";

    type AlertKey =
      | TransactionAlertKey
      | AccountAlertKey
      | LoanAlertKey
      | "unknown";

    type BaseAlert = {
      id: AlertId;
      user: string;
      alert_key: AlertKey;
      created_at: DateString;
      updated_at: DateString;
      delivery_channels: DeliveryChannel[];
      last_delivered_date: DateString | null;
    };

    interface UserTransactionAlert extends BaseAlert {
      alert_key: TransactionAlertKey;
      transaction_query: string;
    }

    interface UserAccountAlert extends BaseAlert {
      rrule?: string | null;
      alert_key: AccountAlertKey;
      subscribed_accounts: UUID[];
      threshold_amount?: number | null;
      next_alert_at: DateString | null;
    }

    interface UserLoanAlert extends BaseAlert {
      alert_key: LoanAlertKey;
      reminder_days_offset?: number | null;
    }

    type UserAlert = UserTransactionAlert | UserLoanAlert | UserAccountAlert;

    // Secondary resource right based on type of alert.
    type AlertPartition = "transaction" | "account" | "loan";

    type GetUserResponse = {
      users: User[];
    };

    type PermissionId = Brand<string, "PermissionId">;

    interface Permission {
      model_str: ModelStr;
      operation: Operation;
      uuid: PermissionId | API.AccountId | "*";
    }

    namespace ACHPayment {
      type SECCode = "CCD" | "CIE" | "CTX" | "IAT" | "PPD" | "WEB";
      type Error = AnyException;
    }
  }
}

type OptionalUserProps = {
  phone?: API.PhoneNumber;
  addresses?: API.Address[];
  // additional props set from OrganizationUserSerializer
  uuid: API.User["id"];
  organization_uuid: API.User["org_uuid"];
  role: API.User["org_role"];
};

type DeserializedUserFields = {
  addresses: Address[];
  updated_at: Date | undefined;
  last_login: Date | undefined;
};

function coerceToDate(value?: string | number | Date) {
  if (value instanceof Date) return value;
  if (typeof value === "string" && value !== "") return new Date(value);
  if (typeof value === "number") return new Date(value * 1000);
  return undefined;
}

type CoercedUserProps = {
  updated_at: Parameters<typeof coerceToDate>[0];
  last_login: Parameters<typeof coerceToDate>[0];
  addresses: ConstructorParameters<typeof Address>[0][];
};

type UserProps = Omit<
  API.User,
  keyof OptionalUserProps | keyof CoercedUserProps | "addresses"
> &
  OptionalUserProps &
  CoercedUserProps;

type DeserializedUser = Omit<UserProps, keyof DeserializedUserFields> &
  DeserializedUserFields;

interface User extends DeserializedUser {}

class User {
  static PERSONAL = "personal";

  static BUSINESS = "business";

  constructor(props: Partial<UserProps>) {
    Object.assign(this, props);
    // from ProfileSerializer
    if (!this.id && props.uuid) this.id = props.uuid;
    this.updated_at = coerceToDate(props.updated_at);
    this.addresses = props.addresses?.map((a) => new Address(a)) || [];
    this.phone_numbers = props.phone_numbers || [];
    this.features = props.features || {};
    this.org_uuid = props.org_uuid || props.organization_uuid || null;
    this.org_role = props.org_role || props.role || null;
    this.business_permissions = props.business_permissions || [];
    // additional props set from OrganizationUserSerializer
    if (!this.uuid) this.uuid = this.id;
    if (!this.role && props.org_role) this.role = props.org_role;
    if (!this.organization_uuid && props.org_uuid)
      this.organization_uuid = props.org_uuid;
    this.phone =
      props.phone ||
      (Array.isArray(props.phone_numbers) ? props.phone_numbers[0] : undefined);
    if (!this.role && props.org_role) this.role = props.org_role;
    if (!this.organization_uuid && props.org_uuid)
      this.organization_uuid = props.org_uuid;
    this.last_login = coerceToDate(props.last_login);
  }

  getDescription() {
    if (this.first_name || this.last_name) {
      return [this.first_name, this.last_name].filter((x) => x).join(" ");
    }
    return this.email;
  }

  hasEstatements() {
    return !this.paper_statements;
  }

  getShortDescription() {
    return this.first_name || this.last_name || this.username;
  }

  getLastLogin() {
    if (this.last_login) {
      return DateTime.fromJSDate(this.last_login).toFormat("M/d/yyyy");
    }
    return "-";
  }

  static maskEmail(email: string) {
    // fall back to profile email if core provided email does not exist
    if (!email) return "";

    const firstLetterInEmail = email[0];

    // if there are no '@' (which should never happen), return the first letter of email with asterisks
    const emailSplitByAtSign = email.split("@");
    if (emailSplitByAtSign.length < 2) {
      return `${firstLetterInEmail}*****`;
    }

    // if there are multiple '@', we just want what is after the last one
    const domain = emailSplitByAtSign.at(-1) || "";

    const firstLetterOfDomain = domain[0];

    // if there are no periods in the domain, return first letter of email and first letter of domain with asterisks
    const domainSplitByPeriod = domain.split(".");
    if (domainSplitByPeriod.length < 2) {
      return `${firstLetterInEmail}*****@${firstLetterOfDomain}*****`;
    }

    // if there are multiple periods in the domain, we just want what is after the last one
    const topLevelDomain = domainSplitByPeriod.at(-1) || "";

    return `${firstLetterInEmail}*****@${firstLetterOfDomain}*****.${topLevelDomain}`;
  }

  isBusinessAccountHolder() {
    return this.org_role === OrganizationUser.ROLE.ACCOUNT_HOLDER;
  }

  isBusinessAdmin() {
    return (
      this.org_role === OrganizationUser.ROLE.ADMIN ||
      this.isBusinessAccountHolder()
    );
  }

  isPersonalUserOrAccountHolder() {
    return !this.isBusiness() || this.isBusinessAccountHolder();
  }

  isPersonalUserOrBusinessAdmin() {
    return !this.isBusiness() || this.isBusinessAdmin();
  }

  getEnrollmentCodeEmail() {
    // if the enrolling user is a personal user or an account holder, show the masked core_provided_email
    // if the enrolling user is a business user but isn't the account holder, the invitation email was sent to their normal user email (not the core_provided_email)
    const email = this.isPersonalUserOrAccountHolder()
      ? this.core_provided_email
      : this.email;
    return User.maskEmail(email);
  }

  getMaskedEmail() {
    return User.maskEmail(this.core_provided_email || this.email);
  }

  resendEnrollmentCode() {
    return ApiHttp.fetch(`users/${this.uuid}/enrollment_code`, {
      method: "POST",
    });
  }

  updateUsername(newUsername: string) {
    if (!newUsername) return Promise.resolve(this);
    return ApiHttp.fetch<{ user: API.User }>(
      `users/${this.uuid}/username`,
      { method: "POST" },
      { new_username: newUsername },
    ).then((response) => User.deserialize(response.user));
  }

  updatePhoneNumber(newPhoneNumber: string) {
    if (!newPhoneNumber) return Promise.resolve(this);
    return ApiHttp.fetch<{ user: API.User }>(
      `users/${this.uuid}/phone`,
      { method: "PUT" },
      { number: newPhoneNumber },
    ).then((response) => User.deserialize(response.user));
  }

  updatePassword(
    newPassword1: string,
    newPassword2: string,
    oldPassword: string,
  ) {
    if (!newPassword1 || !newPassword2) return Promise.resolve(this);
    return ApiHttp.fetch<{ user: API.User; message: string }>(
      `users/${this.uuid}/password`,
      { method: "POST" },
      {
        new_password1: newPassword1,
        new_password2: newPassword2,
        ...(oldPassword ? { old_password: oldPassword } : null),
      },
    ).then((response) => ({
      message: response.message,
      user: User.deserialize(response.user),
    }));
  }

  updateEmail(newEmail: string) {
    if (!newEmail) return Promise.resolve(this);
    return ApiHttp.fetch(
      `users/${this.uuid}/emails`,
      { method: "POST" },
      { new_email: newEmail },
    );
  }

  updateAddress(address: API.Address) {
    if (!address) return Promise.resolve(this);
    return ApiHttp.fetch<{ user: API.User }>(
      `users/${this.uuid}/address`,
      { method: "PUT" },
      address,
    ).then((response) => User.deserialize(response.user));
  }

  getPhoneDevices() {
    return ApiHttp.fetch(`users/${this.uuid}/mfa_devices`, {
      method: "GET",
    }).then((response) => response.phone_devices);
  }

  addMfaDevice<P extends { type: string }>(payload: P) {
    return ApiHttp.fetch(
      `users/${this.uuid}/mfa_devices`,
      { method: "POST" },
      payload,
    );
  }

  sendEnrollmentCompletedRequest() {
    return ApiHttp.fetch(
      `users/${this.uuid}/enrollment_complete`,
      { method: "POST" },
      { has_estatements: this.hasEstatements() },
    );
  }

  isBusiness() {
    return !!this.organization_uuid;
  }

  async getFundingCards() {
    const response = await ApiHttp.fetch(`users/${this.uuid}/funding`, {
      method: "GET",
    });

    return response?.funding_methods ? response.funding_methods : [];
  }

  async addFundingCard(
    payload: { nonce: string; type: "card" | "ach" | "transfer" },
    token: string,
    secret: string,
  ) {
    const response = await ApiHttp.fetch<{ funding_method: { id: string } }>(
      `users/${this.uuid}/funding`,
      { method: "POST", token, secret },
      payload,
    );

    if (!response?.funding_method)
      throw Error("Unable to use newly added card.");

    return response.funding_method;
  }

  makeLoanPayment(payload: {
    account_uuid: string;
    amount: Cents;
    userfunding_uuid: string;
  }) {
    return ApiHttp.fetch(
      `users/${this.uuid}/payment`,
      { method: "POST" },
      payload,
    );
  }

  static enroll<P extends object>(payload: P) {
    return ApiHttp.fetch<{ user: API.User }>(
      "enroll",
      { method: "POST" },
      payload,
    ).then((response) => User.deserialize(response.user));
  }

  static getUser() {
    return ApiHttp.fetch<{ users: API.User[] }>("me")
      .then((response) => User.deserialize(response.users?.[0]))
      .catch(() => null);
  }

  static verifyEnrollmentCode<P extends object>(payload: P) {
    return ApiHttp.fetch<{ user: API.User }>(
      "enroll_verify",
      { method: "POST" },
      payload,
    ).then((response) =>
      response.user
        ? User.deserialize(response.user)
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject(
            "Could not verify your enrollment code. Please try again.",
          ),
    );
  }

  static deserialize(payload?: null): null;

  static deserialize(payload: API.User): User;

  static deserialize(payload?: null | API.User) {
    if (!payload) return null;
    return new User({
      ...payload,
      addresses:
        payload.addresses && payload.addresses.map((a) => a as API.Address),
    });
  }

  getPrimaryAddress() {
    return this.addresses.find((a) => a.isPrimary());
  }

  getMailingAddress() {
    return this.addresses.find((a) => a.isMailing());
  }
}

export default User;
