import { useState } from "react";
import { useLocalization } from "@fluent/react";

import { Button } from "@narmi/design_system";
import { Dialog } from "cerulean";

const LoanPaymentTypesDialog = ({
  isAmortizedLoan,
}: {
  isAmortizedLoan: boolean;
}) => {
  const { l10n } = useLocalization();
  const [isOpen, setIsOpen] = useState(false);
  const customPaymentDescription = isAmortizedLoan
    ? l10n.getString("loan-paydown-custom-amount-description-amortized")
    : l10n.getString("loan-paydown-custom-amount-description");

  return (
    <div className="margin--top--l">
      <Button
        label={l10n.getString("loan-paydown-payment-types-dialog-launch-button")}
        kind="plain"
        type="button"
        size="s"
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        onUserDismiss={() => setIsOpen(false)}
        title={l10n.getString("loan-paydown-payment-types-dialog-title")}
        isOpen={isOpen}
      >
        <div className="margin--top--s">
          <div className="margin--bottom--l">
            <div className="attribute-label">{l10n.getString("loan-paydown-amount-due")}</div>
            <div className="fontColor--secondary">
              {l10n.getString("loan-paydown-amount-due-description")}
            </div>
          </div>
          <div className="margin--bottom--l">
            <div className="attribute-label">{l10n.getString("loan-paydown-custom-amount")}</div>
            <div className="fontColor--secondary">
              {customPaymentDescription}
            </div>
          </div>
          <div className="margin--bottom--l">
            <div className="attribute-label">{l10n.getString("loan-paydown-principal")}</div>
            <div className="fontColor--secondary">
              {l10n.getString("loan-paydown-principal-only-description")}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LoanPaymentTypesDialog;
