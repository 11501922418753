import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { Checkbox } from "@narmi/design_system";
import { ContextForm, ProgressButtons, useFormData } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const SubmitPreferences = ({
  institution,
  mustAddOrReverifyDeviceOnSubmit,
}) => {
  const { show_checkbox_on_enrollment_preferences_page } = institution;
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({});
  const { currentUser } = useCurrentUser();

  const onFinish = async (callback) => {
    // notify the user that they must add/reverify a device using via the Two-factor authentication component
    // iff mfa is required, email is not a permitted device, and they have not added/verified a device as of the current page load
    if (mustAddOrReverifyDeviceOnSubmit) {
      callback(
        l10n.getString(
          "error-add-mfa-device",
          null,
          "Please add a valid two-factor authentication device to proceed. If you are unable to add one, please contact Support."
        )
      );
      return;
    }
    /*
    makes a call to set is_converting false, effectively concluding the enrollment/conversion process
    will fail if mfa is required and the user doesn't have a permitted device (or backup codes), or if the user is already done converting
    note that if the user is done converting, we would already redirect them into OLB anyway
    */
    currentUser
      .sendEnrollmentCompletedRequest()
      .then(() => {
        // redirect the user to the dashboard upon successful request
        window.location.href = "/dashboard";
      })
      /*
      we do a lot of client-side validation before making the above request,
      but in the event of an error, just present it to the user
      */
      .catch((err) => callback(err));
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      {show_checkbox_on_enrollment_preferences_page && (
        <ContextForm.Field
          className="margin--top--m"
          validate={(value) => {
            if (!value) {
              return l10n.getString(
                "error-accept-preferences",
                null,
                "Please check the box to proceed."
              );
            }
            return "";
          }}
        >
          <Checkbox
            field="reviewTermsPrompt"
            label={l10n.getString(
              "label-accept-preferences",
              null,
              "By checking this box, I agree to the selected preferences."
            )}
          />
        </ContextForm.Field>
      )}
      <div className="progress-buttons-container">
        <ProgressButtons
          onNext={onFinish}
          nextLabel={l10n.getString("button-finish", null, "Finish")}
          onBack={() => {
            if (!institution?.logout_redirect) {
              ApiHttp.fetch("logout", { endpointIsUrl: true }).then(() => {
                window.location.assign("/enroll");
              });
            } else {
              window.open(`/logout`, "_self");
            }
          }}
          backLabel={l10n.getString("button-cancel", null, "Cancel")}
        />
      </div>
    </ContextForm>
  );
};

SubmitPreferences.propTypes = {
  institution: PropTypes.object,
  mustAddOrReverifyDeviceOnSubmit: PropTypes.bool,
};

export default SubmitPreferences;
