import { createDeepEqualSelector } from "../utils";
import { selectUser } from "../selectors";

import type {
  WireDualApproval,
  DualApprovalSlice,
  StatusType,
  UnknownDualApproval,
  PendingWireDualApproval,
  RespondedWireDualApproval,
  ACHDualApproval,
  PendingACHDualApproval,
  RespondedACHDualApproval,
  RespondedWireOrACHDualApproval,
  PendingWireOrACHDualApproval,
  ScheduledACHDualApproval,
  PendingScheduledACHDualApproval,
} from "./types";
import type { RootState } from "../store";

const isWireFilter = (da: UnknownDualApproval): da is WireDualApproval => {
  return "wire" in da && !!da.wire;
};

const isACHFilter = (da: UnknownDualApproval): da is ACHDualApproval => {
  return "ach" in da && !!da.ach;
};

const isScheduledACHFilter = (
  da: UnknownDualApproval,
): da is ScheduledACHDualApproval => {
  return "scheduledACH" in da && !!da.scheduledACH;
};

export const sortDualApprovals = <T extends { created_at: string }>(
  a: T,
  b: T,
) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }

  return 0;
};

export const selectDualApprovalForWire = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
  wireId: API.WireId,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId).filter(
    isWireFilter,
  );

  const dualApproval = dualApprovalValues.find((da) => {
    return da.wire === wireId;
  });

  return dualApproval;
};

export const selectDualApprovalForACH = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
  achId: API.ACHPaymentId,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId).filter(
    isACHFilter,
  );

  const dualApproval = dualApprovalValues.find((da) => {
    return da.ach === achId;
  });

  return dualApproval;
};

export const selectDualApprovalForScheduledACH = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
  achId: API.ACHPaymentId,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId).filter(
    isScheduledACHFilter,
  );

  const dualApproval = dualApprovalValues.find((da) => {
    return da.scheduledACH === achId;
  });

  return dualApproval;
};

export const selectDualApproval = (
  state: RootState,
  id: API.DualApprovalId,
) => {
  return state.dualApprovals.byId[id];
};

export const selectWireDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const filtered = dualApprovalValues
    .filter(isWireFilter)
    .sort(sortDualApprovals);

  return filtered;
};

export const selectACHDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const filtered = dualApprovalValues
    .filter(isACHFilter)
    .sort(sortDualApprovals);

  return filtered;
};

export const selectScheduledACHDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const filtered = dualApprovalValues
    .filter(isScheduledACHFilter)
    .sort(sortDualApprovals);

  return filtered;
};

export const selectAllDualApprovals = <
  S extends { dualApprovals: DualApprovalSlice },
>(
  state: S,
) => {
  const dualApprovalValues = Object.values(state.dualApprovals.byId);

  const sorted = dualApprovalValues.sort(sortDualApprovals);

  return sorted;
};

export const selectPendingDualApprovalsWires = createDeepEqualSelector(
  selectWireDualApprovals,
  (wireDualApprovals): PendingWireDualApproval[] => {
    const filtered = wireDualApprovals
      .filter((da): da is PendingWireDualApproval => {
        return da.responder === null;
      })
      .sort(sortDualApprovals);
    return filtered;
  },
);

export const selectPendingDualApprovalsACH = createDeepEqualSelector(
  selectACHDualApprovals,
  (achDualApprovals) => {
    const filtered = achDualApprovals
      .filter((da): da is PendingACHDualApproval => {
        return da.responder === null;
      })
      .sort(sortDualApprovals);
    return filtered;
  },
);

export const selectPendingDualApprovalsScheduledACH = createDeepEqualSelector(
  selectScheduledACHDualApprovals,
  (achDualApprovals) => {
    const filtered = achDualApprovals
      .filter((da): da is PendingScheduledACHDualApproval => {
        return da.responder === null;
      })
      .sort(sortDualApprovals);
    return filtered;
  },
);

export const selectAllPendingDualApprovalsWireOrACHOrScheduled =
  createDeepEqualSelector(
    [
      selectPendingDualApprovalsWires,
      selectPendingDualApprovalsACH,
      selectPendingDualApprovalsScheduledACH,
    ],
    (wireDAs, achDAs, scheduledACHDAs) => {
      const combined: PendingWireOrACHDualApproval[] = [
        ...wireDAs,
        ...achDAs,
        ...scheduledACHDAs,
      ];
      const filtered = combined.sort(sortDualApprovals);
      return filtered;
    },
  );

export const selectRespondedDualApprovalsWires = createDeepEqualSelector(
  selectWireDualApprovals,
  (wireDualApprovals) => {
    const filtered = wireDualApprovals
      .filter((da): da is RespondedWireDualApproval => {
        return da.responder !== null;
      })
      .sort(sortDualApprovals);

    return filtered;
  },
);

export const selectRespondedDualApprovalsACH = createDeepEqualSelector(
  selectACHDualApprovals,
  (achDualApprovals) => {
    const filtered = achDualApprovals
      .filter((da): da is RespondedACHDualApproval => {
        return da.responder !== null;
      })
      .sort(sortDualApprovals);

    return filtered;
  },
);

export const selectAllRespondedDualApprovalsWireOrACH = createDeepEqualSelector(
  [selectRespondedDualApprovalsWires, selectRespondedDualApprovalsACH],
  (wireDAs, achDAs) => {
    const combined: RespondedWireOrACHDualApproval[] = [...wireDAs, ...achDAs];
    const filtered = combined.sort(sortDualApprovals);
    return filtered;
  },
);

export const selectWireDualApprovalsByStatus = createDeepEqualSelector(
  [selectWireDualApprovals, (_state, status: StatusType) => status],
  (wireDualApprovals, status) => {
    return wireDualApprovals.filter((da) => da.status === status);
  },
);

export const selectACHDualApprovalsByStatus = createDeepEqualSelector(
  [selectACHDualApprovals, (_state, status: StatusType) => status],
  (achDualApprovals, status) => {
    return achDualApprovals.filter((da) => da.status === status);
  },
);

export const selectIsDualApprovalRequired = createDeepEqualSelector(
  selectUser,
  (user) => {
    if (user === null || user === undefined) return false;
    return user.dual_approval_required || false;
  },
);
