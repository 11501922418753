import { RRule } from "rrule";

import type { Weekday } from "rrule";

const weekdayMap = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
] as const;

/**
 * Convert a numbered list of weekdays (0 = Monday, 6 = Sunday)
 * into an iCalendar RRULE string for a weekly recurrence.
 *
 * @param {number[]} weekdays - Array of numbers representing weekdays (0=Mon, 6=Sun)
 * @returns {string} The recurrence rule string.
 */
export function weekdaysToRecurrenceRule(weekdays: number[]): string | null {
  const rruleWeekdays = weekdays
    .map((dayNum) => {
      if (dayNum < 0 || dayNum > 6) {
        return false;
      }
      return weekdayMap[dayNum];
    })
    .filter((dayNum) => !!dayNum) as Weekday[];

  // If empty weekdays list; return null
  if (!rruleWeekdays.length) {
    return null;
  }

  // Create a new RRule for a weekly frequency.
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: rruleWeekdays,
  });

  // Return the string representation of the rule.
  return rule.toString();
}

/**
 * Parses a rrule string and returns the numbered list of weekdays.
 *
 * @param {string} rruleString - The recurrence rule string, e.g. "RRULE:FREQ=WEEKLY;BYDAY=MO,WE,FR"
 * @returns {number[]} Array of numbers representing weekdays (0=Mon, 6=Sun)
 */
export function recurrenceRuleToWeekdays(
  rruleString?: string | null,
): number[] | null {
  try {
    if (!rruleString) return null;

    const rule = RRule.fromString(rruleString);
    const { byweekday } = rule.options;

    return byweekday;
  } catch (error) {
    return null;
  }
}
