import ApiHttp from "./ApiHttp";
import utils from "./utils";

type LoanProps = {
  from_account_id: string;
  to_account_id: string;
  amount: string;
  payment_type: string;
  idempotencyKey: string;
};

class LoanPayment {
  from_account_id;

  to_account_id;

  amount;

  payment_type;

  idempotencyKey;

  constructor(props: LoanProps) {
    this.from_account_id = props.from_account_id;
    this.to_account_id = props.to_account_id;
    this.amount = props.amount;
    this.payment_type =
      props.payment_type === "principal" ? props.payment_type : "default";
    this.idempotencyKey = props.idempotencyKey;
  }

  submit() {
    return ApiHttp.fetch(
      "internal_loan_payments/",
      { method: "POST", headers: { "Idempotency-Key": this.idempotencyKey } },
      {
        amount: utils.dollarsToPennies(this.amount.replace(/[^\d.]/g, "")),
        from_account: this.from_account_id,
        to_account: this.to_account_id,
        payment_type: this.payment_type,
      },
    );
  }
}

export default LoanPayment;
