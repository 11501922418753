import React, { useContext, useEffect } from "react";
import { Toggle, Tooltip, useBreakpoints } from "@narmi/design_system";
import { useLocalization } from "@fluent/react";
import {
  formatNumber,
  TextFit,
} from "cerulean";
import Account from "byzantine/src/Account";

import SectionCard from "../SectionCard";
import AccountContext from "../contexts/AccountContext";

export function calculateBalanceTotal(
  depositAccounts,
  nonHiddenDepositAccounts,
  showHidden,
) {
  const accounts = depositAccounts.filter(
    (acc) => ![...Account.PRODUCT_GROUPS.CDs].includes(acc.product_type),
  );
  let totalBalance = 0;
  if (showHidden) {
    totalBalance = accounts.reduce(
      (total, currentValue) => total + currentValue.availableBalanceAsFloat(),
      0,
    );
  } else {
    totalBalance = nonHiddenDepositAccounts.reduce(
      (total, currentValue) => total + currentValue.availableBalanceAsFloat(),
      0,
    );
  }
  return totalBalance;
}

export function filterDepositAccounts(depositAccounts) {
  return depositAccounts.filter(
    (acc) =>
      acc.hidden === false &&
      ![...Account.PRODUCT_GROUPS.CDs].includes(acc.product_type),
  );
}

const TotalsCard = () => {
  const { l10n } = useLocalization();
  const { depositAccounts } = useContext(AccountContext);
  const hiddenDepositAccountsLength = depositAccounts.filter(
    (acc) =>
      acc.hidden === true && acc.product_type !== "certificate_of_deposit",
  ).length;
  const nonHiddenDepositAccounts = filterDepositAccounts(depositAccounts);
  const [showHidden, setShowHidden] = React.useState(false);
  const [accountsTotal, setAccountsTotal] = React.useState(0);
  const { s } = useBreakpoints();

  useEffect(() => {
    setAccountsTotal(
      calculateBalanceTotal(
        depositAccounts,
        nonHiddenDepositAccounts,
        showHidden,
      ),
    );
  }, [showHidden, nonHiddenDepositAccounts, accountsTotal]);

  return (
    <SectionCard>
      <div className="alignChild--left--center">
        <h4
          className="fontColor--primary fontSize--m fontWeight--normal fontFamily--body"
          data-testid="total-available-cash-header"
        >
          Total available cash
        </h4>
        <Tooltip
          text={l10n.getString("tooltip-total-available-cash")}
          maxWidth={s ? "476px" : "350px"}
        >
          <div className="alignChild--center--center">
            <span className="narmi-icon-info fontSize--xxs padding--left--xxs" />
          </div>
        </Tooltip>
      </div>
      <div
        className="available-balance-header fontWeight--bold"
        data-testid="total-available-cash-amount"
      >
        <TextFit
          ariaLive="polite"
          text={formatNumber(accountsTotal, "currency")}
        />
      </div>
      {hiddenDepositAccountsLength > 0 && (
        <div className="hidden-total" data-testid="hidden-total-toggle">
          <hr />
          <Toggle
            defaultActive={false}
            label="Include hidden accounts"
            onChange={() => {
              setShowHidden((prevState) => !prevState);
            }}
          />
        </div>
      )}
    </SectionCard>
  );
};
TotalsCard.propTypes = {};

export default TotalsCard;
