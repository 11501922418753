/* eslint-disable camelcase */
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Address from "byzantine/src/Address";
import WireRecipient from "byzantine/src/WireRecipient";
import { Button } from "@narmi/design_system";
import {
  AccountNumberTextInput,
  ContextForm,
  Dialog,
  RoutingNumberTextInput,
  TextInput,
  useFormData,
  validateRoutingNumber,
} from "cerulean";
import utils from "../../../../utils";
import BankingUSAddressField from "../../../address/BankingUSAddressField";

interface RecipientFields {
  name: string;
  street_address: string;
  street_address_2: string;
  city: string;
  region_code: string;
  postal_code: string;
  country_code: string;
  routing_number: string;
  account_number: string;
}

const validateAddress = (formData: RecipientFields) => {
  const addressFieldErrors = utils.validateAddress(formData);
  if (Object.values(addressFieldErrors).some((v) => !!v)) {
    return addressFieldErrors;
  }
  return "";
};

interface EditRecipientDialogProps {
  closeDialog: () => void;
  isOpen: boolean;
  updateWireRecipient: (recipient: WireRecipient) => Promise<void>;
  recipient: WireRecipient;
}

const EditRecipientDialog = ({
  closeDialog,
  isOpen,
  updateWireRecipient,
  recipient,
}: EditRecipientDialogProps) => {
  const { l10n } = useLocalization();
  const initialFormData: RecipientFields = {
    name: recipient.name,
    street_address: recipient.address?.street_address,
    street_address_2: recipient.address?.street_address_2,
    city: recipient.address?.city,
    region_code: recipient.address?.region_code,
    postal_code: recipient.address?.postal_code,
    country_code: Address.COUNTRIES.US,
    routing_number: recipient.routing_number,
    account_number: recipient.account_number,
  };
  const { formData, onChange, setFormData } = useFormData(initialFormData);
  const [addressErrors, setAddressErrors] = useState({});

  const onCloseDialog = () => {
    setFormData(initialFormData);
    setAddressErrors({});
    closeDialog();
  };

  const onSubmit = () => {
    const errors = validateAddress(formData);
    if (errors) {
      setAddressErrors(errors);
      return;
    }
    const updatedRecipient = new WireRecipient({
      ...recipient,
      name: formData.name,
      address: new Address({
        street_address: formData.street_address,
        street_address_2: formData.street_address_2,
        city: formData.city,
        region_code: formData.region_code,
        postal_code: formData.postal_code,
        country_code: Address.COUNTRIES.US,
      }),
      routing_number: formData.routing_number,
      account_number: formData.account_number,
    });
    updateWireRecipient(updatedRecipient);
  };
  return (
    <Dialog isOpen={isOpen} onUserDismiss={onCloseDialog} title="Edit details">
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field required style={{ marginTop: "12px" }}>
          <TextInput
            field="name"
            label={l10n.getString("label-name", null, "Name")}
            maxLength={35}
          />
        </ContextForm.Field>
        <BankingUSAddressField
          data={formData || {}}
          errors={addressErrors}
          onUpdate={onChange}
          showTitle={false}
        />
        <div className="margin--bottom--l">
          <ContextForm.Field required>
            <AccountNumberTextInput
              field="account_number"
              label={l10n.getString(
                "labelAccountNumber",
                null,
                "Account number",
              )}
            />
          </ContextForm.Field>
        </div>
        <ContextForm.Field required validate={validateRoutingNumber}>
          <RoutingNumberTextInput
            field="routing_number"
            label={l10n.getString("label-routing", null, "Routing number")}
          />
        </ContextForm.Field>
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <Button
            onClick={onCloseDialog}
            kind="negative"
            type="button"
            label="Cancel"
          />
          <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
            <Button label="Save changes" />
          </ContextForm.Action>
        </div>
      </ContextForm>
    </Dialog>
  );
};
EditRecipientDialog.propTypes = {
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
  updateTransferFormWithRecipient: PropTypes.func,
  setRecipients: PropTypes.func,
};

export default EditRecipientDialog;
