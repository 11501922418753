import { useMemo } from "react";
import { useLocalization } from "@fluent/react";
import { Row } from "cerulean";
import { useBreakpoints, Tag } from "@narmi/design_system";
import styles from "./HeaderWithSteps.module.scss";

type HeaderWithStepsProps = {
  headerText: string;
  subheaderText?: React.ReactNode;
  step?: number | null;
  totalSteps?: number | null;
  icon?: React.ReactElement;
};

const HeaderWithSteps = ({
  headerText,
  subheaderText,
  step,
  totalSteps,
  icon,
}: HeaderWithStepsProps) => {
  const { l10n } = useLocalization();
  const { s } = useBreakpoints();

  // on extra small screens, we render the step number above the header, rather than to it's right
  const shouldRenderMobileSteps = !s;

  const renderedStep = useMemo(
    () =>
      step && totalSteps ? (
        <Tag
          label={l10n.getString("step-number", {
            current: step,
            total: totalSteps,
          })}
        />
      ) : null,
    [l10n, step, totalSteps],
  );

  return (
    <div className={styles.container}>
      <Row alignItems="center">
        <Row.Item>
          {shouldRenderMobileSteps && step && totalSteps && (
            <div className="margin--bottom--l">{renderedStep}</div>
          )}
          <div className={styles.headerContainer}>
            <h4>{headerText} </h4>
            {icon ? <span className={styles.icon}>{icon}</span> : null}
          </div>
        </Row.Item>
        {!shouldRenderMobileSteps && step && (
          <Row.Item shrink>{renderedStep}</Row.Item>
        )}
      </Row>
      {subheaderText && (
        <div className={styles.subheaderText}>{subheaderText}</div>
      )}
    </div>
  );
};

export default HeaderWithSteps;
