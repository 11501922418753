import { useContext } from "react";
import { DateTime } from "luxon";
import { useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import {
  BackCancelButton,
  ContextForm,
  FormHeaderWithIcon,
} from "cerulean";
import Account from "byzantine/src/Account";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import AccountContext from "../../../contexts/AccountContext";
import { TRANSLATED_FREQUENCIES } from "../../FrequencySelector";
import LoanReviewRow from "../LoanReviewRow";
import { PaymentType } from "./LoanPaydownForm";
import type { LoanForm } from "./LoanPaydownForm";

interface LoanPaydownActionReviewProps {
  data: LoanForm;
  goBack: () => void;
  cancel: () => void;
  onSubmit: (callback: (arg?: unknown) => void) => void;
}

const LoanPaydownActionReview = ({
  data,
  goBack,
  cancel,
  onSubmit,
}: LoanPaydownActionReviewProps) => {
  const { l10n } = useLocalization();
  const { accounts }: { accounts: Account[] } = useContext(AccountContext);
  const fromAccount = accounts.find(
    (a: Account) => a.id === data.from_account_id
  );
  const loanAccount = accounts.find(
    (a: Account) => a.id === data.to_account_id
  );

  let displayedPaymentType = l10n.getString("loan-paydown-payment-title", {
    loan: loanAccount?.getShortDescription() || "",
  });
  let displayedDate = DateTime.fromFormat(data.date, "M/d/yyyy").toFormat(
    "MM/dd/yyyy"
  );
  let displayedFrequency =
    data.frequency as (typeof FREQUENCIES)[keyof typeof FREQUENCIES];
  if (data.payment_type === PaymentType.Principal) {
    displayedPaymentType = l10n.getString(
      "loan-paydown-principal-payment-title",
      { loan: loanAccount?.getShortDescription() || "" }
    );
    displayedDate = DateTime.now().toFormat("MM/dd/yyyy");
    displayedFrequency = FREQUENCIES.ONCE;
  }

  let dateLabel = l10n.getString("label-review-date-once");
  if (
    data.payment_type !== PaymentType.Principal &&
    data.frequency !== FREQUENCIES.ONCE
  ) {
    dateLabel = l10n.getString("label-review-date-recurring");
  }

  return (
    <div className="loan-payment-page">
      <FormHeaderWithIcon
        headerText={l10n.getString("loan-paydown-review-page-heading")}
        onBack={goBack}
      />
      <div className="loan-payment-form-card">
        <h4 className="nds-sans">{displayedPaymentType}</h4>

        <div className="margin--top--xl">
          <LoanReviewRow
            label={l10n.getString("label-from")}
            content={fromAccount?.getShortDescription()}
          />
          <LoanReviewRow
            label={l10n.getString("label-amount")}
            content={data.amount}
          />
          {displayedFrequency !== FREQUENCIES.ONCE && (
            <LoanReviewRow
              label={l10n.getString("label-frequency")}
              content={l10n.getString(
                TRANSLATED_FREQUENCIES[displayedFrequency]
              )}
            />
          )}
          <LoanReviewRow label={dateLabel} content={displayedDate} />
        </div>
      </div>
      <div className="alignChild--center--center form-buttons-with-spacing">
        <BackCancelButton
          onBack={cancel}
          backLabel={l10n.getString("loan-paydown-cancel-button")}
        />
        <ContextForm.Action onSubmit={onSubmit}>
          <div style={{ marginLeft: "auto" }}>
            <Button
              label={l10n.getString("loan-paydown-make-payment-button")}
            />
          </div>
        </ContextForm.Action>
      </div>
    </div>
  );
};

export default LoanPaydownActionReview;
