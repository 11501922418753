/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { TransfersSliceState } from "./types";

const name = "transfers";

export const initialState: TransfersSliceState = {
  destinationInstantEligible: undefined,
  paymentIdentification: undefined,
  transferState: undefined,
  fees: undefined,
  fednowPayment: undefined,
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    eligible: (
      state: TransfersSliceState,
      action: PayloadAction<{ online: boolean; enrollment_level: string }>,
    ) => {
      state.destinationInstantEligible =
        action.payload.online &&
        ["receive", "send_receive", "rfp", "rfp_send"].includes(
          action.payload.enrollment_level,
        );
    },
    resetEligible: (
      state: TransfersSliceState,
      action?: PayloadAction<boolean | undefined>,
    ) => {
      if (action?.payload !== undefined) {
        state.destinationInstantEligible = action.payload;
      } else {
        state.destinationInstantEligible = undefined;
      }
    },
    setInstantTransferCreated: (
      state: TransfersSliceState,
      action: PayloadAction<API.TransferResponse>,
    ) => {
      state.paymentIdentification = (
        action.payload.transfer as API.InstantTransfer
      ).payment_identification;
      state.transferState = (
        action.payload.transfer as API.InstantTransfer
      ).state;
    },
    setTransferCreated: (
      state: TransfersSliceState,
      action: PayloadAction<API.TransferResponse>,
    ) => {
      state.transferState = "processed";
    },
    setTransferRejected: (
      state: TransfersSliceState,
      action: PayloadAction<undefined>,
    ) => {
      state.transferState = "declined";
    },
    setFees: (
      state: TransfersSliceState,
      action: PayloadAction<API.InstantFeesResponse>,
    ) => {
      state.fees = action.payload;
    },
    resetTransferSubmitted: (state: TransfersSliceState) => {
      state.transferState = undefined;
      state.paymentIdentification = undefined;
      state.fednowPayment = undefined;
    },
    setFedNowPayment: (
      state: TransfersSliceState,
      action: PayloadAction<API.FedNowPayment>,
    ) => {
      state.fednowPayment = action.payload;
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
