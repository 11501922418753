// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav--BkG3E{width:200px;padding-left:var(--space-xl)}", "",{"version":3,"sources":["webpack://./components/settings/alerts/AlertsContainer/AlertsNavBar/AlertsNavBar.module.scss"],"names":[],"mappings":"AAAA,YACE,WAAA,CACA,4BAAA","sourcesContent":[".nav {\n  width: 200px;\n  padding-left: var(--space-xl);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "nav--BkG3E"
};
export default ___CSS_LOADER_EXPORT___;
