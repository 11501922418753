import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { useLocalization } from "@fluent/react";
import TransferSchedule from "byzantine/src/TransferSchedule";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import Account from "byzantine/src/Account";
import { ContextForm, useFormData } from "cerulean";
import SimpleTransferForm from "./SimpleTransferForm";
import SimpleTransferActionReview from "./SimpleTransferActionReview";
import useReviewableAction from "../useReviewableAction";
import useActions from "../hooks/useActions";
import AccountContext from "../contexts/AccountContext";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const SimpleTransfer = ({ limits, fromAccountId, toAccountId }) => {
  const { currentUser } = useCurrentUser();
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({
    frequency: FREQUENCIES.ONCE,
    date: DateTime.now().toFormat("MM/dd/yyyy"),
  });
  const { isInput, goToInput, goToReview } = useReviewableAction();
  const { accounts } = useContext(AccountContext);
  const { goToPrevious } = useActions();
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
  const navigate = useNavigate();

  const transactionType = Account.getTransactionType(
    formData.to_account_id,
    formData.from_account_id,
    accounts
  );

  useEffect(() => {
    if (toAccountId) {
      onChange({ to_account_id: toAccountId });
    }
  }, [toAccountId]);

  useEffect(() => {
    if (fromAccountId) {
      onChange({ from_account_id: fromAccountId });
    }
  }, [fromAccountId]);

  useEffect(() => {
    // if scheduling a transfer, cannot start today
    if (formData.frequency !== FREQUENCIES.ONCE) {
      const tomorrow = DateTime.now().plus({ days: 1 });
      if (DateTime.fromFormat(formData.date, "M/d/yyyy") < tomorrow) {
        onChange({ date: tomorrow.toFormat("MM/dd/yyyy") });
      }
    }
  }, [formData.frequency]);

  const onSubmit = (callback) => {
    const schedule = new TransferSchedule({
      amount: formData.amount,
      from_account_id: formData.from_account_id,
      to_account_id: formData.to_account_id,
      frequency: formData.frequency,
      start_date: DateTime.fromFormat(formData.date, "M/d/yyyy").toFormat(
        "yyyy-MM-dd"
      ),
      memo: formData.memo,
      idempotencyKey,
    });
    schedule
      .submit()
      .then(() => {
        goToPrevious(
          `success=${encodeURIComponent(
            l10n.getString(
              "toast-transfer-scheduled",
              { transactionType },
              `${transactionType} scheduled.`
            )
          )}`
        );
      })
      .catch((error) => {
        if (typeof error === "string") {
          goToPrevious(`negative=${encodeURIComponent(error)}`);
        } else {
          callback(error);
          goToInput();
        }
      })
      .finally(() => {
        setIdempotencyKey(uuidv4());
      });
  };

  const goToTransferPage = () => {
    navigate("/");
  };

  const transactionTypeMsg = transactionType.toLocaleLowerCase();

  return (
    <ContextForm data={formData} onChange={onChange}>
      {isInput ? (
        <SimpleTransferForm
          data={formData}
          onSubmit={goToReview}
          cancel={goToTransferPage}
          limits={limits}
          isBusiness={currentUser.isBusiness()}
          title={l10n.getString(
            "make-a-transaction",
            { transactionType: transactionTypeMsg },
            `Make a ${transactionTypeMsg}`
          )}
        />
      ) : (
        <SimpleTransferActionReview
          data={formData}
          onSubmit={onSubmit}
          goBack={goToInput}
          cancel={goToTransferPage}
        />
      )}
    </ContextForm>
  );
};
SimpleTransfer.propTypes = {
  limits: PropTypes.object,
  toAccountId: PropTypes.string,
  fromAccountId: PropTypes.string,
};

export default SimpleTransfer;
