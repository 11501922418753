import * as Yup from "yup";
import { RRule } from "rrule";

import type {
  AccountAlertForm,
  BaseAlertForm,
  TransactionAlertForm,
} from "./form";

const isValidRecurrenceRule = (rruleStr?: string) => {
  try {
    if (!rruleStr) return false;
    return !!RRule.fromString(rruleStr);
  } catch (error) {
    return false;
  }
};

export enum AlertDeliveryChannel {
  EMAIL = "email",
  PUSH = "push",
  SMS = "sms",
}

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const baseValidationSchema = Yup.object<BaseAlertForm>({
  alertKey: Yup.string()
    .required("Alert type is required.")
    .notOneOf(["unknown"], "Alert type is required."),
  deliveryChannels: Yup.array()
    .of(
      Yup.string().oneOf(
        Object.values(AlertDeliveryChannel),
        "Invalid delivery channel",
      ),
    )
    .min(1, "At least one delivery channel is required.")
    .test(
      "unique-delivery-channels",
      "Delivery channels must be unique.",
      (value) => !value || value.length === new Set(value).size,
    )
    .required(),
});

export const transactionAlertValidationSchema: Yup.ObjectSchema<
  Yup.AnyObject,
  TransactionAlertForm
> = baseValidationSchema.concat(
  Yup.object({
    transactionQuery: Yup.string().required("Required."),
  }),
);

export const accountAlertValidationSchema: Yup.ObjectSchema<
  Yup.AnyObject,
  AccountAlertForm
> = baseValidationSchema.concat(
  Yup.object({
    rrule: Yup.string()
      .nullable()
      // If alertKey is "available_balance", rrule is required.
      .when("alertKey", {
        is: "available_balance",
        then: (schema) => schema.required("Required."),
        otherwise: (schema) => schema.nullable(),
      })
      // Custom test: if a value is provided, it must be a valid recurrence rule.
      .test(
        "is-valid-rrule",
        "Valid recurrence rule is required.",
        (value) => value === null || isValidRecurrenceRule(value),
      ),
    thresholdAmount: Yup.number()
      .nullable()
      // If alertKey is "low_available_balance", thresholdAmount is required.
      .when("alertKey", {
        is: "low_available_balance",
        then: (schema) =>
          schema
            .required("Required.")
            .min(0, "Balance threshold cannot be below 0."),
        otherwise: (schema) => schema.nullable(),
      }),
    subscribedAccounts: Yup.array()
      .of(Yup.string().matches(UUID_REGEX, "Must select a valid account."))
      .required("Required."),
  }),
);

export const loanAlertValidationSchema: Yup.ObjectSchema<
  Yup.AnyObject,
  AccountAlertForm
> = baseValidationSchema.concat(
  Yup.object({
    reminderDaysOffset: Yup.number()
      .nullable()
      .when("alertKey", {
        // If alertKey is "loan_reminder", reminderDaysOffset is required.
        is: "loan_reminder",
        then: (schema) =>
          schema
            .required("Due date is required.")
            .min(0, "Due date must be at least 0 days before payment.")
            .max(7, "Due date must be at most 7 days before payment."),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
);
