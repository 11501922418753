import { useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import { Dialog, Row } from "cerulean";

type DeleteDialog = {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

const DeleteDialog = ({ isOpen, handleClose, handleConfirm }: DeleteDialog) => {
  const { l10n } = useLocalization();

  return (
    <Dialog
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={l10n.getString("delete-alert-modal-title")}
      footer={
        <Row justifyContent="end" alignItems="center">
          <Row.Item shrink>
            <Button
              kind="negative"
              label={l10n.getString("button-cancel")}
              type="button"
              onClick={handleClose}
            />
          </Row.Item>
          <Row.Item shrink>
            <Button
              kind="primary"
              label={l10n.getString("delete-alert-modal-confirm-button")}
              type="button"
              onClick={handleConfirm}
            />
          </Row.Item>
        </Row>
      }
    >
      {l10n.getString("delete-alert-modal-content")}
    </Dialog>
  );
};

export default DeleteDialog;
