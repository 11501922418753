import { createForm } from "../../forms";

// eslint-disable-next-line unused-imports/no-unused-imports
import {
  transactionAlertValidationSchema,
  accountAlertValidationSchema,
  loanAlertValidationSchema,
} from "./schema";

export type BaseAlertForm = {
  alertKey: API.AlertKey;
  deliveryChannels: API.DeliveryChannel[];
};

export interface TransactionAlertForm extends BaseAlertForm {
  alertKey: API.TransactionAlertKey | "unknown";
  transactionQuery: Cents | null;
}

export interface AccountAlertForm extends BaseAlertForm {
  rrule: string | null;
  thresholdAmount: Cents | null;
  alertKey: API.AccountAlertKey | "unknown";
  subscribedAccounts: API.AccountId[] | null;
}

export interface LoanAlertForm extends BaseAlertForm {
  reminderDaysOffset: number | null;
  alertKey: API.LoanAlertKey | "unknown";
}

const txnInitialValues: TransactionAlertForm = {
  alertKey: "unknown",
  transactionQuery: null,
  deliveryChannels: [],
};

const accInitialValues: AccountAlertForm = {
  alertKey: "unknown",
  rrule: null,
  thresholdAmount: null,
  subscribedAccounts: [],
  deliveryChannels: [],
};

const loanInitialValues: LoanAlertForm = {
  alertKey: "unknown",
  reminderDaysOffset: null,
  deliveryChannels: [],
};

export const transactionAlertForm = createForm<TransactionAlertForm>({
  initialValues: txnInitialValues,
  validationSchema: transactionAlertValidationSchema,
});

export const accountAlertForm = createForm<AccountAlertForm>({
  initialValues: accInitialValues,
  validationSchema: accountAlertValidationSchema,
});

export const loanAlertForm = createForm<LoanAlertForm>({
  initialValues: loanInitialValues,
  validationSchema: loanAlertValidationSchema,
});

export const emptyAlertForm = createForm<BaseAlertForm>({
  initialValues: {
    alertKey: "unknown",
    deliveryChannels: [],
  },
});
