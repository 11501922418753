import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useObjectMemo } from "../../../hooks";

import { actions as userAlertsActions } from "./slice";
import { selectUserAlert } from "./selectors";
import { useGetUserAlerts } from "./requests";

export const useUserAlert = (key: API.AlertKey) => {
  return useSelector(selectUserAlert(key));
};

export const useFetchAllUserAlerts = (skipFlushState: boolean = false) => {
  const dispatch = useDispatch();
  const { send: fetchTransactionAlerts, loading: transactionLoading } =
    useGetUserAlerts({ alertPartition: "transaction" });
  const { send: fetchAccountAlerts, loading: accountLoading } =
    useGetUserAlerts({ alertPartition: "account" });
  const { send: fetchLoanAlerts, loading: loanLoading } = useGetUserAlerts({
    alertPartition: "loan",
  });

  const fetchUserAlerts = useCallback(() => {
    // Flush state before alerts are fetched.
    if (!skipFlushState) {
      dispatch(userAlertsActions.removeAll());
    }

    // Fetch all alerts for each partition individually.
    fetchTransactionAlerts();
    fetchAccountAlerts();
    fetchLoanAlerts();
  }, [
    dispatch,
    fetchAccountAlerts,
    fetchLoanAlerts,
    fetchTransactionAlerts,
    skipFlushState,
  ]);

  return useObjectMemo({
    send: fetchUserAlerts,
    loading: transactionLoading || accountLoading || loanLoading,
  });
};
