import React from "react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";

const SAMPLE_STATEMENT_URL = "/sample_statement";

const SamplePDFLink = ({ children, onConfirmedEstatementAccess }) => (
    <Button
      as="a"
      kind="plain"
      href={SAMPLE_STATEMENT_URL}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        window?.open(SAMPLE_STATEMENT_URL, "_blank", "Sample PDF");
        onConfirmedEstatementAccess(true);
      }}
    >
      {children}
    </Button>
  );

SamplePDFLink.propTypes = {
  children: PropTypes.node,
  onConfirmedEstatementAccess: PropTypes.func.isRequired,
};

export default SamplePDFLink;
