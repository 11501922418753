import type { CreateAlertPayload, UpdateAlertPayload } from "./types";
import type { NetworkPayload } from "../../../composites";

const baseResourceUrl = "alerts";

const snakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

const formatPayload = (payload: any) => {
  const formattedPayload: Record<string, unknown> = {};

  Object.keys(payload).forEach((key: string) => {
    if (payload[key] !== undefined) {
      formattedPayload[snakeCase(key)] = payload[key];
    }
  });

  return formattedPayload;
};

const UserAlertsAPI = {
  getAllPartitionAlerts: (
    alertPartition: API.AlertPartition,
  ): NetworkPayload => {
    return {
      url: `${baseResourceUrl}/${alertPartition}/`,
      options: {
        method: "GET",
      },
    };
  },

  getAlert: (
    alertUUID: API.AlertId,
    alertPartition: API.AlertPartition,
  ): NetworkPayload => {
    return {
      url: `${baseResourceUrl}/${alertPartition}/${alertUUID}/`,
      options: {
        method: "GET",
      },
    };
  },

  createAlert: (
    createParameters: CreateAlertPayload,
    alertPartition: API.AlertPartition,
  ): NetworkPayload => {
    return {
      url: `${baseResourceUrl}/${alertPartition}/`,
      options: {
        method: "POST",
        payload: formatPayload(createParameters),
      },
    };
  },

  updateAlert: (
    alertUUID: API.AlertId,
    updatedParameters: UpdateAlertPayload,
    alertPartition: API.AlertPartition,
  ): NetworkPayload => {
    return {
      url: `${baseResourceUrl}/${alertPartition}/${alertUUID}/`,
      options: {
        method: "PUT",
        payload: formatPayload(updatedParameters),
      },
    };
  },

  deleteAlert: (
    alertUUID: API.AlertId,
    alertPartition: API.AlertPartition,
  ): NetworkPayload => {
    return {
      url: `${baseResourceUrl}/${alertPartition}/${alertUUID}/`,
      options: {
        method: "DELETE",
        isJsonResponse: false,
      },
    };
  },
};

export default UserAlertsAPI;
