// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--paB52{display:flex;flex-direction:column;padding-top:30px}@media only screen and (min-width: 768px){.container--paB52{flex-direction:row}}", "",{"version":3,"sources":["webpack://./components/settings/alerts/AlertsContainer/AlertsContainer.module.scss","webpack://./../scss/_mixins.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,gBAAA,CCUA,0CDbF,kBAMI,kBAAA,CAAA","sourcesContent":["@import \"../../../../../scss/mixins\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  padding-top: 30px;\n\n  @include minViewport(\"m\") {\n    flex-direction: row;\n  }\n}\n","@use \"sass:map\";\n@import \"./vars\";\n\n/**\n* Wraps content in a media query that targets the\n* given breakpoint size _and larger_.\n*\n* ```scss\n* @include minViewport(\"m\") {\n*   // styles targeting \"m\" size _and larger_\n* }\n* ```\n*/\n@mixin minViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (min-width: #{$breakpoint}) {\n    @content;\n  }\n}\n\n/**\n* Wraps content in a media query that targets viewports\n* smaller than the given breakpoint size.\n* (Not inclusive of the given breakpoint)\n*\n* ```scss\n* @include maxViewport(\"m\") {\n*   // styles targeting viewports smaller than \"m\"\n* }\n* ```\n*/\n@mixin maxViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (max-width: #{$breakpoint - 1px}) {\n    @content;\n  }\n}\n\n// rules to hide an element visually,\n// but still make it focusable for a11y\n@mixin visuallyHide {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  position: absolute;\n  white-space: nowrap;\n}\n@mixin visuallyShow {\n  clip: unset;\n  clip-path: unset;\n  height: unset;\n  overflow: unset;\n  position: relative;\n  white-space: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--paB52"
};
export default ___CSS_LOADER_EXPORT___;
