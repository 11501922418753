import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { ContentCard } from "cerulean";
import { Alert, Checkbox } from "@narmi/design_system";
import { useLocalization, Localized } from "@fluent/react";
import { useFeature } from "byzantine";
import cc from "classcat";
import styles from "./SecurityAlerts.module.scss";

const SecurityBanner = memo(() => (
  <div className={styles.bannerContainer}>
    <Localized
      id="security-alert-banner"
      elems={{
        profileLink: <Link to="/profile" className={styles.profileLink} />,
      }}
    >
      <Alert isActive isDismissable={false} kind="info">
        {`Security alerts are enabled by default and are sent to your email. You can
      update your email in the <profileLink>Profile</profileLink>.`}
      </Alert>
    </Localized>
  </div>
));

const SecurityAlerts = () => {
  const { l10n } = useLocalization();
  const { featureEnabled } = useFeature();

  const alertDefinitions = useMemo(() => {
    const definitions = [
      {
        key: "security-alert-description-password-reset",
        fallback: "When I reset my password.",
      },
      {
        key: "security-alert-description-password-change",
        fallback: "When I change my password.",
      },
      {
        key: "security-alert-description-email-change",
        fallback: "When I change my email.",
      },
      {
        key: "security-alert-description-phone-change",
        fallback: "When I change my phone number.",
      },
      {
        key: "security-alert-description-address-change",
        fallback: "When I change my address.",
      },
      {
        key: "security-alert-description-staff-create-2fa",
        fallback:
          "When staff generates backup two-factor authentication codes for me.",
      },
      {
        key: "security-alert-description-add-remove-2fa",
        fallback: "When I add or remove a two-factor authentication device.",
      },
      {
        key: "security-alert-description-add-external-account",
        fallback: "When I successfully add an external account.",
      },
      {
        key: "security-alert-description-enroll-banking",
        fallback:
          "When someone attempts to enroll in digital banking with my account information.",
      },
    ];

    if (featureEnabled("olb_card_travel_notes")) {
      definitions.push({
        key: "security-alert-description-card-travel-notes",
        fallback: "When I create a new travel note for a linked card",
      });
    }

    if (
      featureEnabled("olb_enable_card_pos_limit") ||
      featureEnabled("olb_enable_card_atm_limit")
    ) {
      definitions.push({
        key: "security-alert-description-card-limit-increase",
        fallback: "When I request a limit increase for a linked card",
      });
    }

    return definitions;
  }, [featureEnabled]);

  const securityAlerts = useMemo(
    () =>
      alertDefinitions.map(({ key, fallback }) =>
        l10n.getString(key, {}, fallback)
      ),
    [l10n, alertDefinitions]
  );

  return (
    <div className={styles.container}>
      <header>
        <h4
          className={cc([
            "fontSize--heading4 fontFamily--default",
            styles.heading,
          ])}
        >
          Security alerts
        </h4>
      </header>
      <SecurityBanner />
      <ContentCard kind="bordered">
        {securityAlerts.map((description) => (
          <div key={description} className={styles.securityAlertItem}>
            {description}
            <div className={styles.checkboxWrapper}>
              <Checkbox checked disabled />
            </div>
          </div>
        ))}
      </ContentCard>
    </div>
  );
};

export default memo(SecurityAlerts);
