import React, { useCallback, useMemo } from "react";

import { Button } from "@narmi/design_system";
import { IconButton, Row } from "cerulean";
import { modules } from "byzantine";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import HeaderWithSteps from "../../../../../../../components/HeaderWithSteps";

import styles from "./Review.module.scss";

type Props = {
  onSubmit: (idempotencyKey: UUID) => void;
  loading: boolean;
  goBack: () => void;
};

function Review({ onSubmit, loading, goBack }: Props) {
  const idempotencyKey = useMemo(() => uuidv4() as UUID, []);
  const details = modules.wireTemplates.useReviewDetails();

  const navigate = useNavigate();
  const cancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  if (!details) {
    return null;
  }
  const { sections, buttonLabel, disclosure } = details;

  return (
    <div className={styles.wireViaTemplateReviewContainer}>
      <div className={styles.backButtonRow}>
        <span aria-hidden="true" className={styles.iconContainer}>
          <IconButton
            kind="plain"
            label="Go Back"
            name={"chevron-left"}
            onClick={goBack}
            textSize="l"
          />
        </span>
        <div className={styles.headerContainer}>
          <HeaderWithSteps
            headerText="Review your wire"
            step={2}
            totalSteps={2}
          />
        </div>
      </div>
      {sections.map(({ header, rows }, i) => (
        <div key={`section-${header}-${i}`}>
          <p className={styles.reviewSubHeader}>{header}</p>
          <div className={styles.card}>
            {rows.map(({ header: rowHeader, value }, j) => {
              return (
                <div key={`row-${rowHeader}-${j}`}>
                  <p className={styles.key}>{rowHeader}</p>
                  <p className={styles.value}>{value}</p>
                </div>
              );
            })}
          </div>
        </div>
      ))}
      {disclosure ? (
        <div className="fontColor--secondary">
          <ReactMarkdown linkTarget="_blank">{disclosure}</ReactMarkdown>
        </div>
      ) : null}
      <div className={styles.buttonRowContainer}>
        <Row alignItems="center">
          <Row.Item>
            <Button label="Cancel" kind="negative" onClick={cancel} />
          </Row.Item>
          <Row.Item shrink>
            <Button
              label={buttonLabel}
              onClick={() => onSubmit(idempotencyKey)}
              isLoading={loading}
              disabled={loading}
            />
          </Row.Item>
        </Row>
      </div>
    </div>
  );
}

export default React.memo(Review);
