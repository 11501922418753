import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import User from "byzantine/src/User";
import { Button } from "@narmi/design_system";
import { useLoadingContext, useNotificationContext } from "cerulean";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const UnenrollButton = ({
  setConfirmedEstatementAccess,
  setEstatementsEnabled,
  setEstatementsWereEnabledInitially,
  show,
}) => {
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const { l10n } = useLocalization();
  const { currentUser, setCurrentUser } = useCurrentUser();

  if (!show) return null;
  return (
    <Button
      as="a"
      kind="plain"
      label="Unenroll"
      onClick={() => {
        setIsLoading(true);
        ApiHttp.fetch(`users/${currentUser?.id}/estatements`, {
          method: "DELETE",
        })
          .then(() => {
            setConfirmedEstatementAccess(false); // user should have to click the sample statement link again if they want to re-enable
            setEstatementsEnabled(false);
            setEstatementsWereEnabledInitially(false); //  determines which text we show if the user re-enrolls in paperless
            sendNotification({
              type: "success",
              text: l10n.getString(
                "estatements-unenrollment-toast",
                null,
                "You've been unenrolled in paperless statements."
              ),
            });
            User.getUser().then((u) => {
              setCurrentUser(u);
            });
          })
          .catch((err) => {
            sendNotification({
              type: "negative",
              text: err,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    />
  );
};
UnenrollButton.propTypes = {
  setConfirmedEstatementAccess: PropTypes.func,
  setEstatementsEnabled: PropTypes.func,
  setEstatementsWereEnabledInitially: PropTypes.func,
  show: PropTypes.bool,
};

export default UnenrollButton;
