/* eslint-disable camelcase */

import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { useLocalization } from "@fluent/react";
import TransferSchedule from "byzantine/src/TransferSchedule";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import Account from "byzantine/src/Account";
import utils from "byzantine/src/utils";
import {
  ContextForm,
  formatNumber,
  useFormData,
  useNotificationContext,
} from "cerulean";
import LoanPaymentByCardForm from "./LoanPaymentByCardForm";
import LoanPaymentByCardActionReview from "./LoanPaymentByCardActionReview";
import useReviewableAction from "../../../useReviewableAction";
import AccountContext from "../../../contexts/AccountContext";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";

interface LoanPaymentByCardProps {
  limits: object;
  loanId?: API.AccountId;
  goToPrevious: (queryParams?: string) => void;
}

interface UserFeatures {
  loan_payment_by_card: boolean;
}

const LoanPaymentByCard = ({
  limits,
  loanId,
  goToPrevious,
}: LoanPaymentByCardProps) => {
  const { currentUser } = useCurrentUser();
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({
    frequency: FREQUENCIES.ONCE,
    date: DateTime.now().toFormat("MM/dd/yyyy"),
    to_account_id: loanId,
    payment_type: "bank",
  });
  const { isInput, goToInput, goToReview } = useReviewableAction();
  const { accounts } = useContext(AccountContext) as { accounts: Account[] };
  const { loan_payment_by_card } = useUserFeatures() as UserFeatures;
  const [cards, setCards] = useState([]);
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
  const { sendNotification } = useNotificationContext();
  const navigate = useNavigate();

  if (!loan_payment_by_card || !currentUser) {
    return null;
  }

  useEffect(() => {
    if (loanId) {
      onChange({ to_account_id: loanId });
    }
  }, [loanId]);

  useEffect(() => {
    // if scheduling a transfer, cannot start today
    if (formData.frequency !== FREQUENCIES.ONCE) {
      const tomorrow = DateTime.now().plus({ days: 1 });
      if (DateTime.fromFormat(formData.date, "M/d/yyyy") < tomorrow) {
        onChange({ date: tomorrow.toFormat("MM/dd/yyyy") });
      }
    }
  }, [formData.frequency]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        setCards(await currentUser?.getFundingCards());
      } catch {
        sendNotification({
          type: "negative",
          text: l10n.getString(
            "error-saved-cards",
            null,
            "There was an error retrieving your saved cards, please try again later"
          ),
        });
      }
    };

    fetchCards();
  }, []);

  const getAmountAndIsMinPayment = useCallback(() => {
    if (!formData?.preset_amount || formData.preset_amount === "custom") {
      return { amount: formData.amount, isMinPayment: false };
    }
    const loanAccount = accounts.find(
      (account) => account.id === formData?.to_account_id
    );
    let minimumPayment = loanAccount?.loan_details?.minimum_payment;
    if (minimumPayment) {
      minimumPayment = formatNumber(minimumPayment);
    }

    return { amount: minimumPayment, isMinPayment: true };
  }, [formData?.amount, formData?.preset_amount, formData?.to_account_id]);

  const onCardSubmit = useCallback(
    async (callback: (arg?: unknown) => void) => {
      const payload = {
        amount: utils.dollarsToPennies(
          getAmountAndIsMinPayment().amount.replace(/[^\d.]/g, "")
        ),
        account_uuid: formData.to_account_id,
        userfunding_uuid: formData.card,
      };

      try {
        await currentUser.makeLoanPayment(payload);
        goToPrevious("success=Payment+sent.");
      } catch (err) {
        callback(err);
        if (typeof err !== "string") {
          goToInput();
        }
      }
    },
    [formData]
  );

  const onBankSubmit = useCallback(
    (callback: (arg?: unknown) => void) => {
      const start_date = DateTime.fromFormat(
        formData.date,
        "M/d/yyyy"
      ).toFormat("yyyy-MM-dd");
      const transferVerb = TransferSchedule.isImmediateOneTimeTransfer(
        formData.frequency,
        start_date
      )
        ? "sent"
        : "scheduled";
      const schedule = new TransferSchedule({
        amount: getAmountAndIsMinPayment().amount,
        from_account_id: formData.from_account_id,
        to_account_id: formData.to_account_id,
        frequency: formData.frequency,
        start_date,
        memo: formData.memo,
        idempotencyKey,
      });
      schedule
        .submit()
        .then(() => {
          goToPrevious(`success=Payment+${transferVerb}.`);
        })
        .catch((error: unknown) => {
          if (typeof error === "string") {
            goToPrevious(`negative=${encodeURIComponent(error)}`);
          } else {
            callback(error);
            goToInput();
          }
        })
        .finally(() => {
          setIdempotencyKey(uuidv4());
        });
    },
    [formData]
  );

  const goToTransferPage = () => {
    navigate("/");
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      {isInput ? (
        <LoanPaymentByCardForm
          data={formData}
          onSubmit={goToReview}
          cancel={goToTransferPage}
          limits={limits}
          cards={cards}
          setCards={setCards}
        />
      ) : (
        <LoanPaymentByCardActionReview
          data={formData}
          onBankSubmit={onBankSubmit}
          onCardSubmit={onCardSubmit}
          getAmountAndIsMinPayment={getAmountAndIsMinPayment}
          goBack={goToInput}
          cancel={goToTransferPage}
          cards={cards}
        />
      )}
    </ContextForm>
  );
};

export default LoanPaymentByCard;
