import { useCallback } from "react";

import { FormSection } from "@narmi/design_system";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput, Select } from "cerulean";
import { useLocalization } from "@fluent/react";
import { modules } from "byzantine";

import * as formAdapters from "../../../../../../../adapters";
import AccountSelector from "../../../../../../../../components/AccountSelector";
import { useAccountsContext } from "../../../../../../../../components/contexts/AccountContext";
import DrawerLayout from "../../../../../../../../components/DrawerLayout";
import RecipientCard from "../../../../../../../../components/transfer/RecipientCard";

import RecipientSelector from "./RecipientSelector";
import styles from "./TemplateForm.module.scss";

import type Account from "byzantine/src/Account";

const TemplateForm = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();

  const { accounts }: { accounts: Account[] } = useAccountsContext();

  const params = useParams();
  const existingTemplateId = params.templateId as
    | API.FedwireTemplateId
    | undefined;

  const { recipients, templates } = modules.wireTemplates.useData();

  const { form, onRemoveRecipient, wireReasons, onSubmit, loading } =
    modules.wireTemplates.useAddOrCreateTemplateForm(existingTemplateId);

  const {
    values: { wireRecipientId },
  } = form;

  const goBack = useCallback(
    () => navigate(`/wires${templates.length ? "/templates" : ""}`),
    [templates.length, navigate]
  );

  const onSave = useCallback(() => onSubmit(goBack), [goBack, onSubmit]);

  return (
    <div className={styles.formContainer}>
      <DrawerLayout onSave={onSave} onCancel={goBack} loading={loading}>
        <div className={styles.container}>
          <h2 className={styles.header}>
            {existingTemplateId ? "Edit template" : "New template"}
          </h2>
          <div className={styles.subHeader}>
            <FormSection title="Recipient Details" />
          </div>
          <div className={styles.recipients}>
            {wireRecipientId ? (
              <RecipientCard
                recipientId={wireRecipientId}
                onDelete={onRemoveRecipient}
              />
            ) : (
              <RecipientSelector
                field="recipient"
                label="Recipient"
                recipients={recipients}
                {...formAdapters.select(form, "wireRecipientId")}
              />
            )}
          </div>
          <div className={styles.subHeader}>
            <FormSection title="Template Details" />
          </div>
          <div className={styles.field}>
            <TextInput
              label={l10n.getString(
                "label-wire-template--template-name",
                null,
                "Template name"
              )}
              {...formAdapters.input(form, "name")}
            />
          </div>
          <div className={styles.field}>
            <AccountSelector
              field="sourceAccountId"
              label={l10n.getString(
                "label-wire-template--funding-account",
                null,
                "Funding account"
              )}
              {...formAdapters.select(form, "sourceAccountId")}
              isDestination={false}
              accounts={accounts.filter(
                (acc) => acc.isInternal() && acc.isValidTransferSource()
              )}
              showAddExternalAccountLink={false}
            />
          </div>
          <div className={styles.field}>
            <Select
              field="wireReason"
              label={l10n.getString(
                "label-wire-template--wire-reason",
                null,
                "Wire reason (optional)"
              )}
              {...formAdapters.select(form, "wireReason")}
            >
              {wireReasons.map((reason) => (
                <Select.Item key={reason} value={reason}>
                  {reason}
                </Select.Item>
              ))}
            </Select>
          </div>
          <div className={styles.field}>
            <TextInput
              label={l10n.getString(
                "label-wire-template--amount",
                null,
                "Amount (optional)"
              )}
              {...formAdapters.amountInput(form, "amount")}
            />
          </div>
          <div className={styles.field}>
            <TextInput
              field="memo"
              label={l10n.getString(
                "label-wire-template--template-memo",
                null,
                "Memo (optional)"
              )}
              {...formAdapters.input(form, "memo")}
            />
          </div>
          <div className={styles.subText}>
            If optional fields are set, the sender will not be able to edit
            them.
          </div>
        </div>
      </DrawerLayout>
    </div>
  );
};

export default TemplateForm;
