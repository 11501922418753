import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "@narmi/design_system";
import { Error } from "cerulean";
import Account from "byzantine/src/Account";
import { OPERATION_FOR_ACCESS_LEVEL } from "byzantine/src/Role";

export default function AccountCheckList(props) {
  // only display if we need to mutate the account operations for our role or accounts have been passed in
  if (
    !OPERATION_FOR_ACCESS_LEVEL[props.accessLevel] ||
    !props.value ||
    JSON.stringify(props.value) === "[]"
  )
    return null;

  const toggleSelected = (updateAccount) => {
    const updatedAccounts = props.value.map((prevAccount) => {
      const account = new Account(prevAccount);
      if (String(updateAccount.id) === String(account.id)) {
        account.selected = !account.selected;
      }
      return account;
    });
    props.onChange(updatedAccounts);
  };

  return (
    <>
      {props.value.map((account) => (
        <div
          className={`inverted-primary-color-checkbox ${props.containerStyle}`}
          key={`${account.id}-div`}
        >
          <Checkbox
            key={account.id}
            label={account.getShortDescription()}
            onChange={toggleSelected.bind(null, account)}
            checked={Boolean(account.selected)}
          />
        </div>
      ))}
      <Error error={props.error} />
    </>
  );
}
AccountCheckList.propTypes = {
  error: PropTypes.any,
  field: PropTypes.string,
  accessLevel: PropTypes.string,
  header: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
  containerStyle: PropTypes.object,
};
AccountCheckList.defaultProps = {
  onChange: () => {},
};
