import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useBreakpoints } from "@narmi/design_system";
import { Row } from "cerulean";
import Account from "byzantine/src/Account";

import AlertNavBar from "./AlertsNavBar";
import SecurityAlerts from "../SecurityAlerts";

import styles from "./AlertsContainer.module.scss";
import AlertsMobileNav from "./AlertsMobileNav";
import MultiAccountSelect from "../../../MultiAccountSelect";
import { useAccountsContext } from "../../../contexts/AccountContext";

const AlertsContainer = () => {
  const { m } = useBreakpoints();
  const { accounts }: { accounts: Account[] } = useAccountsContext();
  const [selectedAccountIds, setSelectedAccountIds] = useState(
    accounts.map((a: Account) => a.id as string)
  );

  return (
    <Row className={styles.container} alignItems={m ? "top" : "center"}>
      {m ? <AlertNavBar /> : <AlertsMobileNav />}
      <Routes>
        {["", "balance"].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <div style={{ width: 300 }}>
                <MultiAccountSelect
                  accounts={accounts}
                  selectedAccountIds={selectedAccountIds}
                  onSelectionChange={setSelectedAccountIds}
                />
              </div>
            }
          />
        ))}
        <Route path="spending" element={<div>Spending</div>} />
        <Route path="security" element={<SecurityAlerts />} />
        <Route path="custom" element={<div>Custom</div>} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </Row>
  );
};

export default AlertsContainer;
