// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--Fm5Vc{display:flex;flex-direction:column}.row--NKEp0,.tableHeader--CC0C0{min-width:100%;display:flex;align-items:center;text-align:start;padding:var(--space-s) 2px;gap:var(--space-xs);justify-content:space-between}.tableHeader--CC0C0{border-bottom:1px solid var(--border-color-default)}.row--NKEp0+.row--NKEp0{border-top:1px solid var(--border-color-default)}.row--NKEp0:hover,.row--NKEp0:focus{background:rgba(var(--theme-rgb-primary), var(--alpha-5))}.col1--ss3Pg{flex-basis:430px}.col2--YEtps{flex-basis:250px}.col3--Opfrz{flex-basis:130px;text-align:end;flex-shrink:1}", "",{"version":3,"sources":["webpack://./components/RecentTransactionsTable.module.scss"],"names":[],"mappings":"AACA,cACE,YAAA,CACA,qBAAA,CAGF,gCAEE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,0BAAA,CACA,mBAAA,CACA,6BAAA,CAIF,oBACE,mDAAA,CAIA,wBACE,gDAAA,CAGF,oCAEE,yDAAA,CAIJ,aACE,gBAAA,CAEF,aACE,gBAAA,CAEF,aACE,gBAAA,CACA,cAAA,CACA,aAAA","sourcesContent":["\n.table {\n  display: flex;\n  flex-direction: column;\n}\n\n.row,\n.tableHeader {\n  min-width: 100%;\n  display: flex;\n  align-items: center;\n  text-align: start;\n  padding: var(--space-s) 2px;\n  gap: var(--space-xs);\n  justify-content: space-between;\n}\n\n\n.tableHeader {\n  border-bottom: 1px solid var(--border-color-default);\n}\n\n.row {\n  + .row {\n    border-top: 1px solid var(--border-color-default);\n  }\n\n  &:hover,\n  &:focus {\n    background: rgba(var(--theme-rgb-primary), var(--alpha-5));\n  }\n}\n\n.col1 {\n  flex-basis: 430px;\n}\n.col2 {\n  flex-basis: 250px;\n}\n.col3 {\n  flex-basis: 130px;\n  text-align: end;\n  flex-shrink: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--Fm5Vc",
	"row": "row--NKEp0",
	"tableHeader": "tableHeader--CC0C0",
	"col1": "col1--ss3Pg",
	"col2": "col2--YEtps",
	"col3": "col3--Opfrz"
};
export default ___CSS_LOADER_EXPORT___;
