// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--nBcQA{width:100%}", "",{"version":3,"sources":["webpack://./components/settings/alerts/AlertsContainer/AlertsMobileNav/AlertsMobileNav.module.scss"],"names":[],"mappings":"AAAA,kBACE,UAAA","sourcesContent":[".container {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--nBcQA"
};
export default ___CSS_LOADER_EXPORT___;
