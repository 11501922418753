import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";
import {
  Dialog,
  Options,
  Row,
  useLoadingContext,
  CsrfTokenMiddleware,
} from "cerulean";
import { useTranslation } from "react-i18next";

import { useCurrentUser } from "../contexts/CurrentUserContext";

const RemoveLinkedProfileDialog = (props) => {
  const { t } = useTranslation();

  const unlinkProfilesForm = React.useRef();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { setIsLoading } = useLoadingContext();

  const handleDelete = () => {
    setIsLoading(true);
    setIsDialogOpen(false);
    unlinkProfilesForm.current.submit();
  };

  return (
    <>
      <div className="linked-profile-options">
        <Options>
          <Options.Option
            icon="narmi-icon-trash-2"
            text={t("Unlink")}
            onClick={() => {
              setIsDialogOpen(true);
            }}
            width="141px"
          />
        </Options>
      </div>
      <form action="/login/unlink" method="POST" ref={unlinkProfilesForm}>
        <CsrfTokenMiddleware />
        <input type="hidden" name="username" value={props.username} />
        <Dialog
          isOpen={isDialogOpen}
          footer={
            <div style={{ textAlign: "right" }}>
              <Button kind="negative" onClick={() => setIsDialogOpen(false)}>
                {t("Cancel")}
              </Button>
              <span className="margin--left--s">
                <Button onClick={handleDelete}>{t("Unlink")}</Button>
              </span>
            </div>
          }
          title={`Unlink ${props.fullName}?`}
          onUserDismiss={() => {
            setIsDialogOpen(false);
          }}
          width="385px"
        >
          <div style={{ fontSize: "16px" }}>
            Are you sure you want to unlink the profile?
          </div>
        </Dialog>
      </form>
    </>
  );
};

RemoveLinkedProfileDialog.propTypes = {
  fullName: PropTypes.string,
  username: PropTypes.string,
};

const ProfileSwitcher = (props) => {
  const { currentUser } = useCurrentUser();
  const switchBetweenUsersForm = React.useRef();
  const { setIsLoading } = useLoadingContext();

  const handleSubmit = () => {
    setIsLoading(true);
    switchBetweenUsersForm.current.submit();
  };

  return (
    <>
      <div className="linked-profiles-row">
        <Row gapSize="s">
          <Row.Item shrink>
            {props.profile.username === currentUser.username ? (
              <div className="padding--top--xs">
                <span className="narmi-icon-check fontSize--heading3"></span>
              </div>
            ) : (
              <div className="linked-profiles-blank-space"></div>
            )}
          </Row.Item>
          <Row.Item id={props.profile.username}>
            <form
              action="/login/switch/"
              method="POST"
              ref={switchBetweenUsersForm}
            >
              <CsrfTokenMiddleware />
              <input
                type="hidden"
                name="username"
                value={props.profile.username}
              />
              <button
                data-testid="link-profile-name"
                onClick={handleSubmit}
                className="linked-profile-container button--reset"
              >
                <div
                  className="linked-profiles-name"
                  id={props.profile.username}
                >
                  {props.profile.full_name}
                </div>
                <div
                  className="linked-profiles-username"
                  id={props.profile.username}
                >
                  {props.profile.username}
                </div>
              </button>
            </form>
          </Row.Item>
          <Row.Item shrink>
            {props.profile.username !== currentUser.username ? (
              <RemoveLinkedProfileDialog
                fullName={props.profile.full_name}
                username={props.profile.username}
              />
            ) : (
              <div className="linked-profiles-blank-space"></div>
            )}
          </Row.Item>
        </Row>
      </div>
      <div className="line"></div>
    </>
  );
};

ProfileSwitcher.propTypes = {
  profile: PropTypes.instanceOf(Object), // {username: string, full_name: string }
};

export default ProfileSwitcher;
