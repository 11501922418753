import { useCallback, useEffect, useState } from "react";

import { arrayEqual } from "../../../utils/array";
import { centsToDollars, dollarsToCents } from "../../../utils";
import { useLibrary } from "../../../providers";
import { useObjectMemo, useThrottledEffect } from "../../../hooks";
import {
  useCreateUserAlert,
  useDeleteUserAlert,
  useUpdateUserAlert,
  useUserAlert,
} from "../../entities/alerts";

import {
  transactionAlertForm,
  accountAlertForm,
  loanAlertForm,
  emptyAlertForm,
} from "./form";
import { useAlertConfiguration } from "./translations";
import {
  getAlertPartitionFromAlertKey,
  isAccountAlert,
  isAccountForm,
  isLoanAlert,
  isLoanForm,
  isTransactionAlert,
  isTransactionForm,
} from "./utils";

import type {
  TransactionAlertForm,
  AccountAlertForm,
  LoanAlertForm,
  BaseAlertForm,
} from "./form";
import type {
  ToggleAlertReturnType,
  DisableDeliveryChannelFunction,
} from "./types";
import type {
  DisabledChannelRestrictions,
  AlertPayload,
} from "../../entities/alerts";
import type { GenericForm } from "../../forms";

function extractNumberFromTransactionQuery(
  query: string | null,
): Dollars | null {
  if (!query) return null;
  const match = query.match(/>(\d+)/);
  return match ? (parseInt(match[1], 10) as Dollars) : null;
}

export const useAlertFormFromKey = (key: API.AlertKey) => {
  const loanForm = loanAlertForm.useForm();
  const accountForm = accountAlertForm.useForm();
  const placeholderForm = emptyAlertForm.useForm();
  const transactionForm = transactionAlertForm.useForm();

  const alertPartition = getAlertPartitionFromAlertKey(key);

  switch (alertPartition) {
    case "transaction":
      return transactionForm as GenericForm<TransactionAlertForm>;
    case "account":
      return accountForm as GenericForm<AccountAlertForm>;
    case "loan":
      return loanForm as GenericForm<LoanAlertForm>;
    default:
      return placeholderForm as GenericForm<BaseAlertForm>;
  }
};

/**
 * This hook exposes a function to set the default values of an alert
 * based on the alert key, into the relevant alert form.
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 *
 */
export const useSanitizeAlertsPayload = () => {
  const sanitizePayload = useCallback((payload: AlertPayload) => {
    const sanitizedPayload = payload;
    if (isAccountForm(sanitizedPayload, sanitizedPayload.alertKey)) {
      if (sanitizedPayload.alertKey === "available_balance") {
        sanitizedPayload.thresholdAmount = null;
      } else {
        sanitizedPayload.rrule = null;
      }
    } else if (isLoanForm(sanitizedPayload, sanitizedPayload.alertKey)) {
      if (sanitizedPayload.alertKey === "loan_overdue") {
        sanitizedPayload.reminderDaysOffset = null;
      }
    }

    return sanitizedPayload;
  }, []);

  return sanitizePayload;
};

export const useUserAlertsForm = (key: API.AlertKey) => {
  const form = useAlertFormFromKey(key);
  const { submitForm, values } = form;
  const sanitizePayload = useSanitizeAlertsPayload();

  const onValidate = useCallback(async () => {
    const result = await submitForm();
    return result.success;
  }, [submitForm]);

  const formatKeysForPayload = useCallback((): Record<string, unknown> => {
    if (
      isTransactionForm(form.values, key) &&
      form.values.transactionQuery !== null
    ) {
      return {
        transactionQuery: `amount>${centsToDollars(form.values.transactionQuery)}`,
      };
    }

    if (
      isAccountForm(form.values, key) &&
      form.values.thresholdAmount !== null
    ) {
      return {
        thresholdAmount: centsToDollars(form.values.thresholdAmount),
      };
    }

    return {};
  }, [form.values, key]);

  const createRequestPayload = useCallback(() => {
    return sanitizePayload({
      ...form.values,
      ...formatKeysForPayload(),
    } as AlertPayload);
  }, [form.values, formatKeysForPayload, sanitizePayload]);

  const createRestrictedRequestPayload = useCallback(
    (disabledChannels: DisabledChannelRestrictions): AlertPayload => {
      const requestPayload = createRequestPayload();

      return {
        ...requestPayload,
        deliveryChannels: requestPayload.deliveryChannels!.filter(
          (channel) => !disabledChannels.includes(channel),
        ),
      };
    },
    [createRequestPayload],
  );

  return useObjectMemo({
    form,
    values,
    onValidate,
    createRequestPayload,
    createRestrictedRequestPayload,
  });
};

/**
 * This hook exposes a function to asynchronously update the alert's delivery channels
 * incase some external factor is the cause for the delivery channel status to change.
 * Users can enable/disable push notifications or have invalid phone numbers saved
 * when alerts are already activated.
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 * @returns {DisableDeliveryChannelFunction} Function to disable the delivery channels of the alert.
 
* @example
 * import { modules } from "byzantine";
 *
 * function SpecificAlertPage() {
 *   const disableAlertChannels = modules.alerts.useUpdateDeliveryChannels(key);
 *
 *   useEffect(() => {
 *     if(shouldDisablePhone) disableAlertChannels(["sms"])
 *   }, [shouldDisablePhone])
 *
 *   return (
 *     <>
 *      ...
 *     </>
 *   );
 * }
 */
export const useDisableDeliveryChannels = (
  key: API.AlertKey,
): DisableDeliveryChannelFunction => {
  const { form } = useUserAlertsForm(key);

  const updateAlertDeliveryChannels = useCallback(
    (disabledChannels: API.DeliveryChannel[]) => {
      const updatedChannels = form.values.deliveryChannels.filter(
        (channel) => !disabledChannels.includes(channel),
      );

      // Disabled delivery channels are not selected.
      if (arrayEqual(updatedChannels, form.values.deliveryChannels)) return;

      form.setFieldValue("deliveryChannels", updatedChannels, false);
    },
    [form],
  );

  return updateAlertDeliveryChannels;
};

/**
 * This hook listens to the form changes and sends update requests for a currently active alert,
 * if its form values change at any point. The update request is sent in batches to avoid
 * overwhelming the server and the requests are dropped if the form is in an invalid state
 * due to a particular update.
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 *
 * @example
 * import { modules } from "byzantine";
 *
 * function SpecificAlertPage() {
 *   modules.alerts.useUpdateAlertOnChange(alertKey);
 *
 *   return (
 *     <>
 *      ...
 *     </>
 *   );
 * }
 */
export const useUpdateAlertOnChange = (key: API.AlertKey) => {
  const currentAlert = useUserAlert(key);
  const alertPartition = getAlertPartitionFromAlertKey(key);
  const { values, onValidate, createRequestPayload } = useUserAlertsForm(key);
  const { send: updateRequest } = useUpdateUserAlert({
    alertUUID: currentAlert?.id,
  });

  const updateAlertOnValuesChange = useCallback(async () => {
    if (await onValidate()) {
      updateRequest(alertPartition, createRequestPayload());
    }
  }, [alertPartition, createRequestPayload, onValidate, updateRequest]);

  useThrottledEffect(updateAlertOnValuesChange, [values], 10000);
};

/**
 * This hook exposes a function to set the default values of an alert
 * based on the alert key, into the relevant alert form.
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 *
 * @example
 * import { modules } from "byzantine";
 *
 * function SpecificAlertPage() {
 *   const setAlertDefaults = modules.alerts.useSetAlertDefaults(alertKey);
 *
 *   const onDelete = () => {
 *     deleteAlert();
 *     setAlertDefaults();
 *   }
 *
 *   return (
 *     <>
 *      ...
 *     </>
 *   );
 * }
 */
export const useSetAlertDefaults = (key: API.AlertKey) => {
  const { form } = useUserAlertsForm(key);
  const config = useAlertConfiguration(key);

  const setAlertDefaults = useCallback(() => {
    // Reset form state by clearing any errors
    form.resetForm();

    // If an alert is currently inactive, populate form with its static default values.
    // The alertKey field should not change. Force setting it to key will guarantee that.
    form.setFieldValue("alertKey", key, false);

    config.forEach(({ fieldName, fieldDefaultValue }) => {
      form.setFieldValue(fieldName, fieldDefaultValue, false);
    });
  }, [config, form, key]);

  return setAlertDefaults;
};

/**
 * This hook sets values from a user's alert in redux into the alert form.
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 *
 * @example
 * import { modules } from "byzantine";
 *
 * function SpecificAlertPage() {
 *   const setUserAlertValues = modules.alerts.useSetUserAlertValues(alertKey);
 *
 *   const onMount = () => {
 *     if (currentAlert) {
 *       setUserAlertValues()
 *     }
 *   }
 *
 *   return (
 *     <>
 *      ...
 *     </>
 *   );
 * }
 */
export const useSetUserAlertValues = (key: API.AlertKey): (() => void) => {
  const currentAlert = useUserAlert(key);
  const { form } = useUserAlertsForm(key);

  const setTransactionAlertValues = useCallback(
    (
      transactionAlert: API.UserTransactionAlert,
      transactionForm: GenericForm<TransactionAlertForm>,
    ) => {
      const formKeyVals: [keyof TransactionAlertForm, unknown][] = [
        ["alertKey", transactionAlert.alert_key],
        ["deliveryChannels", transactionAlert.delivery_channels],
        [
          "transactionQuery",
          dollarsToCents(
            extractNumberFromTransactionQuery(
              transactionAlert.transaction_query,
            ) || (0 as Dollars),
          ),
        ],
      ];

      formKeyVals.forEach(([formKey, formValue]) => {
        transactionForm.setFieldValue(formKey, formValue, false);
      });
    },
    [],
  );

  const setAccountAlertValues = useCallback(
    (
      accountAlert: API.UserAccountAlert,
      accountForm: GenericForm<AccountAlertForm>,
    ) => {
      const formKeyVals: [keyof AccountAlertForm, unknown][] = [
        ["alertKey", accountAlert.alert_key],
        ["deliveryChannels", accountAlert.delivery_channels],
        ["rrule", accountAlert.rrule],
        [
          "thresholdAmount",
          accountAlert.threshold_amount
            ? dollarsToCents(accountAlert.threshold_amount as Dollars)
            : null,
        ],
        ["subscribedAccounts", accountAlert.subscribed_accounts],
      ];

      formKeyVals.forEach(([formKey, formValue]) => {
        accountForm.setFieldValue(formKey, formValue, false);
      });
    },
    [],
  );

  const setLoanAlertValues = useCallback(
    (loanAlert: API.UserLoanAlert, loanForm: GenericForm<LoanAlertForm>) => {
      const formKeyVals: [keyof LoanAlertForm, unknown][] = [
        ["alertKey", loanAlert.alert_key],
        ["deliveryChannels", loanAlert.delivery_channels],
        ["reminderDaysOffset", loanAlert.reminder_days_offset],
      ];

      formKeyVals.forEach(([formKey, formValue]) => {
        loanForm.setFieldValue(formKey, formValue, false);
      });
    },
    [],
  );

  const setUserAlertValues = useCallback(() => {
    if (!currentAlert) return;

    if (isTransactionAlert(currentAlert)) {
      setTransactionAlertValues(
        currentAlert,
        form as GenericForm<TransactionAlertForm>,
      );
    } else if (isAccountAlert(currentAlert)) {
      setAccountAlertValues(
        currentAlert,
        form as GenericForm<AccountAlertForm>,
      );
    } else if (isLoanAlert(currentAlert)) {
      setLoanAlertValues(currentAlert, form as GenericForm<LoanAlertForm>);
    }
  }, [
    form,
    currentAlert,
    setAccountAlertValues,
    setLoanAlertValues,
    setTransactionAlertValues,
  ]);

  return setUserAlertValues;
};

/**
 * This hook loads the relevant alert form using either the alert's currently set values
 * from redux, or a default set of values that are hardcoded (if the alert is not in redux).
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 *
 * @example
 * import { modules } from "byzantine";
 *
 * function SpecificAlertPage() {
 *   modules.alerts.usePopulateAlertInitialValues(alertKey);
 *
 *   return (
 *     <>
 *      ...
 *     </>
 *   );
 * }
 */
export const usePopulateAlertInitialValues = (key: API.AlertKey) => {
  const currentAlert = useUserAlert(key);
  const setAlertDefaults = useSetAlertDefaults(key);
  const setUserAlertValues = useSetUserAlertValues(key);

  const updateAlertInitialValues = useCallback(() => {
    if (currentAlert) {
      setUserAlertValues();
    } else {
      setAlertDefaults();
    }
  }, [currentAlert, setAlertDefaults, setUserAlertValues]);

  useEffect(() => {
    // Run this function once on mount.
    updateAlertInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * This hook manages switching an alerts active state by exposing a toggle method to sync with
 * the alerts UI switch. Under the hood, this hook, validates the alert form and sends either a
 * create or delete request for the alert depending on whether it is currently active or not. The active/inactive
 * state of the alert is dependent on whether the alert exists in redux.
 *
 * @param {API.AlertKey} key - The key of the currently rendered alert.
 * @returns {ToggleAlertReturnType} An object with metadata and functions for the alert.
 *
 * @example
 * import { modules } from "byzantine";
 *
 * function AlertToggle() {
 *   const {
 *     toggleAlert,
 *     isActive,
 *     loading: toggleLoading,
     } = modules.alerts.useToggleAlert(alertKey);
 *
 *   return (
 *     <>
 *      ...
 *       <Switch
            value={isActive}
            disabled={toggleLoading}
            onValueChange={toggleAlert}
         />
 *     </>
 *   );
 * }
 */
export const useToggleAlert = (key: API.AlertKey): ToggleAlertReturnType => {
  const currentAlert = useUserAlert(key);
  const setAlertDefaults = useSetAlertDefaults(key);
  const alertPartition = getAlertPartitionFromAlertKey(key);
  const [isActive, setIsActive] = useState<boolean>(!!currentAlert);
  const { onValidate, createRequestPayload } = useUserAlertsForm(key);

  const { send: createRequest, loading: createAlertLoading } =
    useCreateUserAlert();

  const { send: deleteRequest, loading: deleteAlertLoading } =
    useDeleteUserAlert({
      alertUUID: currentAlert?.id,
      onSuccess: setAlertDefaults,
    });

  const toggleIsActive = useCallback(() => {
    setIsActive((prev) => !prev);
  }, []);

  const deleteAlert = useCallback(() => {
    toggleIsActive();
    deleteRequest(alertPartition, {
      onError: toggleIsActive,
    });
  }, [alertPartition, deleteRequest, toggleIsActive]);

  const createAlert = useCallback(async () => {
    const validationStatus = await onValidate();
    if (validationStatus) {
      toggleIsActive();
      const payload = createRequestPayload();
      createRequest(alertPartition, payload, {
        onError: toggleIsActive,
      });
    }
  }, [
    alertPartition,
    onValidate,
    toggleIsActive,
    createRequest,
    createRequestPayload,
  ]);

  const toggleAlert = useCallback(async () => {
    if (createAlertLoading || deleteAlertLoading) {
      return;
    }

    // If alert exists, delete alert.
    (currentAlert ? deleteAlert : createAlert)();
  }, [
    createAlert,
    createAlertLoading,
    currentAlert,
    deleteAlert,
    deleteAlertLoading,
  ]);

  return useObjectMemo({
    isActive,
    toggleAlert,
    loading: createAlertLoading || deleteAlertLoading,
  });
};

// For debugging
export const useAlertErrors = (key: API.AlertKey) => {
  const { form } = useUserAlertsForm(key);
  const { throwToast } = useLibrary("toasts");

  const throwError = useCallback(
    (message: string | string[]) => {
      throwToast({ kind: "error", message: `form error: ${message}` });
    },
    [throwToast],
  );

  useEffect(() => {
    if (form.errors) {
      Object.keys(form.errors).forEach((errorKey) => {
        const error = (form.errors as any)[errorKey];
        if (error) throwError(error);
      });
    }
  }, [form.errors, throwError]);
};
