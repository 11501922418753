import { ReactNode, MouseEventHandler } from "react";
import { Button } from "@narmi/design_system";
import { EditLabelTooltip } from "cerulean";

interface ProfileSettingRowProps {
  label: ReactNode;
  value: ReactNode;
  editOnClick?: MouseEventHandler<HTMLButtonElement>;
  tooltipText?: string | null;
  testId?: string;
  isReadOnly?: boolean;
}

const ProfileSettingRow = ({
  label,
  value,
  editOnClick,
  tooltipText,
  testId = "",
  isReadOnly = false,
}: ProfileSettingRowProps) => (
  <div className="profile-setting-row" data-testid={testId}>
    <div className="profile-setting-field">
      <div className="profile-setting-label">
        {label}
        {tooltipText && (
          <>
            {" "}
            <EditLabelTooltip tooltipText={tooltipText} />
          </>
        )}
      </div>
      <div>{value}</div>
    </div>
    {!isReadOnly && (
      <Button kind="plain" label="Edit" size="m" onClick={editOnClick} />
    )}
  </div>
);

export default ProfileSettingRow;
