import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, LoadingSkeleton, RadioButtons } from "@narmi/design_system";
import { fetchSudoCode, fetchSudoDevices } from "./utils";
import HeaderWithSteps from "../HeaderWithSteps";
import { useSudoContext, NUMBER_OF_SUDO_STEPS } from "./SudoContext";
import Container from "../Container";
import BackButton from "./BackButton";
import ActionBar from "./ActionBar";

import styles from "./RequestOTP.module.scss";
import buttonStyles from "./ActionBar.module.scss";

interface RequestOTPProps {
  isInDialog?: boolean;
}

const RequestOTP = ({ isInDialog }: RequestOTPProps = {}) => {
  const { totalSteps, originalRoute, submitOtpRoute, onCancel } =
    useSudoContext();
  const navigate = useNavigate();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [loadingDevices, setLoadingDevices] = useState(true);
  const [devices, setDevices] = useState<{ name: string; id: string }[]>([]);

  useEffect(() => {
    // We expect a 403 forbidden here when we're fetching MFA devices. A quirk of the API.
    // There are 3 uses for the API:
    // 1. Check if Sudo has been established with a GET
    // 2. Get the possible Sudo devices with a POST - this is what we're doing here it will return a 403
    // 3. Establish Sudo with a POST while passing a device ID
    fetchSudoDevices()
      .catch((response) => {
        if (!response.devices?.length) {
          return;
        }
        setSelectedDeviceId(response.device?.id);
        setDevices(response.devices);
      })
      .finally(() => {
        setLoadingDevices(false);
      });
  }, []);

  const sendDevice = async () => {
    if (!selectedDeviceId) {
      return;
    }

    const message = await fetchSudoCode(selectedDeviceId);

    navigate(submitOtpRoute, {
      state: { selectedDeviceId, helpMessage: message },
    });
  };

  const subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    */
    <div style={{ marginTop: "-4px" }}>
      For your security, we need to verify your identity. How would you like to
      receive your verification code?
    </div>
  );

  const content = devices?.length ? (
    <>
      <div>
        <RadioButtons
          name="deviceSelect"
          options={Object.fromEntries(devices.map((d) => [d.name, d.id]))}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedDeviceId(e.target.value)
          }
          value={selectedDeviceId}
        />
      </div>
      <ActionBar align={isInDialog ? "right" : "center"}>
        <BackButton
          to={originalRoute || "/"}
          label="Cancel"
          onClick={onCancel}
        />
        <div className={buttonStyles.continueButtonWrapper}>
          <Button label="Continue" onClick={sendDevice} />
        </div>
      </ActionBar>
    </>
  ) : (
    <div>
      <span data-testid="settings-message">
        Please go to your{" "}
        <Button
          kind="plain"
          onClick={() => window.location.assign("/settings")}
          label="Settings"
        />{" "}
        and add a two factor authentication device.
      </span>
    </div>
  );

  return (
    <LoadingSkeleton isLoading={loadingDevices}>
      {isInDialog ? (
        <>
          <div className={styles.subheaderText}>{subheader}</div>
          {content}
        </>
      ) : (
        <Container>
          <HeaderWithSteps
            step={totalSteps ? totalSteps - NUMBER_OF_SUDO_STEPS : null}
            totalSteps={totalSteps}
            headerText="Let's verify your account"
            subheaderText={subheader}
          />
          {content}
        </Container>
      )}
    </LoadingSkeleton>
  );
};

export default RequestOTP;
