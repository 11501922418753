/* eslint-disable camelcase */

import { useEffect, useState } from "react";
import {
  ContentCard,
  Row,
} from "cerulean";
import { entities } from "byzantine";
import { Button, LoadingSkeleton, MenuButton, Tag } from "@narmi/design_system";
import RecipientDrawer, {
  DRAWER_TYPES,
} from "../../transfer/ach/RecipientDrawer/RecipientDrawer";
import DeleteDialog from "./DeleteDialog";

interface ManageRecipientPopoverProps {
  handleToggleDeleteDialog: () => void;
  handleToggleEditDrawer: () => void;
}

const ManageRecipientPopover = ({
  handleToggleEditDrawer,
  handleToggleDeleteDialog,
}: ManageRecipientPopoverProps) => (
  <MenuButton triggerIcon="more-vertical">
    <MenuButton.Item
      label="Edit"
      startIcon="edit-2"
      onSelect={handleToggleEditDrawer}
    />
    <MenuButton.Item
      label="Delete"
      startIcon="trash-2"
      onSelect={handleToggleDeleteDialog}
    />
  </MenuButton>
);

interface RecipientCardProps {
  recipient: API.Recipient;
}

const RecipientCard = ({ recipient }: RecipientCardProps) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { name, nickname, fedwire_destination, ach_destination } = recipient;
  const { deleteRecipient } = entities.recipients.useDeleteRecipient();

  const toggleEditDrawer = () => {
    setIsEditDrawerOpen(!isEditDrawerOpen);
  };

  const toggleDeleteDialog = () => {
    setIsDeleteDialogOpen(!isDeleteDialogOpen);
  };

  const handleDeleteRecipient = () => {
    toggleDeleteDialog();
    deleteRecipient(recipient.id);
  };

  return (
    <>
      <ContentCard>
        <div className="recipient-section-header">
          <div
            className="fontColor--heading fontWeight--semibold"
            style={{ overflowWrap: "anywhere" }}
          >
            {name}
          </div>
          <div style={{ marginTop: "-4px" }}>
            <ManageRecipientPopover
              handleToggleDeleteDialog={toggleDeleteDialog}
              handleToggleEditDrawer={toggleEditDrawer}
            />
          </div>
        </div>
        <div className="margin--top--xxs" style={{ height: "20px" }}>
          {nickname}
        </div>
        <div className="margin--top--m">
          {ach_destination?.account_number ? (
            <span className="margin--right--xs">
              <Tag kind="subdued" label="ACH" />
            </span>
          ) : null}
          {fedwire_destination?.account_number ? (
            <Tag kind="subdued" label="Wire" />
          ) : null}
        </div>
      </ContentCard>
      <DeleteDialog
        recipientName={name}
        recipientType="ACH recipient"
        onDelete={handleDeleteRecipient}
        isOpen={isDeleteDialogOpen}
        closeDialog={toggleDeleteDialog}
      />
      <RecipientDrawer
        handleClose={toggleEditDrawer}
        isOpen={isEditDrawerOpen}
        drawerType={DRAWER_TYPES.edit}
        recipientId={recipient.id}
      />
    </>
  );
};

// for now, this will only show ACH recipients
// later on, it will show "universal" recipients (both ACH and wire)
const RecipientsSection = () => {
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const { send: fetchRecipients, loading } =
    entities.recipients.useFetchRecipients();
  const recipients = entities.recipients.useRecipients();

  useEffect(() => {
    fetchRecipients();
  }, []);

  const toggleAddDrawer = () => {
    setIsAddDrawerOpen(!isAddDrawerOpen);
  };

  return (
    <>
      <div className="margin--top--xl margin--bottom--l">
        <Row>
          <Row.Item>
            <div className="fontSize--l fontColor--heading fontWeight--bold">
              Recipients
            </div>
          </Row.Item>
          <Row.Item shrink>
            <Button
              kind="plain"
              label="Add a new recipient"
              onClick={toggleAddDrawer}
            />
          </Row.Item>
        </Row>
      </div>
      <LoadingSkeleton content="paragraph" isLoading={loading}>
        <div className="recipients-container">
          {recipients?.map((recipient) => (
            <RecipientCard key={recipient.id} recipient={recipient} />
          ))}
          {!recipients?.length && (
            <div>You don&apos;t have any recipients.</div>
          )}
        </div>
      </LoadingSkeleton>
      <RecipientDrawer
        handleClose={toggleAddDrawer}
        isOpen={isAddDrawerOpen}
        drawerType={DRAWER_TYPES.add}
      />
    </>
  );
};

export default RecipientsSection;
