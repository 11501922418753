import { createDeepEqualSelector } from "../utils";

import { userAlertEntityAdapter } from "./adapter";

import type { RootState } from "../types";

export const getUserAlertState = (state: RootState) => state.userAlerts;

const { selectAll: selectAllAlerts } =
  userAlertEntityAdapter.getSelectors(getUserAlertState);

export { selectAllAlerts };

export const selectUserAlertByKey = createDeepEqualSelector(
  [selectAllAlerts, (_: RootState, key: API.AlertKey) => key],
  (userAlerts, alertKey) =>
    userAlerts?.find((alert) => alert.alert_key === alertKey),
);

export const selectUserAlert = (key: API.AlertKey) => (state: RootState) =>
  selectUserAlertByKey(state, key);
