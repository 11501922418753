import type {
  AccountAlertForm,
  BaseAlertForm,
  LoanAlertForm,
  TransactionAlertForm,
} from "./form";

export const getAlertPartitionFromAlertKey = (
  key: API.AlertKey,
): API.AlertPartition | null => {
  switch (key) {
    case "credit":
    case "debit":
      return "transaction";
    case "available_balance":
    case "low_available_balance":
      return "account";
    case "loan_overdue":
    case "loan_reminder":
      return "loan";
    default:
      return null;
  }
};

export const isTransactionAlert = (
  alert: API.UserAlert,
): alert is API.UserTransactionAlert => {
  return ["credit", "debit"].includes(alert.alert_key);
};

export const isAccountAlert = (
  alert: API.UserAlert,
): alert is API.UserAccountAlert => {
  return ["available_balance", "low_available_balance"].includes(
    alert.alert_key,
  );
};

export const isLoanAlert = (
  alert: API.UserAlert,
): alert is API.UserLoanAlert => {
  return ["loan_reminder", "loan_overdue"].includes(alert.alert_key);
};

export const isTransactionForm = (
  values: BaseAlertForm,
  key: API.AlertKey,
): values is TransactionAlertForm => {
  return ["credit", "debit"].includes(key);
};

export const isAccountForm = (
  values: BaseAlertForm,
  key: API.AlertKey,
): values is AccountAlertForm => {
  return ["available_balance", "low_available_balance"].includes(key);
};

export const isLoanForm = (
  values: BaseAlertForm,
  key: API.AlertKey,
): values is LoanAlertForm => {
  return ["loan_reminder", "loan_overdue"].includes(key);
};
