/* eslint-disable no-param-reassign */
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import {
  actions as dualApprovalsActions,
  isScheduledACHDualApproval,
} from "../../entities/dualApprovals/slice";

export type StoreScheduledACHPayment = {
  id: API.ACHPaymentId;
  amount: Cents;
  memo: string;
  recurring_rule: string;
  institution_account: API.AccountId;
  recipient: API.RecipientId;
};

export type ScheduledAchPaymentSlice = {
  byId: Record<API.ACHPaymentId, StoreScheduledACHPayment>;
};

const initialState: ScheduledAchPaymentSlice = {
  byId: {},
};

const createDA = (da: API.ScheduledACHDualApproval) => {
  return {
    id: da.scheduled_ach_payment.uuid,
    amount: da.scheduled_ach_payment.amount,
    memo: da.scheduled_ach_payment.memo,
    recurring_rule: da.scheduled_ach_payment.recurring_rule,
    institution_account: da.scheduled_ach_payment.institution_account,
    recipient: da.scheduled_ach_payment.recipient,
  };
};

const slice = createSlice({
  name: "scheduledACHPayments",
  initialState,
  reducers: {
    receive: (state, action: PayloadAction<API.ScheduledACHPayment>) => {
      const scheduledACHPayment = action.payload;
      state.byId[scheduledACHPayment.uuid].id = scheduledACHPayment.uuid;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dualApprovalsActions.receive, (state, action) => {
      if ("dualApprovals" in action.payload) {
        const { dualApprovals } = action.payload;
        dualApprovals?.forEach((da) => {
          if (isScheduledACHDualApproval(da)) {
            state.byId[da.scheduled_ach_payment.uuid] = createDA(da);
          }
        });
      } else {
        const { dualApproval } = action.payload;
        if (isScheduledACHDualApproval(dualApproval)) {
          state.byId[dualApproval.scheduled_ach_payment.uuid] =
            createDA(dualApproval);
        }
      }
    });
  },
});

export const { actions, reducer } = slice;

export default {
  scheduledACHPayments: slice.reducer,
};
