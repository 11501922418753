import ApiHttp from "byzantine/src/ApiHttp";
import { Button } from "@narmi/design_system";
import {
  ContextForm,
  Dialog,
  SimpleDateInput,
  TaxIDTextInput,
  TextInput,
  useFormData,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import { useTranslation } from "react-i18next";

interface LinkInternalAccountModalProps {
  linkInternalAccountModalOpen: boolean;
  setLinkInternalAccountModalOpen: CallableFunction;
  fetchMemberships: CallableFunction;
}

interface MembershipPostResponse {
  message: string;
  email: string;
}
const LinkInternalAccountModal = ({
  linkInternalAccountModalOpen,
  setLinkInternalAccountModalOpen,
  fetchMemberships,
}: LinkInternalAccountModalProps) => {
  const { t } = useTranslation();
  const { formData, onChange, setFormData } = useFormData();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const onSubmit = async () => {
    setIsLoading(true);
    const payload = { ...formData, user_category: "personal" };
    try {
      const { message }: MembershipPostResponse = await ApiHttp.fetch(
        "accounts/membership",
        { method: "POST" },
        payload
      );
      setLinkInternalAccountModalOpen(false);
      setIsLoading(false);
      fetchMemberships();
      sendNotification({ type: "success", text: message });
    } catch (err: unknown) {
      setLinkInternalAccountModalOpen(false);
      setIsLoading(false);
      sendNotification({
        type: "negative",
        text: t(err as string),
      });
    }
    setFormData({});
  };

  return (
    <Dialog
      isOpen={linkInternalAccountModalOpen}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            kind="negative"
            onClick={() => setLinkInternalAccountModalOpen(false)}
          >
            {t("Cancel")}
          </Button>
          <span className="margin--left--s">
            <Button onClick={onSubmit}>{t("Link account")}</Button>
          </span>
        </div>
      }
      onUserDismiss={() => setLinkInternalAccountModalOpen(false)}
      title={t("Link another account")}
    >
      <div>Enter your information below and we’ll link your account.</div>
      <div className="padding--top--l">
        <ContextForm data={formData} onChange={onChange}>
          <ContextForm.Field required key="primary_account_number">
            <TextInput field="primary_account_number" label="Account number" />
          </ContextForm.Field>
          <ContextForm.Field required key="ssn">
            <TaxIDTextInput field="ssn" label="Social security number/ITIN" />
          </ContextForm.Field>
          <ContextForm.Field required key="date_of_birth">
            <SimpleDateInput field="date_of_birth" label="Date of birth" />
          </ContextForm.Field>
        </ContextForm>
      </div>
    </Dialog>
  );
};

export default LinkInternalAccountModal;
