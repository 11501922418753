import React from "react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";

const DeviceItem = ({
  device,
  isQueuedForRemoval,
  isRemovable,
  onRemove,
  showHorizontalRule,
}) => {
  const iconForDeviceType = (d) => {
    if (d.device_type === "mobile" || d.id.includes("TOTPDevice"))
      return "smartphone";
    else if (d.name === "Email") return "mail";
    else if (d.id.includes("StaticDevice") || d.name === "Backup codes")
      return "security";
    return "smartphone";
  };
  return (
    <>
      <div className="device-details">
        <div style={{ display: "flex" }}>
          <span
            className={`narmi-icon-${iconForDeviceType(
              device
            )} mfa-device-icon`}
          />
          <p>{device.name}</p>
        </div>
        {isRemovable && (
          <div>
            <Button kind="negative" onClick={() => onRemove(device)}>
              {isQueuedForRemoval ? "Are you sure?" : "Remove"}
            </Button>
          </div>
        )}
      </div>
      {showHorizontalRule && <hr />}
    </>
  );
};
DeviceItem.propTypes = {
  device: PropTypes.object.isRequired,
  isQueuedForRemoval: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  showHorizontalRule: PropTypes.bool.isRequired,
};

export default DeviceItem;
