import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import Account from "byzantine/src/Account";
import { Button, Tooltip } from "@narmi/design_system";
import {
  ContextForm,
  Dialog,
  DateInput,
  formatNumber,
  useFormData,
  TextFit,
} from "cerulean";
import { useLocalization } from "@fluent/react";
import Filters from "byzantine/src/filters";
import UserFeaturesContext from "./contexts/UserFeaturesContext";
import useReviewableAction from "./useReviewableAction";
import utils from "../utils";
import InformationRow from "./InformationRow";

const HeroBannerItem = ({ title, value }) => {
  if (!title || !value) return null;
  return (
    <div className="hero-banner-item">
      <div className="title">{title}</div>
      <div className="value">
        <TextFit text={value} />
      </div>
    </div>
  );
};
HeroBannerItem.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
};

const LoanPayoffForm = ({ formData, onChange, onCancel, onSubmit }) => (
    <>
      <div>Choose a date to see what your loan payoff will be.</div>
      <ContextForm data={formData} onChange={onChange} nativeForm={false}>
        <div className="margin--top--l">
          <ContextForm.Field required>
            <DateInput
              minDate={"today"}
              altInput={true}
              altFormat={"m/d/Y"}
              field="date"
              label="Date (mm/dd/yyyy)"
            />
          </ContextForm.Field>
        </div>
        <div className="loan-payoff-dialog-action-bar margin--top--xl">
          <ContextForm.Action
            noValidation
            onSubmit={onCancel}
            dangerouslyDisableShowLoading
          >
            <Button kind="negative">Cancel</Button>
          </ContextForm.Action>
          <div className="margin--left--m">
            <ContextForm.Action
              onSubmit={onSubmit}
              dangerouslyDisableShowLoading
            >
              <Button label="Calculate" />
            </ContextForm.Action>
          </div>
        </div>
      </ContextForm>
    </>
  );
LoanPayoffForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const LoanPayoffSummary = ({ summary, onCancel, startOver }) => {
  const { l10n } = useLocalization();
  return (
    <>
      <div>
        A payment of <strong>{Filters.currency(summary.total)}</strong> is
        required to pay off this loan on{" "}
        <strong>
          {DateTime.fromFormat(summary.payoffDate, "yyyy-MM-dd").toFormat(
            "MMMM dd, yyyy"
          )}
        </strong>
        .
      </div>
      <div className="margin--top--l">
        <div>
          <InformationRow
            label="Total balance"
            value={Filters.currency(summary.total)}
          />
          <InformationRow
            label="Principal balance"
            value={Filters.currency(summary.principal)}
          />
          <InformationRow
            label="Interest"
            value={Filters.currency(summary.interest)}
          />
        </div>
        <div className="fontColor--secondary margin--top--l">
          {l10n.getString(
            "note-interest-payment-amount",
            null,
            "Note: Interest accrues daily and the total payment amount may change depending on when the payment is received."
          )}
        </div>
        <div className="loan-payoff-dialog-action-bar margin--top--xl">
          <Button kind="negative" onClick={onCancel}>
            Cancel
          </Button>
          <div className="margin--left--m">
            <Button label="Choose another date" onClick={startOver} />
          </div>
        </div>
      </div>
    </>
  );
};
LoanPayoffSummary.propTypes = {
  summary: PropTypes.object,
  onCancel: PropTypes.func,
  startOver: PropTypes.func,
};

const LoanPayoffModal = ({ isOpen, setIsOpen, account }) => {
  const { formData, onChange } = useFormData();
  const { isInput, goToInput, goToReview } = useReviewableAction();
  const [summaryData, setSummaryData] = useState({});

  const onSubmit = () => {
    const interest = account.calculatePayoffInfo(formData.date);
    setSummaryData(interest);
    goToReview();
  };
  const startOver = () => {
    onChange({ date: null });
    setSummaryData({});
    goToInput();
  };
  const closeDialog = () => {
    startOver();
    setIsOpen(false);
  };
  return (
    <Dialog
      title="Calculate loan payoff"
      isOpen={isOpen}
      onUserDismiss={closeDialog}
    >
      {isInput ? (
        <LoanPayoffForm
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          onCancel={closeDialog}
        />
      ) : (
        <LoanPayoffSummary
          summary={summaryData}
          startOver={startOver}
          onCancel={closeDialog}
        />
      )}
    </Dialog>
  );
};
LoanPayoffModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  account: PropTypes.object,
};

const LoanPayoffButton = ({ account, mobile = false }) => {
  const userFeatures = useContext(UserFeaturesContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return account.canCalculateLoanPayoff(userFeatures) ? (
    <>
      <div className={`${mobile ? "mobile-" : ""}hero-banner-button-container`}>
        <div className={`${mobile ? "mobile-" : ""}hero-banner-button`}>
          <Button kind="secondary" onClick={() => setIsDialogOpen(true)}>
            Calculate loan payoff
          </Button>
        </div>
      </div>
      <LoanPayoffModal
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        account={account}
      />
    </>
  ) : null;
};
LoanPayoffButton.propTypes = {
  account: PropTypes.object,
  mobile: PropTypes.bool,
};

export const getHeroBannerContents = (account, l10n) => {
  const contents = [];
  // checking and savings accounts do not get hero banners
  if (
    ![
      ...Account.PRODUCT_GROUPS["Credit cards"],
      ...Account.PRODUCT_GROUPS.CDs,
      ...Account.PRODUCT_GROUPS.Loans,
    ].includes(account.product_type)
  )
    return null;

  // display ledger balance
  let balanceLedgerTitle = "Balance";
  if (account.product_type === "mortgage") {
    // mortgages say "Balance" and have a tooltip
    balanceLedgerTitle = (
      <Tooltip
        text={l10n.getString(
          "tooltip-balance-accuracy",
          null,
          "This balance is accurate as of the current business day."
        )}
      >
        Balance
      </Tooltip>
    );
  } else if (
    account.account_type === "deposit" ||
    account.product_type === "credit_card"
  ) {
    // CD types and credit cards label ledger balance as "Current balance" instead
    balanceLedgerTitle = "Current balance";
  }
  contents.push({
    title: balanceLedgerTitle,
    value: account.balances.ledger,
  });

  // display minimum payment if it exists
  if (![null, undefined, ""].includes(account.loan_details?.minimum_payment)) {
    let paymentTitle =
      account.product_type === "credit_card"
        ? l10n.getString("title-minimum-payment", null, "Minimum payment")
        : l10n.getString("title-next-payment", null, "Next payment");
    // display when the next payment is due if it exists
    if (account.loan_details?.next_payment_at) {
      paymentTitle = `${paymentTitle} (due ${Filters.shortDate(
        account.loan_details.next_payment_at
      )})`;
    }
    contents.push({
      title: paymentTitle,
      value: formatNumber(account.loan_details.minimum_payment),
    });
  }

  // display maturity date if it exists
  if (
    account.metadata?.maturity_date?.value &&
    [...Account.PRODUCT_GROUPS.CDs].includes(account.product_type)
  ) {
    contents.push({
      title: "Maturity date",
      value: account.formattedMetadata(utils.getNDSFormatters()).maturity_date
        .value,
    });
  }

  return contents;
};

const HeroBanner = ({ account }) => {
  const { l10n } = useLocalization();
  const contents = getHeroBannerContents(account, l10n);
  if (!contents) return null;
  return (
    <>
      <div className="hero-banner-container">
        <div className="hero-banner-items">
          {contents.map((item, i) => (
            <HeroBannerItem key={i} title={item.title} value={item.value} />
          ))}
        </div>
        <LoanPayoffButton account={account} />
      </div>
      <LoanPayoffButton account={account} mobile={true} />
    </>
  );
};
HeroBanner.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
};

export default HeroBanner;
