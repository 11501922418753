import { useState } from "react";
import { Button, Checkbox } from "@narmi/design_system";
import { Dialog, Row } from "cerulean";
import { useLocalization } from "@fluent/react";

export type DualApprovalRecurringDialogType = {
  isOpen: boolean;
  handleClose: () => void;
  handleApprove: () => void;
};

const DualApprovalRecurringDialog = ({
  isOpen,
  handleClose,
  handleApprove,
}: DualApprovalRecurringDialogType) => {
  const { l10n } = useLocalization();
  const [doNotShow, setDoNotShow] = useState(false);
  const hideDoNotShowAgainCta = Boolean(
    window.localStorage.getItem("hide-dual-approval-recurring-dialog")
  );

  const handleToggleDoNotShowDialogAgain = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const doNotShowDialogAgain = e.target.checked;
    if (doNotShowDialogAgain) {
      setDoNotShow(true);
    } else {
      setDoNotShow(false);
    }
  };

  const handleClickApprove = () => {
    if (doNotShow && window.localStorage) {
      window.localStorage.setItem(
        "hide-dual-approval-recurring-dialog",
        "true"
      );
    }
    handleApprove();
    handleClose();
  };

  return (
    <Dialog
      width="385px"
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={l10n.getString("recurring-modal-heading")}
      footer={
        <Row alignItems="center">
          <Row.Item>
            <Button kind="plain" onClick={handleClose}>
              {l10n.getString("button-cancel")}
            </Button>
          </Row.Item>
          <Row.Item shrink>
            <Button onClick={handleClickApprove}>
              {l10n.getString("approve-modal-confirm")}
            </Button>
          </Row.Item>
        </Row>
      }
    >
      <span className="margin--bottom--xl">
        {l10n.getString("recurring-modal-content")}
        <div className="margin--top--xl margin--bottom--l">
          {!hideDoNotShowAgainCta ? (
            <Checkbox
              name="recurringModal"
              label={l10n.getString("recurring-modal-cta")}
              onChange={handleToggleDoNotShowDialogAgain}
            />
          ) : null}
        </div>
      </span>
    </Dialog>
  );
};

export default DualApprovalRecurringDialog;
