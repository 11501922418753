import { useLocalization } from "@fluent/react";
import { Button, MenuButton, SeparatorList } from "@narmi/design_system";
import ApiHttp from "byzantine/src/ApiHttp";
import { featureEquals } from "byzantine/src/Feature";
import Filters from "byzantine/src/filters";
import utils from "byzantine/src/utils";
import Account from "byzantine/src/Account";
import {
  ContentCard,
  ContextForm,
  Dialog,
  Row,
  TextInput,
  TruncatedAccount,
  formatNumber,
  useFormData,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AmountTextInput from "../../form/AmountTextInput";
import AddAccountModal from "../../transfer/AddAccountModal";
import azulUtils from "../../../utils";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";

const EditAccountDialog = ({
  account,
  isDialogOpen,
  setDialogOpen,
  onAccountUpdated,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const { formData, setFormData, onChange } = useFormData({
    name: account.name,
  });
  const [initialAccountName, setInitialAccountName] = useState(account.name);

  if (initialAccountName !== account.name) {
    setFormData({ name: account.name });
    setInitialAccountName(account.name);
  }

  function editName(callback) {
    setIsLoading(true);
    const { name } = formData;
    ApiHttp.fetch(`accounts/${account.id}`, { method: "PUT" }, { name })
      .then((response) => {
        onAccountUpdated(Account.deserialize(response.account));
        setDialogOpen(false);
        sendNotification({
          type: "success",
          text: l10n.getString("external-account-name-changed"),
        });
      })
      .catch(() => {
        callback("Something went wrong.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={() => {
        setDialogOpen(false);
      }}
      title={l10n.getString("dialog-title-edit-account-name")}
    >
      <ContextForm data={formData} onChange={onChange}>
        <div className="margin--top--s">
          <ContextForm.Field required>
            <TextInput
              field="name"
              label={l10n.getString("label-account-name")}
              maxLength={100} // based on max chars for ExternalAccountMember.name_user_override
            />
          </ContextForm.Field>
          <div className="margin--top--s">
            <div className="edit-modal-action-bar">
              <ContextForm.Action
                noValidation
                onSubmit={() => setDialogOpen(false)}
                dangerouslyDisableShowLoading
              >
                <Button
                  kind="negative"
                  label={l10n.getString("button-cancel")}
                  type="button"
                />
              </ContextForm.Action>
              <div className="margin--left--m">
                <ContextForm.Action onSubmit={editName}>
                  <Button label={l10n.getString("button-save")} />
                </ContextForm.Action>
              </div>
            </div>
          </div>
        </div>
      </ContextForm>
    </Dialog>
  );
};

EditAccountDialog.propTypes = {
  account: PropTypes.object,
  onAccountUpdated: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
};

const VerifyAccountDialog = ({
  account,
  isDialogOpen,
  setDialogOpen,
  callback,
  uatMicrodepAmntOne,
  uatMicrodepAmntTwo,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { formData, setFormData, onChange } = useFormData({});

  function closeDialog() {
    setDialogOpen(false);
    setFormData({});
  }

  function submitMicroDeposit(formCallback) {
    const amount1 = utils.dollarsToPennies(
      formData.deposit1.replace(/[^\d.]/g, ""),
    );
    const amount2 = utils.dollarsToPennies(
      formData.deposit2.replace(/[^\d.]/g, ""),
    );
    ApiHttp.fetch(
      `accounts/${account.id}/verify/`,
      {
        method: "POST",
      },
      {
        amounts: [amount1, amount2],
      },
    )
      .then((response) => {
        const updatedAccount = response.accounts[0];
        formCallback();
        callback(Account.deserialize(updatedAccount));
        closeDialog();
        sendNotification({
          type: "success",
          text: l10n.getString("external-account-verify-success"),
        });
      })
      .catch((error) => {
        formCallback(
          error?.amounts?.[0] ||
            azulUtils.extractErrorMessage(
              error,
              l10n.getString(
                "error-generic",
                null,
                l10n.getString("error-unknown"),
              ),
            ),
        );
      });
  }
  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={() => {
        setDialogOpen(false);
        setFormData({});
      }}
      title={l10n.getString("dialog-title-verify-account")}
    >
      <p>{l10n.getString("account-verify-microdeposits")}</p>
      {!!uatMicrodepAmntOne && (
        <div className="message-content success margin--bottom--m">
          <span
            className="narmi-icon-alert-circle margin--right--s"
            color="var(--color-successDark)"
          />
          <span className="margin--right--l">
            For the purpose of this demo, please enter the following values:{" "}
            {formatNumber(uatMicrodepAmntOne)} and{" "}
            {formatNumber(uatMicrodepAmntTwo)}.
          </span>
        </div>
      )}
      <ContextForm data={formData} onChange={onChange}>
        <div className="margin--bottom--l">
          <ContextForm.Field required>
            <AmountTextInput
              field="deposit1"
              label={l10n.getString("label-first-amount")}
            />
          </ContextForm.Field>
        </div>
        <ContextForm.Field required>
          <AmountTextInput
            field="deposit2"
            label={l10n.getString("label-second-amount")}
          />
        </ContextForm.Field>
        <div className="margin--top--xl">
          <Row alignItems="center" justifyContent="end">
            <Row.Item shrink>
              <ContextForm.Action
                noValidation
                dangerouslyDisableShowLoading
                onSubmit={closeDialog}
              >
                <Button
                  kind="negative"
                  label={l10n.getString("button-cancel")}
                  type="button"
                />
              </ContextForm.Action>
            </Row.Item>
            <Row.Item shrink>
              <ContextForm.Action
                onSubmit={(formCallback) => {
                  submitMicroDeposit(formCallback);
                }}
              >
                <Button
                  kind="primary"
                  label={l10n.getString("button-verify")}
                />
              </ContextForm.Action>
            </Row.Item>
          </Row>
        </div>
      </ContextForm>
    </Dialog>
  );
};

VerifyAccountDialog.propTypes = {
  account: PropTypes.object,
  isDialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  callback: PropTypes.func,
  uatMicrodepAmntOne: PropTypes.string,
  uatMicrodepAmntTwo: PropTypes.string,
};

const DeleteAccountDialog = ({
  account,
  isDialogOpen,
  setDialogOpen,
  callback,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  function deleteAccount() {
    ApiHttp.fetch(`accounts/${account.id}`, { method: "DELETE" })
      .then(() => {
        sendNotification({
          type: "success",
          text: l10n.getString("external-account-removed"),
        });
        callback();
      })
      .catch((errorText) => {
        sendNotification({
          type: "negative",
          text: errorText,
        });
      });
  }
  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={() => {
        setDialogOpen(false);
      }}
      title={l10n.getString("dialog-title-remove-account")}
      footer={
        <Row alignItems="center" justifyContent="end">
          <Row.Item shrink>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              kind="negative"
              label={l10n.getString("button-cancel")}
              type="button"
            />
          </Row.Item>
          <Row.Item shrink>
            <Button
              onClick={() => {
                deleteAccount();
                setDialogOpen(false);
              }}
              kind="primary"
              label={l10n.getString("button-confirm-remove")}
            />
          </Row.Item>
        </Row>
      }
    >
      <p>
        {l10n.getString("external-account-remove-confirm", {
          accountName: account.name,
        })}
      </p>
    </Dialog>
  );
};

DeleteAccountDialog.propTypes = {
  account: PropTypes.object,
  callback: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
};

const ExternalAccountManager = ({
  externalAccounts,
  uatMicrodepAmntOne,
  uatMicrodepAmntTwo,
}) => {
  const { l10n } = useLocalization();
  const [currentExternalAccounts, setExternalAccounts] =
    useState(externalAccounts);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isVerifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
  const [accountDialog, setAccountDialog] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(
    externalAccounts.length ? externalAccounts[0] : { name: "", id: "" },
  );
  const {
    ach,
    allow_external_account_linking_without_transferable_accounts,
    ...otherFeatures
  } = useUserFeatures();

  const hasExternalAccountCreatePermission = featureEquals(
    otherFeatures,
    "indigo.ExternalAccount_permission",
    "*",
  );
  const canLinkExternalAccount =
    hasExternalAccountCreatePermission &&
    (ach || allow_external_account_linking_without_transferable_accounts);

  const AccountCard = ({ externalAccount }) => (
    <div className="external-account-content-wrapper">
      <ContentCard>
        <Row>
          <Row.Item shrink>
            {externalAccount.fi_svg ? (
              <img
                alt="banklogo"
                style={{ height: "40px", width: "40px" }}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  externalAccount.fi_svg,
                )}`}
              ></img>
            ) : (
              <div className="generic-bank-logo">
                <span className="narmi-icon-bank" />
              </div>
            )}
          </Row.Item>
          <Row.Item>
            <div className="fontColor--heading">
              {Filters.capsToCamel(externalAccount.fi_name)}
            </div>
            <TruncatedAccount
              lastFour={externalAccount.number.slice(-4)}
              name={externalAccount.getNameWithoutMaskedAccountNumber()}
            />
            <div className="fontSize--s fontColor--secondary margin--top--s">
              {externalAccount.verified ? (
                <SeparatorList
                  items={[
                    <span className="italic" key="name">
                      {Filters.humanize(
                        externalAccount.product_type ||
                          externalAccount.product.type,
                      )}
                    </span>,
                    <span className="italic" key="verified">
                      {l10n.getString("account-verified-date", {
                        verificationDate: Filters.americanDate(
                          externalAccount.updated_at,
                        ),
                      })}
                    </span>,
                  ]}
                />
              ) : (
                <div className="italic">
                  <span>{l10n.getString("account-status-pending")} </span>
                  <Button
                    kind="plain"
                    size="s"
                    label={l10n.getString("button-verify-now")}
                    onClick={() => {
                      setVerifyDialogOpen(true);
                      setSelectedAccount(externalAccount);
                    }}
                  />
                </div>
              )}
            </div>
          </Row.Item>
          <Row.Item shrink>
            <MenuButton triggerIcon="more-vertical">
              <MenuButton.Item
                label={l10n.getString("account-action-rename")}
                startIcon="edit-2"
                onSelect={() => {
                  setSelectedAccount(externalAccount);
                  setEditDialogOpen(true);
                }}
              />
              <MenuButton.Item
                label={l10n.getString("account-action-delete")}
                startIcon="trash-2"
                onSelect={() => {
                  setSelectedAccount(externalAccount);
                  setRemoveDialogOpen(true);
                }}
              />
            </MenuButton>
          </Row.Item>
        </Row>
      </ContentCard>
    </div>
  );
  AccountCard.propTypes = {
    externalAccount: PropTypes.object,
  };
  return (
    <>
      <div className="external-account-card-list">
        {currentExternalAccounts.length ? (
          currentExternalAccounts.map((account) => (
            <AccountCard key={account.id} externalAccount={account} />
          ))
        ) : (
          <span>{l10n.getString("no-linked-accounts")}</span>
        )}
      </div>
      {canLinkExternalAccount && (
        <Button
          kind="primary"
          onClick={() => {
            setAccountDialog(true);
          }}
          label={l10n.getString("button-link-new-account")}
        />
      )}
      <EditAccountDialog
        account={selectedAccount}
        isDialogOpen={isEditDialogOpen}
        setDialogOpen={setEditDialogOpen}
        onAccountUpdated={(updatedAccount) => {
          setExternalAccounts(
            currentExternalAccounts.map((obj) =>
              updatedAccount.id === obj.id ? updatedAccount : obj,
            ),
          );
        }}
      />
      <VerifyAccountDialog
        account={selectedAccount}
        isDialogOpen={isVerifyDialogOpen}
        setDialogOpen={setVerifyDialogOpen}
        callback={(updatedAccount) => {
          setExternalAccounts(
            currentExternalAccounts.map((obj) =>
              updatedAccount.id === obj.id ? updatedAccount : obj,
            ),
          );
        }}
        uatMicrodepAmntOne={uatMicrodepAmntOne}
        uatMicrodepAmntTwo={uatMicrodepAmntTwo}
      />
      <DeleteAccountDialog
        account={selectedAccount}
        isDialogOpen={isRemoveDialogOpen}
        setDialogOpen={setRemoveDialogOpen}
        callback={() => {
          setExternalAccounts(
            currentExternalAccounts.filter(
              (obj) => selectedAccount.id !== obj.id,
            ),
          );
        }}
      />
      <AddAccountModal
        open={accountDialog}
        handleClose={() => setAccountDialog(false)}
        updateAccounts={(updatedAccounts) => {
          setExternalAccounts((accounts) => [
            ...accounts,
            ...updatedAccounts.map((a) => Account.deserialize(a)),
          ]);
        }}
      />
    </>
  );
};

ExternalAccountManager.propTypes = {
  externalAccounts: PropTypes.array,
  uatMicrodepAmntOne: PropTypes.string,
  uatMicrodepAmntTwo: PropTypes.string,
};

export default ExternalAccountManager;
