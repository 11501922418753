import React, { memo } from "react";

import { Button, RadioButtons, ResponsiveFlex } from "@narmi/design_system";
import { ContentCard, Row } from "cerulean";
import { entities } from "byzantine";

import HeaderWithSteps from "../../../../../../../components/HeaderWithSteps";
import WireTransferRedesign from "../../../../../../../components/transfer/wires/WireTransfer";
import * as formAdapters from "../../../../../../adapters";

import { OPTIONS as METHOD_OPTIONS, useFormContainer } from "./Form.container";
import styles from "./Form.module.scss";
import TemplateSelector from "./TemplateSelector";
import DetailsSelector from "./DetailsSelector";

type Props = {
  onSubmit: () => void;
};

function Form({ onSubmit }: Props) {
  const { method, onCancel, onSubmitMethod, form } = useFormContainer();
  const limits = entities.limits.useLimits();

  const MethodSelector = (
    <ContentCard kind="bordered">
      <p className={styles.wireTemplatesFormSubHeader}>Method</p>
      <RadioButtons
        name="options"
        options={METHOD_OPTIONS}
        {...formAdapters.radio(form, "method")}
      />
    </ContentCard>
  );

  if (method === "freeform") {
    return (
      <div className={styles.removePadding}>
        <WireTransferRedesign
          Header={
            <div className={styles.wireTemplatesMethodSelector}>
              {MethodSelector}
            </div>
          }
          limits={limits}
        />
      </div>
    );
  }

  return (
    <div className={styles.sendViaWireTemplateForm}>
      <ResponsiveFlex gapSize="xl">
        <HeaderWithSteps
          headerText="Make a wire transfer"
          step={1}
          totalSteps={2}
        />
        {MethodSelector}
        <TemplateSelector />
        <DetailsSelector />
        <Row alignItems="center">
          <Row.Item>
            <Button label="Cancel" kind="negative" onClick={onCancel} />
          </Row.Item>
          <Row.Item shrink>
            <Button
              label="Next"
              onClick={!method ? onSubmitMethod : onSubmit}
            />
          </Row.Item>
        </Row>
      </ResponsiveFlex>
    </div>
  );
}

export default memo(Form);
