import React, { useState, useEffect } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import Account from "byzantine/src/Account";
import { Button } from "@narmi/design_system";
import { ContextForm, Dialog, Row, useFormData } from "cerulean";
import AddBeneficiaryForm from "./AddBeneficiaryForm";

function useAllOtherBeneficiaries(beneficiaries, allBeneficiaries) {
  /*
    This custom hook returns all the beneficiaries across all accounts but filtering out the passed-in beneficiaries
    (which are implied to come from 1 account).
    The reason for this is because this hook is used to populate the beneficiaries selector with existing beneficiaries
    when adding a new beneficiary to the account. It does not make sense to offer a beneficiary which already exists
    on the account as an option to the user.
   */
  const [allOtherBeneficiaries, setAllOtherBeneficiaries] = useState([]);

  const simplifyBene = (beneficiary) => ({
    displayName: `${beneficiary.first_name} ${beneficiary.last_name}`,
    value: beneficiary.core_user_id,
  });

  useEffect(() => {
    /* filters out the `beneficiaries` from `allAccountBeneficiaries` to get `allOtherBeneficiaries` */
    const stringifiedBeneficiaries = beneficiaries.map((bene) =>
      JSON.stringify(simplifyBene(bene))
    );
    setAllOtherBeneficiaries(() =>
      allBeneficiaries.filter(
        (bene) => !stringifiedBeneficiaries.includes(JSON.stringify(bene))
      )
    );
  }, [JSON.stringify(beneficiaries), JSON.stringify(allBeneficiaries)]);

  return allOtherBeneficiaries;
}

const BeneficiaryRow = ({
  beneficiary,
  account,
  deleteBeneficiary,
  setBeneficiaries,
}) => {
  const { l10n } = useLocalization();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);
  const openDeleteDialog = () => setIsDeleteDialogOpen(true);

  const onDeleteSubmit = async (callback) => {
    try {
      await deleteBeneficiary(account, beneficiary, setBeneficiaries);
      callback();
    } catch {
      callback(l10n.getString("error-beneficiary-not-removed"));
    } finally {
      closeDeleteDialog();
    }
  };

  return (
    <tr>
      <td>
        {beneficiary.first_name} {beneficiary.last_name}
      </td>
      <td className="padding--right--none">
        <div
          kind="plain"
          onClick={openDeleteDialog}
          role="button"
          tabIndex="0"
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              openDeleteDialog();
            }
          }}
        >
          <i className="clickable hover-icon trash alternate outline icon"></i>
        </div>
        <Dialog
          isOpen={isDeleteDialogOpen}
          onUserDismiss={closeDeleteDialog}
          title={l10n.getString("button-delete-beneficiary")}
        >
          <ContextForm nativeForm={false}>
            <div className="margin--bottom--xl">
              {l10n.getString("confirm-beneficiary-delete", {
                accountName: account.name,
                beneficiaryName: `${beneficiary.first_name} ${beneficiary.last_name}`,
              })}
            </div>
            <Row justifyContent="end" alignItems="center" gapSize="m">
              <Row.Item shrink>
                <Button
                  kind="negative"
                  label={l10n.getString("button-cancel")}
                  onClick={closeDeleteDialog}
                />
              </Row.Item>
              <Row.Item shrink>
                <ContextForm.Action onSubmit={onDeleteSubmit}>
                  <Button
                    kind="primary"
                    label={l10n.getString("button-confirm-delete")}
                  />
                </ContextForm.Action>
              </Row.Item>
            </Row>
          </ContextForm>
        </Dialog>
      </td>
    </tr>
  );
};

BeneficiaryRow.propTypes = {
  beneficiary: PropTypes.object,
  account: PropTypes.object,
  deleteBeneficiary: PropTypes.func,
  setBeneficiaries: PropTypes.func,
};

const AccountBeneficiaries = ({
  account,
  allBeneficiaries,
  fetchBeneficiariesForAccount,
  deleteBeneficiary,
  addBeneficiary,
  supportPhoneNumber,
}) => {
  const { l10n } = useLocalization();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isNewBeneficiaryDialogOpen, setIsNewBeneficiaryDialogOpen] =
    useState(false);
  const { formData, setFormData, onChange } = useFormData({});
  const [showNewBeneficiaryForm, setShowNewBeneficiaryForm] = useState(false);
  const allOtherBeneficiaries = useAllOtherBeneficiaries(
    beneficiaries,
    allBeneficiaries
  );

  const closeDialog = () => {
    setIsNewBeneficiaryDialogOpen(false);
    setFormData({}); // clear AddBeneficiaryForm every time the dialog closes
    setShowNewBeneficiaryForm(false);
  };
  const openDialog = () => {
    setIsNewBeneficiaryDialogOpen(true);
  };

  useEffect(() => {
    fetchBeneficiariesForAccount(account, setBeneficiaries);
  }, []);

  const onSubmit = (callback) => {
    const payload = {
      account_uuid: account.id,
      core_user_id: formData?.beneficiary_id.value,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      birth_date: formData?.birth_date,
      tax_id: formData?.tax_id,
      email: formData?.email,
      phone: formData?.phone,
      address: {
        street_address: formData?.street_address,
        street_address_2: formData?.street_address_2,
        city: formData?.city,
        region_code: formData?.region_code,
        postal_code: formData?.postal_code,
        country_code: "US",
      },
    };

    addBeneficiary(account, payload, setBeneficiaries)
      .then(() => {
        callback();
        setFormData({});
        closeDialog();
      })
      .catch((error) => {
        callback(error);
      });
  };

  if (account.source === "external") {
    return <div></div>;
  }
  return (
    <table className="bene-table">
      <tbody>
        {beneficiaries.map((beneficiary) => (
          <BeneficiaryRow
            key={beneficiary.core_user_id}
            beneficiary={beneficiary}
            account={account}
            deleteBeneficiary={deleteBeneficiary}
            setBeneficiaries={setBeneficiaries}
          />
        ))}
        <tr>
          <td>
            <Button
              kind="secondary"
              label={l10n.getString("beneficiary-add")}
              onClick={openDialog}
            />
            <Dialog
              isOpen={isNewBeneficiaryDialogOpen}
              onUserDismiss={closeDialog}
              title={l10n.getString("beneficiary-add")}
            >
              <div className="long-form margin--top--s ">
                <div className="margin--bottom--s">
                  {l10n.getString("beneficiary-choose")}
                </div>
                <ContextForm data={formData} onChange={onChange}>
                  <AddBeneficiaryForm
                    allOtherBeneficiaries={allOtherBeneficiaries}
                    showNewBeneficiaryForm={showNewBeneficiaryForm}
                    setShowNewBeneficiaryForm={setShowNewBeneficiaryForm}
                    onChange={onChange}
                    data={formData}
                    supportPhoneNumber={supportPhoneNumber}
                  />
                  <div className="sticky-submit-area">
                    <Row justifyContent="end" alignItems="center" gapSize="m">
                      <Row.Item shrink>
                        <ContextForm.Action
                          noValidation
                          onSubmit={closeDialog}
                          dangerouslyDisableShowLoading
                        >
                          <Button
                            type="button"
                            kind="negative"
                            label={l10n.getString("button-cancel")}
                          />
                        </ContextForm.Action>
                      </Row.Item>
                      <Row.Item shrink>
                        <ContextForm.Action onSubmit={onSubmit}>
                          <Button
                            kind="primary"
                            label={l10n.getString("button-save")}
                          />
                        </ContextForm.Action>
                      </Row.Item>
                    </Row>
                  </div>
                </ContextForm>
              </div>
            </Dialog>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

AccountBeneficiaries.propTypes = {
  account: PropTypes.instanceOf(Account),
  allBeneficiaries: PropTypes.array,
  fetchBeneficiariesForAccount: PropTypes.func,
  deleteBeneficiary: PropTypes.func,
  addBeneficiary: PropTypes.func,
  supportPhoneNumber: PropTypes.supportPhoneNumber,
};

export default AccountBeneficiaries;
