import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, useBreakpoints } from "@narmi/design_system";
import {
  ContentCard,
  IconButton,
  catcat as cc,
  Row,
} from "cerulean";
import type { Features } from "byzantine/src/Feature";
import { onboarding } from "byzantine";
import { isRouteInDashboard } from "../useIsDashboardDialogOpen";
import style from "./OnboardingCard.module.scss";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import { useCurrentUser } from "../../contexts/CurrentUserContext";

interface ProgressBarProps {
  percentComplete: number;
  className?: string;
}

const ProgressBar = ({ percentComplete, className }: ProgressBarProps) => (
  <div className={cc(className, style.progressBar)}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width={`${percentComplete}%`} height="100%" rx={2} />
    </svg>
  </div>
);

const PromptIcon = ({ iconName }: { iconName: string }) => (
  <div
    className={cc(
      "alignChild--center--center",
      style.iconWrapper,
      style.promptIcon
    )}
  >
    <span aria-label={iconName} className={`narmi-icon-${iconName}`} />
  </div>
);

const CheckIcon = () => (
  <div
    className={cc(
      "alignChild--center--center",
      style.iconWrapper,
      style.checkIcon
    )}
  >
    <span aria-label="completed" className="narmi-icon-check" />
  </div>
);

interface PromptRowProps {
  prompt: onboarding.OnboardingPrompt;
  className?: string;
}

const PromptRow = ({ prompt, className }: PromptRowProps) => {
  const { description, title, link, icon_name: iconName, status } = prompt;
  const isCompleted = status === onboarding.OnboardingPromptStatus.completed;
  const navigate = useNavigate();

  const routeTo = useCallback((route: string) => {
    const toUrl = new URL(route, window.location.origin);

    if (toUrl.origin === window.location.origin) {
      toUrl.pathname = toUrl.pathname.replace(/^\/mobile/, "");
      if (isRouteInDashboard(toUrl.pathname)) {
        return navigate(toUrl.pathname);
      }
    }
    return window.location.assign(toUrl.toString());
  }, []);

  const Wrapper = ({
    wrapperClassName,
    children,
  }: {
    wrapperClassName: string;
    children: ReactNode;
  }) =>
    isCompleted ? (
      <div className={wrapperClassName}>{children}</div>
    ) : (
      <button
        className={cc(wrapperClassName, "button--reset")}
        onClick={() => routeTo(link)}
      >
        {children}
      </button>
    );

  return (
    <Wrapper
      wrapperClassName={cc(className, style.promptRow, {
        "not-completed": !isCompleted,
      })}
    >
      <Row alignItems="center">
        <Row.Item shrink>
          {isCompleted ? <CheckIcon /> : <PromptIcon iconName={iconName} />}
        </Row.Item>
        <Row.Item>
          <div
            className={cc("fontWeight--semibold padding--bottom--xxs", {
              "fontColor--heading": !isCompleted,
              "fontColor--secondary": isCompleted,
            })}
          >
            {title}
          </div>
          <div className="fontSize--s padding--bottom--xxs fontColor--secondary">
            {description}
          </div>
        </Row.Item>
        {!isCompleted && (
          <Row.Item shrink>
            <div className="alignt--center--center">
              <span className="narmi-icon-arrow-right fontColor--heading" />
            </div>
          </Row.Item>
        )}
      </Row>
    </Wrapper>
  );
};

const OnboardingCard = () => {
  const { l } = useBreakpoints();
  const { onboarding_prompts: onboardingPrompts } =
    useUserFeatures() as Features;
  const { currentUser } = useCurrentUser();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHidden, setIsHidden] = useState(
    !!window.localStorage.getItem(`onboarding-${currentUser?.id || ""}`)
  );

  const { title, description, prompts, fetchOnboardingPrompts } = onboarding.useOnboardingContainer("web");

  useEffect(() => {
    fetchOnboardingPrompts();
  }, [fetchOnboardingPrompts]);

  const uncompletedPromptsCount = useMemo(
    () =>
      prompts.filter(
        (prompt) => prompt.status === onboarding.OnboardingPromptStatus.inProgress
      ).length,
    [prompts]
  );

  const percentComplete = useMemo(
    () => 100 * ((prompts.length - uncompletedPromptsCount) / prompts.length),
    [prompts]
  );

  const onDismiss = useCallback(() => {
    setIsHidden(true);
    if (window.localStorage) {
      window.localStorage.setItem(
        `onboarding-${currentUser?.id || ""}`,
        "true"
      );
    }
  }, [currentUser?.id]);

  if (isHidden || !onboardingPrompts || uncompletedPromptsCount === 0) {
    return null;
  }

  return (
    <div className={cc("margin--bottom--l", { "margin--top--l": l })}>
      <ContentCard kind="elevated">
        <div className="margin--left--xs">
          <Row alignItems="center">
            <Row.Item>
              <h4 className={style.cardTitle}>{title}</h4>
              <div className="margin--top--xs">{description}</div>
            </Row.Item>
            <Row.Item shrink>
              <IconButton
                label="Hide onboarding guide"
                kind="action"
                name={isCollapsed ? "chevron-up" : "chevron-down"}
                onClick={() => setIsCollapsed((state) => !state)}
              />
            </Row.Item>
          </Row>
        </div>
        {!isCollapsed && (
          <>
            <ProgressBar
              percentComplete={percentComplete}
              className="margin--y--l margin--x--xs"
            />
            {prompts.map((prompt) => (
              <PromptRow
                key={prompt.uuid}
                prompt={prompt}
                className="padding--y--xs padding--x--xs"
              />
            ))}
            <div className="margin--y--l border--top" />
            <div className="alignChild--right--center">
              <Button
                label="Dismiss"
                kind="plain"
                size="s"
                onClick={onDismiss}
              />
            </div>
          </>
        )}
      </ContentCard>
    </div>
  );
};

export default OnboardingCard;
