import { useCallback, useMemo } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Select } from "cerulean";

import { ALERT_ROUTES } from "../AlertsNavBar/AlertsNavBar";
import styles from "./AlertsMobileNav.module.scss";

const AlertsMobileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSelect = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const currentOption = useMemo(() => {
    if (location.pathname === "/alerts") {
      return ALERT_ROUTES[0].path;
    }

    return (
      ALERT_ROUTES.find(({ path }) => location.pathname.startsWith(path))
        ?.path || ""
    );
  }, [location.pathname]);

  return (
    <div className={styles.container}>
      <Select value={currentOption} onChange={onSelect}>
        {ALERT_ROUTES.map(({ label, path }) => (
          <Select.Item key={path} value={path}>
            {label}
          </Select.Item>
        ))}
      </Select>
    </div>
  );
};

export default AlertsMobileNav;
