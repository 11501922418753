/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

import User from "../../../../User";

import type {
  WireDualApproval,
  DualApprovalSlice,
  StatusType,
  AnyDualApproval,
} from "./types";
import type {
  DualApprovalPayload,
  DualApprovalsPayload,
} from "../../../../types/models/v1";

const initialState: DualApprovalSlice = {
  byId: {},
};

export const isWireDualApproval = (
  da: API.AnyDualApproval,
): da is API.WireDualApproval => {
  return "wire" in da && !!da.wire;
};

export const isACHDualApproval = (
  da: API.AnyDualApproval,
): da is API.ACHDualApproval => {
  return "ach_payment" in da && !!da.ach_payment;
};

export const isScheduledACHDualApproval = (
  da: API.AnyDualApproval,
): da is API.ScheduledACHDualApproval => {
  return "scheduled_ach_payment" in da && !!da.scheduled_ach_payment;
};

export const isStoreWireDualApproval = (
  da: AnyDualApproval,
): da is WireDualApproval => {
  return "wire" in da && !!da.wire;
};

const isSingular = <
  T extends { approval_request: API.AnyDualApproval },
  TS extends { approval_requests: API.AnyDualApproval[] },
>(
  payload: T | TS,
): payload is T => "approval_request" in payload;

const prepare = (approvalData: DualApprovalPayload | DualApprovalsPayload) => {
  if (isSingular(approvalData)) {
    return {
      payload: {
        dualApproval: approvalData.approval_request,
      },
    };
  }
  return {
    payload: {
      dualApprovals: approvalData.approval_requests,
    },
  };
};

export const STATUS_TYPE_FROM_STATE = Object.freeze({
  analyzing: "approved",
  pending: "approved",
  review: "approved",
  processing: "approved",
  processed: "approved",
  rejected: "rejected",
  canceled: "rejected",
  awaiting_approval: "pending",
  rejected_approval: "rejected",
  pending_approval_on_schedule: "pending",
  schedule_approved: "approved",
  schedule_awaiting_approval: "pending",
  schedule_rejected: "rejected",
});

const getStatus = (da: API.AnyDualApproval): StatusType => {
  const relatedObject =
    (da as API.WireDualApproval).wire ||
    (da as API.ACHDualApproval).ach_payment ||
    (da as API.ScheduledACHDualApproval).scheduled_ach_payment;
  if (!relatedObject) {
    return "unknown";
  }
  const state =
    relatedObject.state ||
    (relatedObject as unknown as API.ScheduledACHDualApproval)
      .dual_approval_state;
  if (STATUS_TYPE_FROM_STATE[state]) {
    return STATUS_TYPE_FROM_STATE[state];
  }
  // for historical logic reasons, if we do not have a state we are approved
  return "approved";
};

const receiveDualApproval = (
  state: DualApprovalSlice,
  da: API.AnyDualApproval,
) => {
  const { uuid, created_at, requester, responder } = da;
  const status: StatusType = getStatus(da);

  // eslint-disable-next-line no-param-reassign
  state.byId[da.uuid] = {
    id: uuid,
    created_at,
    ...(isWireDualApproval(da) && { wire: da.wire.id }),
    ...(isACHDualApproval(da) && { ach: da.ach_payment.id }),
    ...(isScheduledACHDualApproval(da) && {
      scheduledACH: da.scheduled_ach_payment.uuid,
    }),
    requester: {
      id: requester.id,
      name: User.deserialize(requester).getDescription(),
    },
    responder: responder
      ? {
          id: responder.id,
          name: User.deserialize(responder).getDescription(),
        }
      : null,
    status,
  } as AnyDualApproval;
};

type ReceiveAction = ReturnType<typeof prepare>;

const dualApprovalSlice = createSlice({
  name: "dualApprovals",
  initialState,
  reducers: {
    receive: {
      prepare,
      reducer: (state, action: ReceiveAction) => {
        if ("dualApprovals" in action.payload) {
          const { dualApprovals } = action.payload;
          dualApprovals?.forEach((da) => {
            receiveDualApproval(state, da);
          });
        } else {
          const { dualApproval } = action.payload;
          receiveDualApproval(state, dualApproval);
        }
      },
    },
  },
});

export type ExtraAction = ReturnType<typeof dualApprovalSlice.actions.receive>;

export const { actions } = dualApprovalSlice;
export default {
  dualApprovals: dualApprovalSlice.reducer,
};
