import { useRef } from "react";

import { Button } from "@narmi/design_system";
import { useLoadingContext, useNotificationContext } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import utils from "../../utils";

interface BillPaySsoProps {
  label: string;
  size?: string;
}

const BillPaySso = ({
  label = "Manage bill pay",
  size = "m",
}: BillPaySsoProps) => {
  const { setIsLoading } = useLoadingContext();
  const { sendNotification } = useNotificationContext();
  const formRef = useRef<HTMLFormElement>(null);
  const p1InputRef = useRef<HTMLInputElement>(null);

  const handleSsoSuccess = ({
    url,
    session,
  }: {
    url: string;
    session: string;
  }) => {
    if (!formRef || !formRef.current || !p1InputRef || !p1InputRef.current) {
      return;
    }
    formRef.current.action = url;

    p1InputRef.current.value = session;
    formRef.current.submit();
  };

  const handleSsoError = (error: unknown) => {
    const defaultErrorMessage = "There was an error, please try again shortly.";
    const extractedError = utils.extractErrorMessage(
      error,
      defaultErrorMessage,
    );
    sendNotification({ type: "negative", text: extractedError });
  };

  const getSso = () => {
    setIsLoading(true);
    ApiHttp.fetch("signed_urls/bill_pay/")
      .then(handleSsoSuccess)
      .catch(handleSsoError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        as="a"
        onClick={getSso}
        kind="plain"
        label={label}
        size={size}
        endIcon="external-link"
        ariaLabel={`${label} - opens in new window`}
      />
      <form
        ref={formRef}
        id="ipay-sso-form"
        hidden
        method="POST"
        target="_blank"
      >
        <input ref={p1InputRef} id="ipay-p1-input" type="hidden" name="p1" />
        <input type="hidden" name="op" value="Login" />
        <input type="hidden" name="method" value="Session" />
      </form>
    </>
  );
};

export default BillPaySso;
