import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useLibrary } from "../../../providers";
import { useObjectMemo } from "../../../hooks";
import { useRequestWithFeedback } from "../../../composites";

import UserAlertsAPI from "./api";
import { actions as userAlertsActions } from "./slice";
import { selectAllAlerts } from "./selectors";

import type { OverrideRunProps } from "../../../composites";
import type {
  CreateAlertPayload,
  UpdateAlertPayload,
  UserAlertsGetRequestParams,
  UserAlertsCreateRequestParams,
  UserAlertsDeleteRequestParams,
  UserAlertsGetAllRequestParams,
  UserAlertsUpdateRequestParams,
} from "./types";

type UserAlertsToastFluentId = `user-alerts-toast-${string}`;
const DELETE_FLUENT_ID: UserAlertsToastFluentId =
  "user-alerts-toast-error-delete" as const;

const CREATE_FLUENT_ID: UserAlertsToastFluentId =
  "user-alerts-toast-error-create" as const;

const DEFAULT_USER_ALERT_MESSAGES: Record<UserAlertsToastFluentId, string> = {
  [CREATE_FLUENT_ID]: "Could not activate alert. Please try again.",
  [DELETE_FLUENT_ID]: "Could not deactivate alert. Please try again.",
} as const;

export const useUserAlerts = () => {
  return useSelector(selectAllAlerts);
};

export const useGetUserAlerts = (
  {
    alertPartition,
    onSuccess,
    onError,
    onData,
  }: UserAlertsGetAllRequestParams = { alertPartition: "transaction" },
) => {
  const dispatch = useDispatch();
  const { send: requestSend, loading } =
    useRequestWithFeedback<API.PaginatedResults<API.UserAlert>>();

  const send = useCallback(
    (
      overrideRunProps?: OverrideRunProps<API.PaginatedResults<API.UserAlert>>,
    ) => {
      requestSend({
        action: UserAlertsAPI.getAllPartitionAlerts(alertPartition),
        onError,
        onSuccess,
        onData: ({ results: alerts }) => {
          dispatch(userAlertsActions.setMany(alerts));
          onData?.(alerts);
        },
        ...overrideRunProps,
      });
    },
    [requestSend, onError, onSuccess, dispatch, alertPartition, onData],
  );

  return useObjectMemo({ send, loading });
};

export const useGetUserAlert = ({
  alertUUID,
  onSuccess,
  onError,
  onData,
}: UserAlertsGetRequestParams) => {
  const dispatch = useDispatch();
  const { send: requestSend, loading } =
    useRequestWithFeedback<API.UserAlert>();

  const send = useCallback(
    (
      alertPartition: API.AlertPartition | null,
      overrideRunProps?: OverrideRunProps<API.UserAlert>,
    ) => {
      if (!alertPartition) return;

      requestSend({
        action: UserAlertsAPI.getAlert(alertUUID, alertPartition),
        onError,
        onSuccess,
        onData: (alert) => {
          dispatch(userAlertsActions.setOne(alert));
          onData?.(alert);
        },
        ...overrideRunProps,
      });
    },
    [requestSend, alertUUID, onError, onSuccess, dispatch, onData],
  );

  return useObjectMemo({ send, loading });
};

export const useCreateUserAlert = ({
  onSuccess,
  onError,
  onData,
}: UserAlertsCreateRequestParams = {}) => {
  const dispatch = useDispatch();
  const t = useLibrary("translations");
  const { send: requestSend, loading } =
    useRequestWithFeedback<API.UserAlert>();

  const send = useCallback(
    (
      alertPartition: API.AlertPartition | null,
      createParameters: CreateAlertPayload,
      overrideRunProps?: OverrideRunProps<API.UserAlert>,
    ) => {
      if (!alertPartition) return;

      requestSend({
        action: UserAlertsAPI.createAlert(createParameters, alertPartition),
        messaging: {
          toast: {
            error: t
              ? t.getString(
                  CREATE_FLUENT_ID,
                  {},
                  DEFAULT_USER_ALERT_MESSAGES[CREATE_FLUENT_ID],
                )
              : DEFAULT_USER_ALERT_MESSAGES[CREATE_FLUENT_ID],
          },
        },
        onError,
        onSuccess,
        onData: (alert) => {
          dispatch(userAlertsActions.setOne(alert));
          onData?.(alert);
        },
        ...overrideRunProps,
      });
    },
    [requestSend, t, onError, onSuccess, dispatch, onData],
  );

  return useObjectMemo({ send, loading });
};

export const useUpdateUserAlert = ({
  alertUUID,
  onSuccess,
  onError,
  onData,
}: UserAlertsUpdateRequestParams) => {
  const dispatch = useDispatch();
  const alerts = useUserAlerts();
  const { send: requestSend, loading } =
    useRequestWithFeedback<API.UserAlert>();

  const send = useCallback(
    (
      alertPartition: API.AlertPartition | null,
      updateParameters: UpdateAlertPayload,
      overrideRunProps?: OverrideRunProps<API.UserAlert>,
    ) => {
      if (!alertUUID) return;
      if (!alertPartition) return;
      if (!alerts.find((alert) => alert.id === alertUUID)) return;

      requestSend({
        action: UserAlertsAPI.updateAlert(
          alertUUID,
          updateParameters,
          alertPartition,
        ),
        onError,
        onSuccess,
        onData: (alert) => {
          dispatch(userAlertsActions.setOne(alert));
          onData?.(alert);
        },
        ...overrideRunProps,
      });
    },
    [alertUUID, alerts, requestSend, onError, onSuccess, dispatch, onData],
  );

  return useObjectMemo({ send, loading });
};

export const useDeleteUserAlert = ({
  alertUUID,
  onSuccess,
  onError,
}: UserAlertsDeleteRequestParams) => {
  const dispatch = useDispatch();
  const t = useLibrary("translations");
  const { send: requestSend, loading } = useRequestWithFeedback<null>();

  const send = useCallback(
    (
      alertPartition: API.AlertPartition | null,
      overrideRunProps?: OverrideRunProps<null>,
    ) => {
      if (!alertUUID) return;
      if (!alertPartition) return;

      requestSend({
        action: UserAlertsAPI.deleteAlert(alertUUID, alertPartition),
        messaging: {
          toast: {
            error: t
              ? t.getString(
                  DELETE_FLUENT_ID,
                  {},
                  DEFAULT_USER_ALERT_MESSAGES[DELETE_FLUENT_ID],
                )
              : DEFAULT_USER_ALERT_MESSAGES[DELETE_FLUENT_ID],
          },
        },
        onError,
        onSuccess() {
          dispatch(userAlertsActions.removeOne(alertUUID));
          onSuccess?.();
        },
        ...overrideRunProps,
      });
    },
    [requestSend, alertUUID, t, onError, dispatch, onSuccess],
  );

  return useObjectMemo({ send, loading });
};
