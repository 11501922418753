import { ReactNode } from "react";

import { RenderItemMenuProps } from "./types";

export const isNestedMenuItem = (
  item: API.AnyMenuItem,
): item is API.NestedMenu => !!(item as API.NestedMenu).menu;

export const isLinkItem = (item: API.AnyMenuItem): item is API.LinkMenuItem =>
  !!(item as API.LinkMenuItem).url;

export const isAppItem = (item: API.AnyMenuItem): item is API.AppMenuItem =>
  !!(item as API.AppMenuItem).app;

export const isNafItem = (item: API.AnyMenuItem): item is API.NAFMenuItem =>
  !!(item as API.NAFMenuItem).naf;

export const isAccountItem = (
  item: API.AnyMenuItem,
): item is API.AccountMenuItem => !!(item as API.AccountMenuItem).accounts;

export const isAoiobItem = (item: API.AnyMenuItem): item is API.AOIOBMenuItem =>
  !!(item as API.AOIOBMenuItem).aoiob;

export const getLabel = ({
  name,
  itemProps,
}: RenderItemMenuProps<API.AnyMenuItem>) => {
  let label: ReactNode = name;

  if ("superscript" in itemProps) {
    label = (
      <>
        {name}
        <sup>{itemProps.superscript}</sup>
      </>
    );
  }

  return label;
};
