/* eslint-disable camelcase */
import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressButtons } from "cerulean";
import { entities, modules, cents } from "byzantine";
import { DateTime } from "luxon";
import { Row, ResponsiveFlex } from "@narmi/design_system";
import { useLocalization } from "@fluent/react";
import filters from "byzantine/src/filters";
import Account from "byzantine/src/Account";
import { Features } from "byzantine/src/Feature";
import { v4 as uuidv4 } from "uuid";
import HeaderWithSteps from "../../HeaderWithSteps";
import AccountContext from "../../contexts/AccountContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import styles from "./ReviewScreen.module.scss";
import {
  ACH_PAYMENT_ROUTE,
  ACH_PAYMENT_DETAIL_ROUTE,
  ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE,
} from "./ACHPaymentRoutes";
import { getTotalSteps } from "./RecipientScreen";

type RowType = [string?, string?];

const ReviewScreen = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const features = useUserFeatures() as Features;
  const { accounts } = useContext(AccountContext);
  const { createACHPayment, loading } =
    modules.achPayments.useCreateACHPayment();
  const form = modules.achPayments.ACHPaymentForm.useForm();
  const selectedRecipient = entities.recipients.useSelectedRecipient();
  const achCompany = entities.achCompanies.useACHCompany();
  const { calculateTotalPayments } =
    modules.achPayments.useCalculateTotalPayments();
  const achScheduledEnabled = features.ach_payments_scheduled;

  if (!selectedRecipient) return null;

  const {
    values: {
      amount,
      fromAccount,
      secCode,
      recurringRule,
      memo,
      startDate,
      entryDesc,
      description,
    },
  } = form;
  const { name, ach_destination, nickname } = selectedRecipient;

  const {
    account_number: accountNumber,
    routing_number: routingNumber,
    institution_name: institutionName,
  } = ach_destination!;

  const senderAccountData = accounts.find(
    (account: Account) => account.id === fromAccount
  );
  const senderAccount = new Account(senderAccountData);
  const totalPayments = calculateTotalPayments();
  const totalAmount = (totalPayments * amount) as Cents;

  const handleGoBack = useCallback(() => {
    const route = achScheduledEnabled
      ? ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE
      : ACH_PAYMENT_DETAIL_ROUTE;
    navigate(`/${ACH_PAYMENT_ROUTE}/${route}`);
  }, [navigate]);

  const idempotencyKey = useMemo(() => uuidv4() as UUID, []);

  const handleClickSubmit = () => {
    const onSuccess = () => {
      navigate("/", { replace: true });
    };
    createACHPayment(idempotencyKey, { onSuccess, onError: handleGoBack });
  };

  const RowWrapper = ({
    heading,
    content,
  }: {
    heading?: string;
    content?: string;
  }) => {
    if (!content) return null;

    return (
      <Row>
        <Row.Item>
          <p className="fontColor--secondary">{heading}</p>
        </Row.Item>
        <Row.Item shrink>
          <p>{content}</p>
        </Row.Item>
      </Row>
    );
  };

  const renderRow = (row: RowType, index: number) => {
    const [heading, content] = row;

    return (
      <RowWrapper
        key={`row-${heading}__${index}`}
        heading={heading}
        content={content}
      />
    );
  };

  const recipientRows: RowType[] = [
    [l10n.getString("ach-payment-recipient-title"), name],
    [l10n.getString("ach-payment-review-bank"), institutionName],
    [l10n.getString("ach-payment-review-account"), accountNumber],
    [l10n.getString("ach-payment-review-routing"), routingNumber],
  ];

  const internalDetailsRows: RowType[] = [[l10n.getString("label-note"), memo]];

  return (
    <ResponsiveFlex gapSize="xl">
      <HeaderWithSteps
        headerText={l10n.getString("ach-payment-review-title")}
        step={getTotalSteps()}
        totalSteps={getTotalSteps()}
      />
      <div className={styles.reviewCardContainer}>
        <div className={styles.reviewCardHeader}>
          <p>
            {l10n.getString("ach-payment-review-sub-title")} {name}{" "}
            {nickname ? (
              <span className="fontColor--secondary fontSize--xs">{`(${nickname})`}</span>
            ) : null}
          </p>
          <h1>{`${filters.currency(cents(amount))}`}</h1>
        </div>

        {achScheduledEnabled &&
        recurringRule?.frequency &&
        recurringRule?.isVisible ? (
          <div className={styles.reviewCardSection}>
            <ResponsiveFlex gapSize="m">
              <h5 className="fontSize--xs fontWeight--bold">
                {" "}
                {l10n.getString("ach-payment-payment-schedule").toUpperCase()}
              </h5>
              {startDate ? (
                <RowWrapper
                  heading={l10n.getString("ach-payment-starts")}
                  content={startDate}
                />
              ) : null}
              {recurringRule?.frequency ? (
                <RowWrapper
                  heading={l10n.getString("label-frequency")}
                  content={recurringRule?.frequency}
                />
              ) : null}
              {recurringRule?.ends === "on" && recurringRule?.untilDate ? (
                <RowWrapper
                  heading={l10n.getString("travel-notice-end-date-input")}
                  content={recurringRule?.untilDate}
                />
              ) : null}
              {recurringRule?.ends === "on" ||
              recurringRule?.ends === "after" ? (
                <RowWrapper
                  heading={`${totalPayments} payments total`}
                  content={filters.currency(totalAmount)}
                />
              ) : null}
            </ResponsiveFlex>
          </div>
        ) : null}

        <div className={styles.reviewCardSection}>
          <ResponsiveFlex gapSize="m">
            <h5 className="fontSize--xs fontWeight--bold">
              {" "}
              {l10n.getString("ach-payment-payment-details").toUpperCase()}
            </h5>
            <RowWrapper
              heading={l10n.getString("ach-payment-review-from")}
              content={senderAccount.getShortDescription()}
            />
            <RowWrapper
              heading={l10n.getString("ach-payment-review-company")}
              content={`${achCompany?.company_name} / ${achCompany?.company_id}`}
            />
            {description ? (
              <RowWrapper
                heading={l10n.getString("label-memo")}
                content={description}
              />
            ) : null}
            <RowWrapper
              heading={l10n.getString("ach-payment-transaction-title")}
              content={`${secCode}`}
            />
            {achScheduledEnabled && entryDesc ? (
              <RowWrapper
                heading={l10n.getString("label-entry-description")}
                content={entryDesc}
              />
            ) : null}
            {!achScheduledEnabled ? (
              <RowWrapper
                heading={l10n.getString("ach-payment-review-date")}
                content={DateTime.now().toFormat("MM/dd/yyyy")}
              />
            ) : null}
            {achScheduledEnabled && !recurringRule?.frequency ? (
              <RowWrapper
                heading={l10n.getString("ach-payment-review-date")}
                content={startDate}
              />
            ) : null}
          </ResponsiveFlex>
        </div>

        <div className={styles.reviewCardSection}>
          <ResponsiveFlex gapSize="m">
            <h5 className="fontSize--xs fontWeight--bold">
              {" "}
              {l10n.getString("ach-payment-recipient-details").toUpperCase()}
            </h5>
            {recipientRows.map((row, index) => renderRow(row, index))}
          </ResponsiveFlex>
        </div>

        {achScheduledEnabled && memo ? (
          <div className={styles.reviewCardSection}>
            <ResponsiveFlex gapSize="m">
              <h5 className="fontSize--xs fontWeight--bold">
                {" "}
                {l10n.getString("ach-payment-internal-details").toUpperCase()}
              </h5>
              {internalDetailsRows.map((row, index) => renderRow(row, index))}
            </ResponsiveFlex>
          </div>
        ) : null}
      </div>
      <ProgressButtons
        backLabel={l10n.getString("button-back")}
        nextLabel={l10n.getString("ach-payment-review-action")}
        onBack={handleGoBack}
        onNext={handleClickSubmit}
        disabled={loading}
        disableNext={loading}
        isNextLoading={loading}
      />
    </ResponsiveFlex>
  );
};

export default ReviewScreen;
