import { Button } from "@narmi/design_system";
import { ContextForm } from "cerulean";

import styles from "./DrawerBottomBar.module.scss";

interface DrawerBottomBarProps {
  onSave: () => void;
  saveLabel?: string;
  onCancel: () => void;
  isContextForm?: boolean;
  cancelLabel?: string;
  isSaveDisabled?: boolean;
  isCancelDisabled?: boolean;
  loading?: boolean;
}

const DrawerBottomBar = ({
  onSave,
  saveLabel = "Save",
  onCancel,
  isContextForm,
  cancelLabel = "Cancel",
  isSaveDisabled = false,
  isCancelDisabled = false,
  loading = false,
}: DrawerBottomBarProps) => {
  const saveButton = (
    <Button
      kind="primary"
      type="submit"
      disabled={isSaveDisabled || loading}
      isLoading={loading}
      {...(isContextForm ? {} : { onClick: onSave })}
    >
      {saveLabel}
    </Button>
  );

  return (
    <div className={styles.drawerBottomBar}>
      <Button
        kind="negative"
        onClick={onCancel}
        disabled={isCancelDisabled || loading}
      >
        {cancelLabel}
      </Button>
      <div className={styles.saveButton}>
        {isContextForm ? (
          <ContextForm.Action onSubmit={onSave} dangerouslyDisableShowLoading>
            {saveButton}
          </ContextForm.Action>
        ) : (
          saveButton
        )}
      </div>
    </div>
  );
};

export default DrawerBottomBar;
