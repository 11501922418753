/* eslint-disable jsx-a11y/anchor-has-content */
import { useLocalization, Localized } from "@fluent/react";
import { Button, ContentCard, Row, RadioButtons } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import ApiHttp from "byzantine/src/ApiHttp";
import {
  ContextForm,
  Dialog,
  useLoadingContext,
  useNotificationContext,
  RoutingNumberTextInput,
  ScrollingIframe,
  useFormData,
  AccountNumberTextInput,
} from "cerulean";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import labeledCheck from "../../assets/images/labeled-check.png";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../contexts/UserFeaturesContext";

const ACCOUNT_NUMBER_MAX_LEN = 17;

const CONTENT_STATES = Object.freeze({
  SHOW_OPTIONS: {
    title: "Add a new bank account",
    titleFluentId: "add-account-bank",
    content: ({ setContentState, setSrc }) => (
      <SelectOptions setContentState={setContentState} setSrc={setSrc} />
    ),
  },
  INSTANT_FORM: {
    title: "Add a new bank account",
    titleFluentId: "add-account-bank",
    content: ({ src, handleClose, updateAccounts }) => (
      <InstantForm
        src={src}
        handleClose={handleClose}
        updateAccounts={updateAccounts}
      />
    ),
  },
  MANUAL_FORM: {
    title: "Add a new bank account",
    titleFluentId: "add-account-bank",
    content: ({
      setContentState,
      handleClose,
      setLastFour,
      updateAccounts,
    }) => (
      <ManualForm
        setContentState={setContentState}
        handleClose={handleClose}
        setLastFour={setLastFour}
        updateAccounts={updateAccounts}
      />
    ),
  },
  MANUAL_COMPLETE: {
    title: "Adding in progress!",
    titleFluentId: "add-account-pending",
    content: ({ lastFour }) => <ManualComplete lastFour={lastFour} />,
  },
});

const ExpectedDurationTag = ({ durationText }) => (
  <p
    style={{
      fontSize: "12px",
      color: "var(--theme-primary)",
      background: "RGBA(var(--theme-rgb-primary), var(--alpha-10))",
      padding: "2px 12px 2px 12px",
      borderRadius: "12px",
      alignSelf: "flex-start",
    }}
  >
    {durationText}
  </p>
);
ExpectedDurationTag.propTypes = {
  durationText: PropTypes.string,
};

const SelectOptions = ({ setContentState, setSrc }) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const onInstantSelect = () => {
    setIsLoading(true);
    ApiHttp.fetch("signed_urls/mx/urls/connect_widget")
      .then((response) => {
        setSrc(response.url);
        setContentState(CONTENT_STATES.INSTANT_FORM);
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-external-service"),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        {l10n.getString(
          "add-new-bank-account-subtitle",
          null,
          "Choose how you want to add a new bank account.",
        )}
      </div>
      <div className="margin--top--l">
        <ContentCard kind="interactive" onClick={onInstantSelect}>
          <Row>
            <Row.Item>
              <h4 className="nds-sans fontSize--l fontWeight--bold">
                {l10n.getString("title-instantly-add")}
              </h4>
            </Row.Item>
            <Row.Item shrink>
              <ExpectedDurationTag
                durationText={l10n.getString("duration-instant")}
              />
            </Row.Item>
          </Row>
          <div className="margin--top--s">
            {l10n.getString("instant-add-details")}
          </div>
        </ContentCard>
      </div>
      <div className="margin--top--l">
        <ContentCard
          kind="interactive"
          onClick={() => setContentState(CONTENT_STATES.MANUAL_FORM)}
        >
          <Row>
            <Row.Item>
              <h4 className="nds-sans fontSize--l fontWeight--bold">
                {l10n.getString("title-manually-add")}
              </h4>
            </Row.Item>
            <Row.Item shrink>
              <ExpectedDurationTag
                durationText={l10n.getString("duration-biz-days")}
              />
            </Row.Item>
          </Row>
          <div className="margin--top--s">
            {l10n.getString("manual-add-details")}
          </div>
        </ContentCard>
      </div>
    </>
  );
};
SelectOptions.propTypes = {
  setContentState: PropTypes.func,
  setSrc: PropTypes.func,
};

const InstantForm = ({ handleClose, updateAccounts, src }) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const addAccounts = () =>
    ApiHttp.fetch("accounts/verify", { method: "POST" })
      .then((response) => {
        updateAccounts(response.accounts);
        if (!response.accounts.length) {
          sendNotification({
            type: "negative",
            text: l10n.getString("error-manual-add-ineligible"),
          });
          handleClose();
          return;
        }
        sendNotification({
          type: "success",
          text: l10n.getString("success-account-added"),
        });
        handleClose();
        // TODO (#21297): remove the 2 lines below once #21297 is fixed
        setIsLoading(true);
        document.location.reload();
      })
      .catch((error) => {
        sendNotification({
          type: "negative",
          text: error || l10n.getString("error-unknown"),
        });
        handleClose();
      });

  useEffect(() => {
    const onPostMessage = (event) => {
      let postMessageData = {};
      try {
        postMessageData = JSON.parse(event.data);
      } catch (err) {
        // ignore all non-moneyDesktop events
        return;
      }
      if (!postMessageData.moneyDesktop) return;
      if (
        ["mxConnect:memberAdded", "agg_success"].indexOf(
          postMessageData.type,
        ) >= 0
      ) {
        // https://www.educative.io/answers/how-to-ensure-an-event-listener-is-only-fired-once-in-javascript
        window.removeEventListener("message", onPostMessage);
        // on a message type indicating validation or a new bank membership, addAccounts
        addAccounts();
      }
    };
    window.addEventListener("message", onPostMessage);
    return () => window.removeEventListener("message", onPostMessage);
  }, []);

  return <ScrollingIframe title="Add account form" height="550px" src={src} />;
};
InstantForm.propTypes = {
  handleClose: PropTypes.func,
  updateAccounts: PropTypes.func,
  src: PropTypes.string,
};

const ManualForm = ({
  setContentState,
  handleClose,
  setLastFour,
  updateAccounts,
}) => {
  const { l10n } = useLocalization();
  const {
    external_accounts_allows_business_purpose: allowsBusinessPurpose,
    ach_terms_url: achTermsUrl = "",
  } = useContext(InstitutionSettingsContext);

  const { formData, onChange } = useFormData({
    product_type: "checking",
    account_purpose: "personal",
  });

  const onSubmit = (callback) => {
    if (formData.account_number.length > ACCOUNT_NUMBER_MAX_LEN) {
      callback();
      return;
    }
    Account.addExternalAccount({ ...formData })
      .then((response) => {
        const fullString = response.accounts[0].number;
        setLastFour(fullString.substring(fullString.length - 4));
        updateAccounts(response.accounts);
        setContentState(CONTENT_STATES.MANUAL_COMPLETE);
        callback();
      })
      .catch(callback);
  };

  return (
    <>
      <div>{l10n.getString("manual-add-form-instructions")}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          alt={l10n.getString("alt-check-routing")}
          src={labeledCheck}
          style={{ width: "422px", marginTop: "20px", marginBottom: "20px" }}
        />
      </div>
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field required>
          <RoutingNumberTextInput
            label={l10n.getString("account-detail-routing-number")}
            field="routing_number"
            id="routing_number"
          />
        </ContextForm.Field>
        <ContextForm.Field required>
          <AccountNumberTextInput
            label={l10n.getString("account-detail-account-number")}
            field="account_number"
            id="account_number"
            maxLength={ACCOUNT_NUMBER_MAX_LEN}
          />
        </ContextForm.Field>
        <>
          <div className="radio-group-title">
            {l10n.getString("radio-title-account-type")}
          </div>
          <ContextForm.Field required>
            <RadioButtons
              field="product_type"
              name="product_type"
              options={{
                [l10n.getString("account-type-checking")]: "checking",
                [l10n.getString("account-type-savings")]: "savings",
              }}
            />
          </ContextForm.Field>
        </>
        {allowsBusinessPurpose && (
          <>
            <div className="radio-group-title">
              {l10n.getString("radio-title-account-purpose")}
            </div>
            <ContextForm.Field required>
              <RadioButtons
                field="account_purpose"
                name="account_purpose"
                options={{
                  [l10n.getString("account-purpose-personal")]: "personal",
                  [l10n.getString("account-purpose-business")]: "business",
                }}
              />
            </ContextForm.Field>
          </>
        )}
        <Localized
          id="tos-efta"
          elems={{
            termsLink: <a href={achTermsUrl} target="_blank" rel="noreferrer" />,
          }}
        >
          <div
            style={{ color: "rgba(var(--nds-medium-grey))" }}
            className="fontSize--s"
          >
            {`
          By clicking "Link bank account", you acknowledge that you
          agree to the terms of the <termsLink>electronic funds transfer agreement</termsLink> .
          `}
          </div>
        </Localized>
        <ContextForm.ActionBar
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <ContextForm.Action
            noValidation
            onSubmit={handleClose}
            dangerouslyDisableShowLoading
          >
            <Button
              kind="negative"
              label={l10n.getString("button-cancel")}
              type="button"
            />
          </ContextForm.Action>
          <ContextForm.Action onSubmit={onSubmit}>
            <Button label={l10n.getString("button-link-bank-account")} />
          </ContextForm.Action>
        </ContextForm.ActionBar>
      </ContextForm>
    </>
  );
};
ManualForm.propTypes = {
  setContentState: PropTypes.func,
  handleClose: PropTypes.func,
  setLastFour: PropTypes.func,
  updateAccounts: PropTypes.func,
};

const ManualComplete = ({ lastFour }) => {
  const { l10n } = useLocalization();
  const { short_name } = useContext(InstitutionSettingsContext);
  return (
    <div data-testid="external-account-micro-deposits-in-progress">
      {l10n.getString("micro-deposits-in-progress", {
        accountName: short_name,
        lastFour,
      })}
    </div>
  );
};

ManualComplete.propTypes = {
  lastFour: PropTypes.string,
};

const AddAccountModal = ({ open, handleClose, updateAccounts }) => {
  const { l10n } = useLocalization();
  const userFeatures = useUserFeatures();
  const [contentState, setContentState] = useState(
    userFeatures?.instant_account_verification
      ? CONTENT_STATES.SHOW_OPTIONS
      : CONTENT_STATES.MANUAL_FORM,
  );
  const [src, setSrc] = useState("");
  const [lastFour, setLastFour] = useState("");

  // ensure the modal shows the options when it's opened or closed
  useEffect(() => {
    setContentState(
      userFeatures?.instant_account_verification
        ? CONTENT_STATES.SHOW_OPTIONS
        : CONTENT_STATES.MANUAL_FORM,
    );
  }, [open]);

  const { title, titleFluentId } = contentState;
  let content;
  switch (contentState) {
    case CONTENT_STATES.SHOW_OPTIONS:
      content = contentState.content({ setContentState, setSrc });
      break;
    case CONTENT_STATES.INSTANT_FORM:
      content = contentState.content({ src, handleClose, updateAccounts });
      break;
    case CONTENT_STATES.MANUAL_FORM:
      content = contentState.content({
        setContentState,
        handleClose,
        setLastFour,
        updateAccounts,
      });
      break;
    case CONTENT_STATES.MANUAL_COMPLETE:
      content = contentState.content({ lastFour });
      break;
    default:
  }

  return (
    <Dialog
      isOpen={open}
      onUserDismiss={handleClose}
      title={l10n.getString(titleFluentId, null, title)}
    >
      {content}
    </Dialog>
  );
};
AddAccountModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  updateAccounts: PropTypes.func,
};

export default AddAccountModal;
