import { useBreakpoints } from "@narmi/design_system";
import { catcat as cc, useNotificationContext } from "cerulean";
import { useLocalization } from "@fluent/react";

import ApiHttp from "byzantine/src/ApiHttp";
import TravelNoticeCard from "./TravelNoticeCard";
import styles from "./ExistingTravelNotice.module.scss";
import BackButton from "../../../../../../src/modules/transfers/wire/templates/TemplatesList/BackButton";

interface ExistingTravelNoticeProps {
  onUserDismiss: () => void;
  goBack: () => void;
  notes: API.CardTravelNote[];
  cardId: API.CardId;
}

const ExistingTravelNotice = ({
  onUserDismiss,
  goBack,
  notes,
  cardId,
}: ExistingTravelNoticeProps) => {
  const { m } = useBreakpoints();
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();

  const deleteTravelNote = async () => {
    try {
      await ApiHttp.fetch(`cards/${cardId}/travel_note/`, {
        method: "DELETE",
      });
      sendNotification({
        type: "success",
        text: l10n.getString("travel-notice-deleted-success"),
      });
      onUserDismiss();
    } catch (error) {
      sendNotification({
        type: "negative",
        text: l10n.getString("travel-notice-deleted-failure"),
      });
    }
  };
  return (
    <div className={cc(styles.container, { "padding--top--l": m })}>
      <BackButton
        text={l10n.getString("travel-notice-back-button")}
        className={cc("margin--bottom--l", { "margin--top--xs": m })}
        onClick={goBack}
      />
      <h2 className={styles.header}>
        {l10n.getString("travel-notice-list-title")}
      </h2>
      <div className="margin--top--xs margin--bottom--xl">
        {l10n.getString("travel-notice-list-description")}
      </div>
      {notes.map(({ start_date: startDate, end_date: endDate }) => (
        <TravelNoticeCard
          key={`${startDate}-${endDate}`}
          startDate={startDate}
          endDate={endDate}
          deleteTravelNote={deleteTravelNote}
        />
      ))}
    </div>
  );
};

export default ExistingTravelNotice;
