import { Frequency, RRule } from "rrule";

import type {
  AlertConfigurationMap,
  AlertSection,
  DeliveryChannelConfiguration,
} from "./types";

export const ALERT_CONFIGURATIONS: AlertConfigurationMap = {
  credit: [
    {
      fieldName: "transactionQuery",
      fieldDefaultValue: 20000,
      fieldSectionTitle: "AMOUNT",
      fieldKey: "transaction-query",
      showSectionTitle: true,
      fieldLabel: "Alert me if a transaction is over",
    },
    {
      fieldName: "deliveryChannels",
      fieldSectionTitle: "ALERT DETAILS",
      fieldLabel: "",
      fieldKey: "delivery-channels",
      showSectionTitle: true,
      fieldDefaultValue: ["push", "sms", "email"],
    },
  ],
  debit: [
    {
      fieldName: "transactionQuery",
      fieldDefaultValue: 20000, // In cents
      fieldSectionTitle: "AMOUNT",
      fieldKey: "transaction-query",
      showSectionTitle: true,
      fieldLabel: "Alert me if a transaction is over",
    },
    {
      fieldName: "deliveryChannels",
      fieldSectionTitle: "ALERT DETAILS",
      fieldLabel: "",
      fieldKey: "delivery-channels",
      showSectionTitle: true,
      fieldDefaultValue: ["push", "sms", "email"],
    },
  ],
  low_available_balance: [
    {
      fieldName: "thresholdAmount",
      fieldDefaultValue: 50000, // In cents
      fieldSectionTitle: "AMOUNT",
      fieldKey: "threshold-amount",
      showSectionTitle: true,
      fieldLabel: "Alert me if my balance falls below",
    },
    {
      fieldName: "subscribedAccounts",
      fieldDefaultValue: [],
      showSectionTitle: true,
      fieldSectionTitle: "ALERT DETAILS",
      fieldKey: "subscribed-accounts",
      fieldLabel: "",
    },
    {
      fieldName: "deliveryChannels",
      fieldSectionTitle: "",
      fieldLabel: "",
      fieldKey: "delivery-channels",
      showSectionTitle: false,
      fieldDefaultValue: ["push", "sms", "email"],
    },
  ],
  available_balance: [
    {
      fieldName: "rrule",
      // Defaults to every Sunday
      fieldDefaultValue: new RRule({
        freq: Frequency.WEEKLY,
        byweekday: [RRule.SU],
      }).toString(),
      fieldSectionTitle: "DAYS ALERTED",
      fieldKey: "rrule",
      fieldLabel: "",
      showSectionTitle: true,
    },
    {
      fieldName: "subscribedAccounts",
      fieldDefaultValue: [],
      showSectionTitle: true,
      fieldSectionTitle: "DAYS ALERTED",
      fieldKey: "subscribed-accounts",
      fieldLabel: "",
    },
    {
      fieldName: "deliveryChannels",
      fieldSectionTitle: "",
      fieldLabel: "",
      showSectionTitle: false,
      fieldKey: "delivery-channels",
      fieldDefaultValue: ["push", "sms", "email"],
    },
  ],
};

export const WEB_DELIVERY_CHANNELS: DeliveryChannelConfiguration[] = [
  {
    type: "sms",
    name: "SMS",
    icon: "tablet",
  },
  {
    type: "email",
    name: "Email",
    icon: "at-sign",
  },
];

export const MOBILE_DELIVERY_CHANNELS: DeliveryChannelConfiguration[] = [
  {
    name: "Push",
    type: "push",
    icon: "bell",
  },
  ...WEB_DELIVERY_CHANNELS,
];

export const ALERT_SECTIONS: AlertSection[] = [
  {
    section: "BALANCE ALERTS",
    sectionKey: "balance-alerts",
    items: [
      {
        key: "low_available_balance",
        isActive: false,
        alertName: "Balance below",
        configurableFields: true,
        navigateTo: "AlertRule",
      },
      {
        key: "available_balance",
        isActive: false,
        alertName: "Available balance",
        configurableFields: true,
        featureFlag: "periodic_alerts",
        navigateTo: "AlertRule",
      },
      {
        key: "credit",
        isActive: false,
        alertName: "Incoming transaction",
        configurableFields: true,
        navigateTo: "AlertRule",
      },
    ],
  },
  {
    section: "SPENDING ALERTS",
    sectionKey: "spending-alerts",
    items: [
      {
        key: "debit",
        isActive: false,
        alertName: "Incoming transaction",
        configurableFields: true,
        navigateTo: "AlertRule",
      },
    ],
  },
];
