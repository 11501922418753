import { useCallback } from "react";

import { generateRecurrenceRuleString } from "../../../../Recurrence";
import {
  combineRunProps,
  type OverrideRunProps,
  parseErrorResponse,
  useRequestWithFeedback,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { useSelectedRecipient } from "../../entities/recipients";
import { useInstitution } from "../../entities";
import { useACHCompany } from "../../entities/achCompanies";
import { useLibrary } from "../../../providers";

import {
  formatValuesForRecurrenceString,
  getIsOneTimePaymentForToday,
} from "./utils";
import { API } from "./api";
import {
  ACHPaymentForm,
  transformToApiFields,
  transformToScheduledApiFields,
} from "./ACHPayment.form";
import { useOrganizationHasMultipleApprovalUsers } from "./hooks";

export const useCreateACHPayment = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateACHPayment.Response | API.CreateScheduledACHPayment.Response,
    API.CreateACHPayment.Error | API.CreateScheduledACHPayment.Error
  >();
  const t = useLibrary("translations");
  const { values } = ACHPaymentForm.useForm();
  const recipient = useSelectedRecipient() as API.Recipient;
  const achCompany = useACHCompany() as API.ACHCompany;
  const institution = useInstitution();
  const { throwToast } = useLibrary("toasts");
  const { organizationHasMultipleApprovalUsers, isOrgUserWithEditPerms } =
    useOrganizationHasMultipleApprovalUsers();
  const createACHPayment = useCallback(
    (
      idempotencyKey: UUID,
      overrideRunProps: OverrideRunProps<
        API.CreateACHPayment.Response | API.CreateScheduledACHPayment.Response,
        API.CreateACHPayment.Error | API.CreateScheduledACHPayment.Error
      > = {},
    ) => {
      const awaitingDualApprovalStates = [
        "schedule_awaiting_approval",
        "awaiting_approval",
      ];
      const isOneTimePaymentForToday = getIsOneTimePaymentForToday(
        values,
        institution?.ach_next_cutoff,
      );
      const userInputtedEntryDesc = values.entryDesc;

      const getRecurrenceRuleString = () => {
        const { frequency, startDate, untilDate, count } =
          formatValuesForRecurrenceString(values);
        const recurringRuleString = generateRecurrenceRuleString(
          frequency,
          startDate,
          untilDate,
          count,
        );
        return recurringRuleString;
      };

      const todayOneTimePaymentValues = {
        ...values,
        recipient: recipient.id,
        achCompany: achCompany.id,
        entryDesc:
          userInputtedEntryDesc || institution?.ach_business_description || "",
      };

      const scheduledValues = {
        ...todayOneTimePaymentValues,
        recurringRuleString: getRecurrenceRuleString(),
        transactionType: "credit",
      };

      const transformedValues = isOneTimePaymentForToday
        ? transformToApiFields(todayOneTimePaymentValues)
        : transformToScheduledApiFields(scheduledValues);

      send({
        action: API.createACHPayment(idempotencyKey, transformedValues),
        ...combineRunProps<
          | API.CreateACHPayment.Response
          | API.CreateScheduledACHPayment.Response,
          API.CreateACHPayment.Error | API.CreateScheduledACHPayment.Error
        >(
          {
            onError: async (error) => {
              const { errors } = await parseErrorResponse(error);
              const defaultError = t.getString(
                "ach-payment-error-banner",
                null,
                "We could not process your ACH payment. Please try again.",
              );
              throwToast({
                kind: "error",
                message: errors.length
                  ? `${errors[0].description}`
                  : defaultError,
              });
            },
            onSuccess: (response) => {
              // response has two different possible types
              // ACHPayment | ScheduledACHPayment
              // .state only exists on ACHPayment
              // .dual_approval_state only exists on ScheduledACHPayment
              const responseState =
                "state" in response
                  ? response.state
                  : response.dual_approval_state ?? "";

              const requiresDualApproval =
                awaitingDualApprovalStates.includes(responseState);
              const isScheduledPayment = "recurring_rule" in response;

              if (
                requiresDualApproval &&
                !organizationHasMultipleApprovalUsers &&
                isOrgUserWithEditPerms
              ) {
                const message = t.getString(
                  "ach-payment-approval-org-requires-users",
                  null,
                  "Your submission can’t be approved until another user has approval permissions. [Update user permissions](/manage_users)",
                );

                throwToast({
                  kind: "error",
                  message,
                });
              } else {
                let message = t.getString(
                  isScheduledPayment
                    ? "scheduled-ach-payment-success-banner"
                    : "ach-payment-success-banner",
                  null,
                  isScheduledPayment
                    ? "Scheduled payment sent."
                    : "Payment sent.",
                );

                if (requiresDualApproval) {
                  message = t.getString(
                    "ach-payment-approval-success-banner",
                    null,
                    "ACH payment submitted for approval.",
                  );
                }

                throwToast({
                  kind: "success",
                  message,
                });
              }
            },
          },
          overrideRunProps,
        ),
      });
    },
    [
      values,
      recipient,
      achCompany,
      institution?.ach_business_description,
      institution?.ach_next_cutoff,
      organizationHasMultipleApprovalUsers,
      isOrgUserWithEditPerms,
      send,
      t,
      throwToast,
    ],
  );

  return useObjectMemo({
    createACHPayment,
    loading,
  });
};
