export interface TransfersSliceState {
  destinationInstantEligible: boolean | undefined;
  paymentIdentification: string | undefined;
  transferState: API.InstantTransferState | undefined;
  fees: API.InstantFeesResponse | undefined;
  fednowPayment: API.FedNowPayment | undefined;
}

export const FedNowPaymentSuccessStates = [
  "awaiting_response",
  "posted",
  "accepted_without_post",
];
