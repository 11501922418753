/* eslint-disable camelcase */

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import LoanPayment from "byzantine/src/LoanPayment";
import TransferSchedule from "byzantine/src/TransferSchedule";
import { ContextForm, useFormData } from "cerulean";
import useReviewableAction from "../../../useReviewableAction";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import LoanPaydownForm from "./LoanPaydownForm";
import LoanPaydownActionReview from "./LoanPaydownActionReview";

interface LoanPaydownProps {
  limits: object;
  loanId?: API.AccountId;
  goToPrevious: (queryParams?: string) => void;
}

interface UserFeatures {
  loan_principal_payment: boolean;
}

const LoanPaydown = ({ limits, loanId, goToPrevious }: LoanPaydownProps) => {
  const { currentUser } = useCurrentUser();
  const { formData, onChange } = useFormData({
    frequency: FREQUENCIES.ONCE,
    date: DateTime.now().toFormat("MM/dd/yyyy"),
    to_account_id: loanId,
  });
  const { isInput, goToInput, goToReview } = useReviewableAction();
  const { loan_principal_payment } = useUserFeatures() as UserFeatures;
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
  const navigate = useNavigate();

  if (!loan_principal_payment || !currentUser) {
    return null;
  }

  useEffect(() => {
    if (loanId) {
      onChange({ to_account_id: loanId });
    }
  }, [loanId]);

  useEffect(() => {
    // if scheduling a transfer, cannot start today
    if (formData.frequency !== FREQUENCIES.ONCE) {
      const tomorrow = DateTime.now().plus({ days: 1 });
      if (DateTime.fromFormat(formData.date, "M/d/yyyy") < tomorrow) {
        onChange({ date: tomorrow.toFormat("MM/dd/yyyy") });
      }
    }
  }, [formData.frequency]);

  const onSubmit = useCallback(
    (callback: (arg?: unknown) => void) => {
      const start_date = DateTime.fromFormat(
        formData.date,
        "M/d/yyyy"
      ).toFormat("yyyy-MM-dd");
      const isIOT = TransferSchedule.isImmediateOneTimeTransfer(
        formData.frequency,
        start_date
      );
      const transferVerb = isIOT ? "sent" : "scheduled";
      const defaultPayload = {
        amount: formData.amount as string,
        from_account_id: formData.from_account_id as string,
        to_account_id: formData.to_account_id as string,
        idempotencyKey: idempotencyKey as string,
      };
      let schedule;
      if (isIOT) {
        schedule = new LoanPayment({
          ...defaultPayload,
          payment_type: formData.payment_type,
        });
      } else {
        schedule = new TransferSchedule({
          ...defaultPayload,
          frequency: formData.frequency,
          start_date,
        });
      }
      schedule
        .submit()
        .then(() => {
          goToPrevious(`success=Payment+${transferVerb}.`);
        })
        .catch((error: unknown) => {
          if (typeof error === "string") {
            goToPrevious(`negative=${encodeURIComponent(error)}`);
          } else {
            callback(error);
            goToInput();
          }
        })
        .finally(() => {
          setIdempotencyKey(uuidv4());
        });
    },
    [formData]
  );

  const goToTransferPage = () => {
    navigate("/");
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      {isInput ? (
        <LoanPaydownForm
          data={formData}
          onChange={onChange}
          onSubmit={goToReview}
          cancel={goToTransferPage}
          limits={limits}
        />
      ) : (
        <LoanPaydownActionReview
          data={formData}
          goBack={goToInput}
          cancel={goToTransferPage}
          onSubmit={onSubmit}
        />
      )}
    </ContextForm>
  );
};

export default LoanPaydown;
