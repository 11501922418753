import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useLocalization } from "@fluent/react";
import { rrulestr } from "rrule";
import { Button, Tag, Tooltip, LoadingSkeleton } from "@narmi/design_system";
import {
  catcat as cc,
  Drawer,
  ContentCard,
  Row,
  TruncatedAccount,
} from "cerulean";
import Filters from "byzantine/src/filters";
import DualApprovalRequest, {
  DualApprovalRequestActionProps,
} from "byzantine/src/DualApprovalRequest";
import type Account from "byzantine/src/Account";
import { generateFrequencyString } from "byzantine/src/Recurrence";
import { useACHCompany } from "byzantine/src/dbbl/businessLogic/entities/achCompanies";
import { STATUS_TYPE_FROM_STATE } from "byzantine/src/dbbl/businessLogic/entities/dualApprovals";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import AccountContext from "../contexts/AccountContext";
import DrawerLayout from "../DrawerLayout";
import styles from "./DualApproval.module.scss";
import DualApprovalRecurringDialog from "./DualApprovalRecurringDialog";

export type DualApprovalDrawerProps = {
  handleClose: () => void;
  isOpen: boolean;
  showControls: boolean;
  drawerType: "pending" | "history";
  dualApproval: DualApprovalRequest | null;
  handleApprove?: (callback?: (error?: Error) => void) => void;
  handleReject?: () => void;
  handleOnNext: () => void;
  handleOnPrev: () => void;
  isLoading?: boolean;
};

export const getTotalRecurrence = (action: DualApprovalRequestActionProps) => {
  let recurrence;
  if ("recurring_rule" in action) {
    const rrule = rrulestr(action.recurring_rule);
    // user inputted "count" (ends after X amount of payments)
    if (rrule.options.count) {
      recurrence = rrule.options.count;
      // there's an end date, so we have to calculate the count
    } else if (rrule.options.until) {
      recurrence = rrule.count();
    }
  }

  return recurrence;
};

const DualApprovalDrawer = ({
  handleClose,
  isOpen,
  showControls,
  drawerType,
  dualApproval,
  handleApprove,
  handleReject,
  handleOnNext,
  handleOnPrev,
  isLoading,
}: DualApprovalDrawerProps) => {
  const achCompany = useACHCompany();
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const [showRecurringDialog, setShowRecurringDialog] = useState(false);
  const drawerContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scrollIntoView({
        behavior: "instant",
      });
    }
  }, [isOpen, isLoading, dualApproval]);

  if (!dualApproval || !isOpen) return null;

  const { action, created_at: paymentCreatedAt, requester } = dualApproval;
  const { type, dollarAmount, state: status, recipientName } = action;
  const { first_name: firstName, last_name: lastName } = requester;
  const isPendingDrawer = drawerType === "pending";
  const isHistoryDrawer = drawerType === "history";
  const isOwnRequest = currentUser?.uuid === requester.uuid;
  const showActions =
    isPendingDrawer && handleApprove && handleReject && !isOwnRequest;
  const isWirePayment = type === "Wire";
  const isACHPayment = type === "ACH";
  const isScheduledACHPayment = isACHPayment && "recurring_rule" in action;
  const doNotShowRecurringDialog = Boolean(
    window.localStorage.getItem("hide-dual-approval-recurring-dialog")
  );

  const isOneTimeScheduledACH = () => {
    if (isACHPayment) {
      const recurrence = getTotalRecurrence(action);
      return recurrence === 1;
    }
    return false;
  };

  const toggleRecurringDialog = () => {
    setShowRecurringDialog(!showRecurringDialog);
  };

  const handleClickApprove = () => {
    const oneTimeScheduled = isOneTimeScheduledACH();
    if (
      isScheduledACHPayment &&
      !oneTimeScheduled &&
      !doNotShowRecurringDialog
    ) {
      toggleRecurringDialog();
    } else if (showActions) {
      handleApprove();
    }
  };

  const renderRow = (
    row: [string, string | undefined | ReactElement],
    index: number,
    totalRows: number
  ) => {
    const [heading, content] = row;

    return content ? (
      <div
        key={`row-${heading}__${index}`}
        className={cc({ "margin--bottom--l": index !== totalRows - 1 })}
      >
        <Row>
          <Row.Item>
            <p className="fontColor--secondary">{heading}</p>
          </Row.Item>
          <span className={styles.rowContent}>
            <Row.Item shrink>
              {typeof content === "string" ? <p>{content}</p> : content}
            </Row.Item>
          </span>
        </Row>
      </div>
    ) : null;
  };

  const ScheduledDateSection = ({ startDate }: { startDate: Date }) => (
      <>
        <h5 className={styles.drawerLabelHeading}>
          {l10n.getString("label-scheduled-date")}
        </h5>
        <span>{Filters.longMonthDayYear(startDate.toISOString())}</span>
      </>
    );

  const getTopCardPaymentScheduleDetails = () => {
    const oneTimeScheduled = isOneTimeScheduledACH();

    if ("recurring_rule" in action) {
      const rrule = rrulestr(action.recurring_rule);
      const { until, dtstart: startDate } = rrule.options;
      if (oneTimeScheduled) {
        return <ScheduledDateSection startDate={startDate} />;
      }

      const frequency = generateFrequencyString(rrule);
      const count = getTotalRecurrence(action);
      const showCount = count && count < 100;
      let endString = l10n.getString("approval-drawer-value-indefinite");

      if (until) {
        const allOccurences = rrule.all();
        endString = Filters.longMonthDayYear(
          allOccurences[allOccurences.length - 1].toISOString()
        );
      } else if (showCount) {
        endString = l10n.getString("approval-drawer-value-ends-count", {
          count,
        });
      }

      const totalAmount = showCount
        ? Filters.currency(
            (count * Filters.dollarsToPennies(dollarAmount)) as Cents
          )
        : "";

      return (
        <>
          <h5 className={styles.drawerLabelHeading}>
            {l10n.getString("approval-drawer-label-payment-schedule")}
          </h5>
          <div className="margin--bottom--m">
            {l10n.getString("approval-drawer-value-payment-schedule", {
              frequency,
              startDate: Filters.longMonthDayYear(startDate.toISOString()),
            })}
          </div>
          <h5 className={styles.drawerLabelHeading}>
            {l10n.getString("approval-drawer-label-schedule-ends")}
          </h5>
          <div className={cc({ "margin--bottom--m": totalAmount })}>
            {endString}
          </div>
          {showCount ? (
            <>
              <h5 className={styles.drawerLabelHeading}>
                {l10n.getString("approval-drawer-label-payment-count", {
                  count,
                })}
              </h5>
              <div>{totalAmount}</div>
            </>
          ) : null}
        </>
      );
    }

    return <ScheduledDateSection startDate={paymentCreatedAt} />;
  };

  const TopCard = () => {
    const typeCopy = isWirePayment ? "Wire" : "Standard ACH";

    return (
      <div className="margin--top--l">
        <ContentCard kind="bordered">
          <h5 className="fontSize--m fontWeight--normal margin--bottom--xs">{`${typeCopy} to ${recipientName}`}</h5>
          <h1 className="fontColor--primary">
            {Filters.currency(dollarAmount, { hasDecimal: true })}
          </h1>
          <hr className="margin--bottom--l margin--top--l" />
          {getTopCardPaymentScheduleDetails()}
        </ContentCard>
      </div>
    );
  };

  const RecipientDetailsCard = () => {
    let showRecentlyChangedTag;
    let rows: Array<[string, string | undefined]> = [];

    if (isWirePayment) {
      const {
        to_account_institution_name: recipientAccountInstitution,
        to_account_number: recipientAccountNumber,
        to_account_routing_number: recipientRoutingNumber,
        recipientFormattedAddress,
      } = action;

      rows = [
        [l10n.getString("ach-payment-recipient-title"), recipientName],
        [
          l10n.getString("ach-payment-review-bank"),
          recipientAccountInstitution,
        ],
        [l10n.getString("ach-payment-review-account"), recipientAccountNumber],
        [l10n.getString("ach-payment-review-routing"), recipientRoutingNumber],
        [l10n.getString("label-address"), recipientFormattedAddress],
      ];
    } else if (isACHPayment) {
      const {
        recipientAccountNumber,
        recipientRoutingNumber,
        recipientAccountInstitution,
        recipientLastUpdated,
      } = action;

      const recipientUpdatedDate = new Date(recipientLastUpdated);
      const threeDaysAgoFromPaymentSubmitted = new Date(paymentCreatedAt);
      threeDaysAgoFromPaymentSubmitted.setDate(
        threeDaysAgoFromPaymentSubmitted.getDate() - 3
      );
      showRecentlyChangedTag =
        recipientUpdatedDate > threeDaysAgoFromPaymentSubmitted;

      rows = [
        [l10n.getString("ach-payment-recipient-title"), recipientName],
        [
          l10n.getString("ach-payment-review-bank"),
          recipientAccountInstitution,
        ],
        [l10n.getString("ach-payment-review-account"), recipientAccountNumber],
        [l10n.getString("ach-payment-review-routing"), recipientRoutingNumber],
      ];
    }

    return (
      <div className="margin--top--xl">
        <Row alignItems>
          <Row.Item>
            <h5 className="fontColor--secondary fontWeight--bold margin--bottom--xs lineHeight--body-text">
              {l10n.getString("recipient-details").toUpperCase()}
            </h5>
          </Row.Item>
          {showRecentlyChangedTag ? (
            <Row.Item shrink>
              <div className="margin--bottom--xs">
                <Tooltip
                  maxWidth="210px"
                  text={l10n.getString("pending-drawer-recently-changed-copy")}
                >
                  <Button kind="plain">
                    <Tag kind="error" label="Recently changed" />
                  </Button>
                </Tooltip>
              </div>
            </Row.Item>
          ) : null}
        </Row>
        <ContentCard kind="bordered">
          {rows.map((row, index) => renderRow(row, index, rows.length))}
        </ContentCard>
      </div>
    );
  };

  const PaymentDetailsCard = () => {
    const { wire_display_fee: wireFee } = useContext(
      InstitutionSettingsContext
    );
    const { accounts } = useContext(AccountContext);
    const rows: Array<[string, string | undefined | ReactElement]> = [];

    if (isWirePayment) {
      const {
        template_name: templateName,
        wire_reason: wireReason,
        formattedMemo,
      } = action;

      rows.push(
        [l10n.getString("ach-payment-review-from"), recipientName],
        [l10n.getString("label-wire-template--template-name"), templateName],
        [
          l10n.getString("label-wire-template--wire-reason-required"),
          wireReason,
        ],
        [l10n.getString("label-wire-fee"), wireFee],
        [l10n.getString("label-memo"), formattedMemo]
      );
    } else if (isACHPayment) {
      const {
        entry_desc: entryDescription,
        institution_account: institutionAccount,
        std_ent_cls_code: transactionType,
      } = action;
      let note;
      if ("memo" in action) {
        note = action.memo;
      }
      const companyId = achCompany?.company_id;
      const companyName = achCompany?.company_name;

      const fromAccount = accounts.find(
        (account: Account) => account.id === institutionAccount
      );
      const truncatedAccountEl = (
        <TruncatedAccount
          name={fromAccount.name}
          lastFour={fromAccount.getMaskedNumber()}
        />
      );

      rows.push(
        [l10n.getString("ach-payment-review-from"), truncatedAccountEl],
        [
          l10n.getString("ach-payment-transaction-title"),
          `${transactionType} payment`,
        ],
        [l10n.getString("label-company-name"), companyName],
        [l10n.getString("label-company-id"), companyId],
        [l10n.getString("label-entry-description"), entryDescription]
      );
      if (note) {
        rows.push([l10n.getString("label-note-internal"), note]);
      }
    }

    // common rows
    rows.push(
      [
        l10n.getString("label-submitted-by"),
        isOwnRequest ? "You" : `${firstName} ${lastName}`,
      ],
      [
        l10n.getString("label-submitted-on"),
        paymentCreatedAt.toLocaleDateString("en-US"),
      ]
    );

    return (
      <div className="margin--top--xl">
        <Row>
          <Row.Item>
            <h5 className="margin--bottom--xs fontColor--secondary fontWeight--bold">
              {l10n
                .getString("ach-payment-payment-details-title")
                .toUpperCase()}
            </h5>
          </Row.Item>
        </Row>
        <ContentCard kind="bordered">
          {rows.map((row, index) => renderRow(row, index, rows.length))}
        </ContentCard>
      </div>
    );
  };

  const StatusTag = () => {
    const today = new Date();
    const TWENTY_FOUR_HOURS_IN_MILLISECONDS = 86400000;
    const overDue =
      today.setHours(0, 0, 0, 0) >
      new Date(paymentCreatedAt).setHours(0, 0, 0, 0);
    const dueSoon =
      isScheduledACHPayment &&
      new Date(rrulestr(action.recurring_rule).options.dtstart).setHours(
        0,
        0,
        0,
        0
      ) -
        today.setHours(0, 0, 0, 0) <
        TWENTY_FOUR_HOURS_IN_MILLISECONDS;

    // always show status tag on history drawer
    if (isHistoryDrawer) {
      const displayStatus =
        STATUS_TYPE_FROM_STATE[status as keyof typeof STATUS_TYPE_FROM_STATE];
      const tagKind = displayStatus === "rejected" ? "error" : "success";
      return <Tag kind={tagKind} label={Filters.humanize(displayStatus)} />;
    } else if (
      isPendingDrawer &&
      (status === "awaiting_approval" ||
        status === "schedule_awaiting_approval")
    ) {
      // only show "overdue" or "due soon" tag on pending drawer
      if (overDue) {
        return (
          <Tooltip
            maxWidth="210px"
            text={l10n.getString("pending-drawer-overdue-copy")}
          >
            <Button kind="plain">
              <Tag kind="warn" label="Overdue" />
            </Button>
          </Tooltip>
        );
      }
      if (dueSoon) {
        return (
          <Tooltip
            maxWidth="210px"
            text={l10n.getString("pending-drawer-due-soon-copy")}
          >
            <Button kind="plain">
              <Tag kind="error" label="Due soon" />
            </Button>
          </Tooltip>
        );
      }
    }
    return null;
  };

  const DeliveryCopy = () => {
    if (isPendingDrawer) {
      return (
        <div className="fontSize--xs margin--top--xs">
          {isWirePayment
            ? l10n.getString("wire-delivery-copy")
            : l10n.getString("ach-delivery-copy")}
        </div>
      );
    }
    return null;
  };

  const renderDrawerContent = () => (
    <>
      <Row alignItems>
        <Row.Item shrink>
          <h1 className="padding--top--l">
            {isPendingDrawer
              ? l10n.getString("pending-drawer-title")
              : l10n.getString("history-drawer-title")}
          </h1>
        </Row.Item>
        <Row.Item>
          <div className="margin--top--l">
            <StatusTag />
          </div>
        </Row.Item>
      </Row>
      <TopCard />
      <DeliveryCopy />
      <RecipientDetailsCard />
      <PaymentDetailsCard />
    </>
  );

  return (
    <>
      <Drawer
        depth="524px"
        isOpen={isOpen}
        showControls={showControls}
        onUserDismiss={handleClose}
        paddingSize={showActions ? "none" : "xl"}
        onNext={handleOnNext}
        onPrev={handleOnPrev}
      >
        <div ref={drawerContentRef}>
          <LoadingSkeleton content="paragraph" showTitle isLoading={isLoading}>
            {showActions ? (
              <DrawerLayout
                onSave={handleClickApprove}
                onCancel={handleReject}
                cancelLabel={"Reject"}
                saveLabel={"Approve"}
              >
                {renderDrawerContent()}
              </DrawerLayout>
            ) : (
              <>{renderDrawerContent()}</>
            )}
          </LoadingSkeleton>
        </div>
      </Drawer>
      {showActions && isScheduledACHPayment ? (
        <DualApprovalRecurringDialog
          isOpen={showRecurringDialog}
          handleClose={toggleRecurringDialog}
          handleApprove={handleApprove}
        />
      ) : null}
    </>
  );
};

export default DualApprovalDrawer;
