import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { useLocalization } from "@fluent/react";
import BillPaySchedule from "byzantine/src/BillPaySchedule";
import Payee from "byzantine/src/Payee";
import { ContextForm, useFormData, NotificationContext } from "cerulean";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import BillPayForm from "./BillPayForm";
import BillPayActionReview from "./BillPayActionReview";
import BillPaySsoRedirect from "./BillPaySsoRedirect";
import useReviewableAction from "../useReviewableAction";
import useActions from "../hooks/useActions";

const BillPay = () => {
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData();
  const { isInput, goToInput, goToReview } = useReviewableAction();
  const [payees, setPayees] = useState([]);
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
  const { sendNotification } = useContext(NotificationContext);
  const { goToPrevious } = useActions();
  const navigate = useNavigate();
  const { disable_billpay_native_flow } = useUserFeatures();
  const formDataRef = useRef(formData);

  const fetchPayees = async (payee) => {
    try {
      setPayees(await Payee.fetchPayees(payee));
    } catch {
      sendNotification({
        type: "negative",
        text: l10n.getString(
          "error-payees",
          null,
          "Error fetching your payees. Please contact support."
        ),
      });
    }
  };

  useEffect(() => {
    if (!disable_billpay_native_flow) {
      fetchPayees();
    }
  }, []);

  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  const onSubmit = useCallback(
    (callback) => {
      const payload = {
        amount: formData.amount,
        payee_id: formData.payee_id,
        payment_date: DateTime.fromFormat(
          formData.payment_date,
          "M/d/yyyy"
        ).toFormat("yyyy-MM-dd"),
        payees,
        ignore_warnings: formDataRef?.current.ignore_warnings,
        idempotencyKey,
      };
      // this is feature-flagged at the moment so from_account_id may not exist
      if (formData?.from_account_id)
        payload.from_account_id = formData.from_account_id;
      const schedule = new BillPaySchedule(payload);
      schedule
        .submit()
        .then(() => {
          goToPrevious("success=Payment+scheduled.");
        })
        .catch(callback)
        .finally(() => {
          setIdempotencyKey(uuidv4());
        });
    },
    [formData, payees, formDataRef]
  );

  const goToTransferPage = () => {
    navigate("/");
  };

  if (disable_billpay_native_flow) {
    return <BillPaySsoRedirect />;
  }

  return (
    <ContextForm data={formData} onChange={onChange}>
      {isInput ? (
        <BillPayForm
          onChange={onChange}
          onSubmit={goToReview}
          cancel={goToTransferPage}
          payees={payees}
          fetchPayees={fetchPayees}
        />
      ) : (
        <BillPayActionReview
          data={formData}
          onSubmit={onSubmit}
          goBack={goToInput}
          cancel={goToTransferPage}
          payees={payees}
        />
      )}
    </ContextForm>
  );
};

export default BillPay;
