import { useMemo } from "react";

import { useLibrary } from "../../../providers";
import { useDeepEqualMemo, useObjectMemo } from "../../../hooks";
import { useUserAlerts } from "../../entities/alerts";
import { useFeature } from "../features";

import {
  ALERT_CONFIGURATIONS,
  ALERT_SECTIONS,
  MOBILE_DELIVERY_CHANNELS,
  WEB_DELIVERY_CHANNELS,
} from "./constants";

import type {
  AlertConfigurableField,
  AlertSection,
  DeliveryChannelConfiguration,
} from "./types";

export const useAlertDeliveryChannels = (
  platform: "mobile-app" | "web",
  disabledChannels: API.DeliveryChannel[] = [],
): DeliveryChannelConfiguration[] => {
  const t = useLibrary("translations");
  const { featureEnabled } = useFeature();

  return useMemo(() => {
    const deliveryChannels: DeliveryChannelConfiguration[] =
      platform === "mobile-app"
        ? MOBILE_DELIVERY_CHANNELS
        : WEB_DELIVERY_CHANNELS;

    // Removes push notifications as an option if push
    // notifications are not enabled by the FI.
    const filteredDeliveryChannels: DeliveryChannelConfiguration[] =
      deliveryChannels.filter(({ type }) =>
        !featureEnabled("push_notifications") ? type !== "push" : true,
      );

    // Translate delivery channel name and mark appropriate channels as disabled.
    return filteredDeliveryChannels.map((channel) => ({
      type: channel.type,
      name: t
        ? t.getString(
            `alerts-delivery-channel-${channel.type}`,
            {},
            channel.name,
          )
        : channel.name,
      icon: channel.icon,
      disabled: disabledChannels.includes(channel.type),
    }));
  }, [disabledChannels, featureEnabled, platform, t]);
};

export const useAlertConfiguration = (
  key: API.AlertKey,
): AlertConfigurableField[] => {
  const t = useLibrary("translations");

  return useMemo(() => {
    return (
      ALERT_CONFIGURATIONS[key]?.map(
        ({
          fieldSectionTitle,
          fieldKey,
          fieldLabel,
          showSectionTitle,
          ...config
        }) => {
          const disableSectionTitle = showSectionTitle
            ? {}
            : { fieldSectionTitle: "" };

          return {
            ...config,
            fieldKey,
            showSectionTitle,
            fieldSectionTitle: t
              ? t.getString(
                  `enhanced-alerts-field-section-title-${fieldKey}`,
                  {},
                  fieldSectionTitle,
                )
              : fieldSectionTitle,
            fieldLabel: t
              ? t.getString(
                  `enhanced-alerts-field-label-${fieldKey}`,
                  {},
                  fieldLabel,
                )
              : fieldLabel,
            ...disableSectionTitle,
          };
        },
      ) || []
    );
  }, [key, t]);
};

type AlertStrings = {
  title: string;
  description: string;
};

type AlertDescriptions = Partial<Record<API.AlertKey, AlertStrings>>;

const DEFAULT_ALERT_DESCRIPTIONS: AlertDescriptions = {
  credit: {
    title: "Incoming transaction",
    description:
      "Notify me when I have an incoming transaction over a custom amount.",
  },
  debit: {
    title: "Outgoing transaction",
    description:
      "Notify me when I have an outgoing transaction over a custom amount.",
  },
  low_available_balance: {
    title: "Balance below",
    description: "Notify me when my balance falls below a custom amount.",
  },
  available_balance: {
    title: "Available balance",
    description:
      "Notify me of my available balance at 11am ET on the following days.",
  },
};

export const useTranslatedAlerts = (): AlertDescriptions => {
  const t = useLibrary("translations");
  const translatedDescriptions: AlertDescriptions = {};

  Object.keys(DEFAULT_ALERT_DESCRIPTIONS).forEach((innerKey) => {
    const alertKey = innerKey as API.AlertKey;
    if (!t) {
      translatedDescriptions[alertKey] = DEFAULT_ALERT_DESCRIPTIONS[alertKey];
    } else {
      translatedDescriptions[alertKey] = {
        title: t.getString(
          `enhanced-alerts-title-${alertKey}`,
          {},
          DEFAULT_ALERT_DESCRIPTIONS[alertKey]?.title,
        ),
        description: t.getString(
          `enhanced-alerts-description-${alertKey}`,
          {},
          DEFAULT_ALERT_DESCRIPTIONS[alertKey]?.description,
        ),
      };
    }
  });

  return translatedDescriptions || {};
};

export const useTranslatedAlert = (key: API.AlertKey): AlertStrings => {
  const translatedAlerts = useTranslatedAlerts();
  return useObjectMemo(translatedAlerts[key] ?? { title: "", description: "" });
};

export const useAlertSections = (): AlertSection[] => {
  const t = useLibrary("translations");
  const alerts = useUserAlerts() || [];
  const { featureEnabled } = useFeature();
  const translatedAlerts = useTranslatedAlerts();

  return useDeepEqualMemo((): AlertSection[] => {
    return ALERT_SECTIONS.map(({ section, sectionKey, items }) => ({
      section:
        t?.getString(
          `enhanced-alerts-section-title-${sectionKey}`,
          {},
          section,
        ) ?? section,
      sectionKey,
      items: items
        .filter(
          (item) =>
            item.featureFlag === undefined || featureEnabled(item.featureFlag),
        )
        .map((item) => {
          const existingAlert = alerts.find(
            (alertInfo) => alertInfo.alert_key === item.key,
          );
          return {
            ...item,
            alertName: translatedAlerts[item.key]?.title || item.alertName,
            isActive: !!existingAlert,
            alert: existingAlert,
          };
        }),
    }));
  }, [alerts, t]);
};
