import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { RRule } from "rrule";
import { Tabs } from "@narmi/design_system";
import ApiHttp from "byzantine/src/ApiHttp";
import BillPaySchedule from "byzantine/src/BillPaySchedule";
import Payee from "byzantine/src/Payee";
import { NotificationContext } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import SectionCard from "../SectionCard";
import TransferTable from "./TransferTable";

const sortTransactions = (
  scheduledTransfers,
  scheduledACHPayments,
  awaitingApprovalACHPayments,
  recipients,
  accounts,
  accountUuid
) => {
  /* separates the fetched scheduled transfers into one-time transfers and recurring transfers */
  const oneTimeTransactions = [];
  const recurringTransactions = [];

  scheduledTransfers?.forEach((t) => {
    const transfer = { ...t };
    transfer.from_account =
      accounts.find((a) => a.id === t.from_account_id) || null;
    transfer.to_account =
      accounts.find((a) => a.id === t.to_account_id) || null;

    /* only display scheduled transfers where the account is either the source or destination */
    if (
      ![transfer.from_account?.id, transfer.to_account?.id].includes(
        accountUuid
      )
    ) {
      return;
    }

    /* only display active scheduled transfers (one-time transfers are not marked as deleted when
       they are completed, and this table is for upcoming transfers)
     */
    if (transfer.state === "expired") return;

    transfer.rrule = RRule.fromString(t.recurring_rule);
    if (transfer.rrule.options.count === 1) {
      oneTimeTransactions.push(transfer);
    } else {
      recurringTransactions.push(transfer);
    }
  });

  scheduledACHPayments?.forEach((payment) => {
    const updatedPayment = { ...payment };
    updatedPayment.recipientName = recipients?.find(
      (recipient) => recipient.id === payment.recipient
    )?.name;

    /* only display scheduled ACH payments where the account is institution_account */
    if (payment.institution_account !== accountUuid) {
      return;
    }

    updatedPayment.rrule = RRule.fromString(payment.recurring_rule);
    if (updatedPayment.rrule.options.count === 1) {
      oneTimeTransactions.push(updatedPayment);
    } else {
      recurringTransactions.push(updatedPayment);
    }
  });

  awaitingApprovalACHPayments?.forEach((payment) => {
    const updatedPayment = { ...payment };
    updatedPayment.recipientName = recipients?.find(
      (recipient) => recipient.id === payment.recipient
    )?.name;

    /* only display awaiting approval ACH payments where the account is institution_account */
    if (payment.institution_account !== accountUuid) {
      return;
    }

    updatedPayment.next_transfer_at = payment.created_at;

    oneTimeTransactions.push(updatedPayment);
  });

  const sortByNextTransferAt = (values) =>
    values.sort(
      (a, b) =>
        new Date(a.next_transfer_at).getTime() -
        new Date(b.next_transfer_at).getTime()
    );

  const sortedOneTimeTransactions = sortByNextTransferAt(oneTimeTransactions);
  const sortedRecurringTransactions = sortByNextTransferAt(
    recurringTransactions
  );

  return {
    sortedOneTimeTransactions,
    sortedRecurringTransactions,
  };
};

const ScheduledTransfersCard = ({ accountUuid, limits, recipients }) => {
  /* a card that displays one-time and recurring scheduled transfers/payments in separate tabs
  where the account is either the source or the destination */
  const features = useUserFeatures();
  const { currentUser } = useCurrentUser();
  const achScheduledEnabled = features.ach_payments_scheduled;
  const achPaymentsEnabled = features.ach_payments;
  const shouldFetchScheduledACHPayments =
    achScheduledEnabled && currentUser?.isBusiness();

  const [oneTimeTransactions, setOneTimeTransactions] = useState([]);
  const [recurringTransactions, setRecurringTransactions] = useState([]);
  const [isLoadingTransfers, setIsLoadingTransfers] = useState(true);

  const [payees, setPayees] = useState([]);
  const [payments, setPayments] = useState([]);
  const oneTimePayments = payments.filter((p) => !p.is_recurring);
  const recurringPayments = payments.filter((p) => p.is_recurring);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const { accounts } = useContext(AccountContext);
  const currentAccount = accounts.find((a) => a.id === accountUuid);
  const { sendNotification } = useContext(NotificationContext);

  const fetchScheduledTransfers = async () => {
    const { scheduled_transfers: scheduledTransfers } = await ApiHttp.fetch(
      "transfers/scheduled?is_expired=false",
      {
        method: "GET",
      }
    );

    return scheduledTransfers;
  };

  const fetchScheduledACHPayments = async () => {
    const scheduledACHPayments = shouldFetchScheduledACHPayments
      ? (await ApiHttp.fetch("ach_payments/scheduled", { method: "GET" }))
          .results
      : [];
    const visibleScheduledACHPayments = scheduledACHPayments.filter(
      (payment) =>
        payment.dual_approval_state === "schedule_awaiting_approval" ||
        (payment.dual_approval_state === "schedule_approved" &&
          payment.next_transfer_at)
    );

    return visibleScheduledACHPayments;
  };

  const fetchAwaitingApprovalACHPayments = async () => {
    const achPayments = achPaymentsEnabled
      ? (
          await ApiHttp.fetch("ach_payments?state=awaiting_approval", {
            method: "GET",
          })
        ).results
      : [];

    return achPayments;
  };

  const fetchTransactions = async () => {
    try {
      setIsLoadingTransfers(true);
      /* resetting the one-time and recurring transfer states because this function
         will be called again when a transfer is deleted */
      setOneTimeTransactions([]);
      setRecurringTransactions([]);

      // fetch scheduled transfers
      const scheduledTransfers = await fetchScheduledTransfers();

      // fetch scheduled ACH payments (future one time, or recurring) that are pending dual approval
      // or have been approved and have a next transfer date
      const scheduledACHPayments = await fetchScheduledACHPayments();

      // fetch all ACH Payments that are pending dual approval
      const awaitingApprovalACHPayments =
        await fetchAwaitingApprovalACHPayments();

      const { sortedOneTimeTransactions, sortedRecurringTransactions } =
        sortTransactions(
          scheduledTransfers,
          scheduledACHPayments,
          awaitingApprovalACHPayments,
          recipients,
          accounts,
          accountUuid
        );

      setOneTimeTransactions(sortedOneTimeTransactions);
      setRecurringTransactions(sortedRecurringTransactions);
    } catch {
      sendNotification({
        type: "negative",
        text: "There was an error retrieving scheduled transfers, please try again later.",
      });
    } finally {
      setIsLoadingTransfers(false);
    }
  };

  const fetchPayees = async () => {
    try {
      setPayees(await Payee.fetchPayees());
    } catch {
      sendNotification({
        type: "negative",
        text: "Error fetching your payees. Please contact support.",
      });
    }
  };

  const fetchPayments = async () => {
    try {
      setIsLoadingPayments(true);
      const allPayments = await BillPaySchedule.fetchPayments(accountUuid);
      setPayments(BillPaySchedule.filterScheduledPayments(allPayments));
    } catch {
      sendNotification({
        type: "negative",
        text: "Error fetching your payments. Please contact support.",
      });
    } finally {
      setIsLoadingPayments(false);
    }
  };

  useEffect(() => {
    // update the `payees` field of every payment once payees and payments have been fetched
    if (payees?.length && payments?.length) {
      setPayments((prevPayments) =>
        prevPayments.map((p) => {
          p.payees = payees;
          return p;
        })
      );
    }
  }, [JSON.stringify(payees), JSON.stringify(payments)]);

  useEffect(() => {
    fetchTransactions();
    if (currentAccount.isValidBillpaySource()) {
      fetchPayees();
      fetchPayments();
    } else {
      setIsLoadingPayments(false);
    }
  }, []);

  /* remove loading state only if both transfers and payments are done loading */
  useEffect(() => {
    if (!isLoadingPayments && !isLoadingTransfers) setIsLoading(false);
    else setIsLoading(true);
  }, [isLoadingPayments, isLoadingTransfers]);

  // only display card if there are scheduled transfers for the account
  const isCardDisplayed =
    oneTimeTransactions.length > 0 ||
    recurringTransactions.length > 0 ||
    payments.length > 0;

  // only diplay tabs if both recurring and one-time transfers exist
  const areTabsDisplayed =
    (oneTimeTransactions.length > 0 || oneTimePayments.length > 0) &&
    (recurringTransactions.length > 0 || recurringPayments.length > 0);

  const untabbedTable = (
    <TransferTable
      hasTopBorder={true}
      transfers={
        recurringTransactions.length > 0
          ? recurringTransactions
          : oneTimeTransactions
      }
      payments={
        recurringPayments.length > 0 ? recurringPayments : oneTimePayments
      }
      accountUuid={accountUuid}
      isRecurring={
        recurringTransactions.length > 0 || recurringPayments.length > 0
      }
      limits={limits}
      fetchScheduledTransfers={fetchScheduledTransfers}
      fetchPayments={fetchPayments}
    />
  );

  const tabbedTable = (
    <Tabs defaultSelectedIndex={0}>
      <Tabs.List xPadding="l">
        <Tabs.Tab label="One time" tabId="oneTime" />
        <Tabs.Tab label="Recurring" tabId="recurring" />
      </Tabs.List>
      <Tabs.Panel tabId="oneTime">
        <div className="padding--x--l padding--bottom--xs">
          <TransferTable
            hasTopBorder={false}
            transfers={oneTimeTransactions}
            payments={oneTimePayments}
            accountUuid={accountUuid}
            isRecurring={false}
            limits={limits}
            fetchScheduledTransfers={fetchScheduledTransfers}
            fetchPayments={fetchPayments}
          />
        </div>
      </Tabs.Panel>
      <Tabs.Panel tabId="recurring">
        <div className="padding--x--l padding--bottom--xs">
          <TransferTable
            hasTopBorder={false}
            transfers={recurringTransactions}
            payments={recurringPayments}
            accountUuid={accountUuid}
            isRecurring={true}
            limits={limits}
            fetchScheduledTransfers={fetchScheduledTransfers}
            fetchPayments={fetchPayments}
          />
        </div>
      </Tabs.Panel>
    </Tabs>
  );

  return (
    <SectionCard
      isLoading={isLoading}
      paddingSize={areTabsDisplayed ? null : "l"}
      kind={areTabsDisplayed ? null : "transactions"}
    >
      {isCardDisplayed && (
        <>
          <div
            className={cc([
              "padding--bottom--s",
              {
                "padding--x--l padding--top--l": areTabsDisplayed,
              },
            ])}
          >
            <SectionCard.Title text="Scheduled and pending transactions" />
          </div>
          {areTabsDisplayed ? tabbedTable : untabbedTable}
        </>
      )}
    </SectionCard>
  );
};

ScheduledTransfersCard.displayName = "ScheduledTransfersCard";
ScheduledTransfersCard.propTypes = {
  accountUuid: PropTypes.string.isRequired,
  limits: PropTypes.object,
  recipients: PropTypes.array,
};

export default ScheduledTransfersCard;
