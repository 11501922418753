import type { NetworkPayload } from "../../../composites";

const isScheduledPayment = (
  values: API.CreateACHPayment.Request | API.CreateScheduledACHPayment.Request,
): values is API.CreateScheduledACHPayment.Request => {
  return "transaction_type" in values && "recurring_rule" in values;
};

export const API = {
  createACHPayment: (
    idempotencyKey: UUID,
    values:
      | API.CreateACHPayment.Request
      | API.CreateScheduledACHPayment.Request,
  ): NetworkPayload => {
    const {
      amount,
      institution_account,
      ach_company,
      std_ent_cls_code,
      entry_desc,
      recipient,
      description,
      memo,
    } = values;

    const createPaymentPayload = {
      amount,
      institution_account,
      ach_company,
      std_ent_cls_code,
      entry_desc,
      recipient,
      description,
      memo,
    };

    if (isScheduledPayment(values)) {
      return {
        url: "ach_payments/scheduled",
        options: {
          method: "POST",
          payload: {
            ...createPaymentPayload,
            transaction_type: values.transaction_type,
            recurring_rule: values.recurring_rule,
          },
          idempotencyKey,
        },
      };
    }

    return {
      url: "ach_payments/",
      options: {
        method: "POST",
        payload: createPaymentPayload,
        idempotencyKey,
      },
    };
  },
};
