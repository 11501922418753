import { Button } from "@narmi/design_system";
import {
  ContextForm,
  Row,
  TextInput,
  useFormData,
  useLoadingContext,
  useNotificationContext,
  validateEmail,
} from "cerulean";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useInstitutionSettings } from "../../../../contexts/InstitutionSettingsContext";
import { useSudoContext } from "../../../../SudoContext";

interface EmailEditDialogBodyProps {
  closeDialog: () => void;
}

const EmailEditDialogBody = ({ closeDialog }: EmailEditDialogBodyProps) => {
  const { currentUser } = useCurrentUser();
  const { sendNotificationToParent } = useNotificationContext();
  const { formData, onChange } = useFormData({
    newEmail: "",
  });
  const { setIsLoading } = useLoadingContext();
  const { establishSudo } = useSudoContext();

  const { sudo_mode_required_for_email_change: sudoRequired } =
    useInstitutionSettings();

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    const response = await currentUser?.updateEmail(formData.newEmail);
    if (response?.message) {
      sendNotificationToParent({ type: "success", text: response?.message });
    }
    closeDialog();
    callback();
  };

  const onSubmitWrapper = async (callback: (arg?: unknown) => void) => {
    // different error handling depending on whether sudo mode is required
    if (sudoRequired) {
      setIsLoading(false);
      establishSudo("/profile", () => {
        setIsLoading(true);
        return onSubmit(callback).catch((error) => {
          let toastContent = "";
          if (error instanceof Error) {
            toastContent = error?.message;
          } else if (typeof error === "string") {
            toastContent = error;
            callback(error);
          } else {
            const e = error as { new_email: string };
            toastContent = e.new_email;
          }
          callback();
          sendNotificationToParent({ type: "negative", text: toastContent });
        });
      });
    } else {
      onSubmit(callback).catch((error) => {
        if (error instanceof Error) {
          callback(error?.message);
        } else if (typeof error === "string") {
          callback(error);
        } else {
          const e = error as { new_email: string };
          callback(e.new_email);
        }
      });
    }
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field field="currentEmail">
        <TextInput
          label="Current email address"
          value={currentUser?.email}
          aria-label="Current email address"
          disabled
        />
      </ContextForm.Field>
      <ContextForm.Field
        required
        validate={(value: string) => validateEmail(value)}
      >
        <TextInput
          field="newEmail"
          label="New email address"
          value={formData?.newEmail}
          aria-label="new email address"
        />
      </ContextForm.Field>

      <div className="margin--bottom--xl" />
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button
            type="button"
            onClick={closeDialog}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <ContextForm.Action onSubmit={onSubmitWrapper}>
            <Button kind="primary" label="Save changes" />
          </ContextForm.Action>
        </Row.Item>
      </Row>
    </ContextForm>
  );
};

export default EmailEditDialogBody;
