import { useEffect, memo } from "react";
import { LoadingSkeleton } from "@narmi/design_system";
import { modules } from "byzantine";
import {
  type LimitsForDisplayType,
} from "byzantine/src/dbbl/businessLogic/modules/achPayments";
import Container from "../../Container";
import AccountSelectScreen from "./AccountSelectScreen";

function TransfersContainer({ limits }: { limits: LimitsForDisplayType }) {
  const { loading, load } = modules.transfers.bootstrap.useBootstrap();
  useEffect(() => {
    load();
  }, [load]);

  return (
    <LoadingSkeleton isLoading={loading}>
      <Container>
        <AccountSelectScreen limits={limits} />
      </Container>
    </LoadingSkeleton>
  );
}

export default memo(TransfersContainer);
