import { useCallback } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "@narmi/design_system";

import styles from "./AlertsNavBar.module.scss";

export const ALERT_ROUTES = [
  { label: "Balance alerts", path: "/alerts/balance" },
  { label: "Spending alerts", path: "/alerts/spending" },
  { label: "Security alerts", path: "/alerts/security" },
  { label: "Custom alerts", path: "/alerts/custom" },
];

export const AlertsNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = useCallback(
    (path: string) =>
      location.pathname === path ||
      (path === "/alerts/balance" && location.pathname === "/alerts"),
    [location]
  );

  return (
    <div className={styles.nav}>
      <Sidebar>
        {ALERT_ROUTES.map(({ label, path }) => (
          <Sidebar.Item
            key={path}
            label={label}
            onClick={() => navigate(path)}
            isActive={isActive(path)}
          />
        ))}
      </Sidebar>
    </div>
  );
};

export default AlertsNavBar;
