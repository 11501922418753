import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import cc from "classcat";
import { useLocalization } from "@fluent/react";
import { Button, SeparatorList, Tag, Tooltip } from "@narmi/design_system";
import { API_VERSION } from "byzantine/src/ApiHttp";
import Transaction from "byzantine/src/Transaction";
import {
  ContextForm,
  Dialog,
  Error,
  NotificationContext,
  Row,
  TextInput,
  useFormData,
  useTimeout,
} from "cerulean";
import TransactionMap from "./TransactionMap";
import useEffectSkipFirstRender from "./hooks/useEffectSkipFirstRender";
import UserFeaturesContext from "./contexts/UserFeaturesContext";

const NOTE_DEBOUNCE_TIME = 500; // in milliseconds
const CLEAR_SUCCESSFUL_NOTE_SAVE_TIME = 1000 * 5; // in milliseconds
const TAG_DEBOUNCE_TIME = 100; // in milliseconds
const TAG_CHAR_LIMIT = 16;
const NOTE_SAVE_STATUS = Object.freeze({
  SAVED: "Saved",
  SAVING: "Saving",
});

const kebabCase = (string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

const InvertedColorPlusCircle = ({ onClick }) => (
  /* makes an icon with a grey circle and a white plus sign in it */
  <div
    onClick={onClick}
    onKeyUp={({ key }) => {
      if (key === "Enter") onClick();
    }}
    role="button"
    tabIndex="0"
  >
    <div className="inverted-color-plus-circle-container clickable">
      <div className={`narmi-icon-solid-circle`} />
      <div className="plus-sign-wrapper">
        <div className={`narmi-icon-plus`} />
      </div>
    </div>
  </div>
);
InvertedColorPlusCircle.propTypes = {
  onClick: PropTypes.func,
};

const InvertedColorMinusCircle = ({ onClick }) => (
  /* makes an icon with a grey circle and a white minus sign in it */
  <div
    onClick={onClick}
    onKeyUp={({ key }) => {
      if (key === "Enter") onClick();
    }}
    role="button"
    tabIndex="0"
  >
    <div className="inverted-color-minus-circle-container clickable">
      <div className={`narmi-icon-solid-circle`} />
      <div className="minus-sign-wrapper">
        <div className={`narmi-icon-minus`} />
      </div>
    </div>
  </div>
);
InvertedColorMinusCircle.propTypes = {
  onClick: PropTypes.func,
};

const CheckImages = ({ front, back }) => {
  if (
    [null, undefined, ""].includes(front) &&
    [null, undefined, ""].includes(back)
  ) {
    return null;
  }

  return (
    <Row>
      {front && (
        <Tooltip text="Download this image to see in high res">
          <Row.Item>
            <img src={`data:;base64,${front}`} alt="Front of check" />
          </Row.Item>
        </Tooltip>
      )}
      {back && (
        <Tooltip text="Download this image to see in high res">
          <Row.Item>
            <img src={`data:;base64,${back}`} alt="Back of check" />
          </Row.Item>
        </Tooltip>
      )}
    </Row>
  );
};
CheckImages.propTypes = {
  front: PropTypes.string,
  back: PropTypes.string,
};

const AddTagInput = ({ addTag, currentTags, isVisible }) => {
  if (!isVisible) return null;
  const { formData, onChange } = useFormData({ currentTags });

  useEffect(() => {
    onChange({ currentTags });
  }, [JSON.stringify(currentTags)]);

  const validateNewTag = (newTag, data) => {
    // validate the to-be-added tag
    if (!newTag) return "Cannot add empty tag.";
    if (data.currentTags.includes(newTag)) return "Cannot add duplicate tag.";
    if (newTag.length > TAG_CHAR_LIMIT)
      return `Length cannot exceed ${TAG_CHAR_LIMIT} chars.`;
    return null;
  };

  const onSubmit = () => {
    addTag(formData.newTag);
    onChange({ newTag: "" });
  };

  return (
    <ContextForm data={formData} onChange={onChange} nativeForm={false}>
      <Row>
        <Row.Item>
          <ContextForm.Field
            validate={validateNewTag}
            style={{
              marginBottom: "var(--space-xs)",
              marginTop: "var(--space-xs)",
            }}
          >
            <TextInput placeholder="Tag" field="newTag" />
          </ContextForm.Field>
        </Row.Item>
        <Row.Item shrink>
          <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
            <div style={{ marginTop: "var(--space-s)" }}>
              <Button kind="primary" type="button" label="Add a new tag" />
            </div>
          </ContextForm.Action>
        </Row.Item>
      </Row>
    </ContextForm>
  );
};
AddTagInput.propTypes = {
  addTag: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  currentTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TransactionTagsSection = ({ value, onChange, tagErrors }) => {
  /* displays the transaction tags for the specific transaction
     and allow users to add or delete tags */
  const [isAddTagOpen, setIsAddTagOpen] = useState(false);
  const removeTag = (tagName) =>
    onChange(
      value.filter((t) => t !== tagName),
      true,
    );
  const addTag = (tagName) => onChange([...value, tagName]);

  const tagEditIcon = isAddTagOpen ? (
    <InvertedColorMinusCircle onClick={() => setIsAddTagOpen(false)} />
  ) : (
    <InvertedColorPlusCircle onClick={() => setIsAddTagOpen(true)} />
  );

  return (
    <>
      <Row alignItems="center" gapSize="xxs">
        <Row.Item shrink>
          <div className="attribute-label">Tags</div>
        </Row.Item>
        <Row.Item shrink>{tagEditIcon}</Row.Item>
      </Row>
      <AddTagInput
        addTag={addTag}
        currentTags={value}
        isVisible={isAddTagOpen}
      />
      {value?.length > 0 && (
        <div className="tags-row">
          {value.map((tag) => (
            <Tag
              key={tag}
              kind="dismissible"
              label={tag}
              onDismiss={() => removeTag(tag)}
            />
          ))}
        </div>
      )}
      <Error error={tagErrors} />
    </>
  );
};
TransactionTagsSection.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  tagErrors: PropTypes.string,
};

const TransactionAttribute = ({ label, content }) => {
  if ([null, undefined, ""].includes(content)) return null;
  const { l10n } = useLocalization();
  const translatedLabel = l10n.getString(
    `transaction-detail-${kebabCase(label)}`,
    null,
    label,
  );
  if (translatedLabel === "") {
    return null;
  }
  return (
    <div className="transaction-row-wrapper">
      <Row alignItems="center">
        <Row.Item shrink>
          <span className="attribute-label">{translatedLabel}</span>
        </Row.Item>
        <Row.Item>
          <div className="attribute-content">{content}</div>
        </Row.Item>
      </Row>
    </div>
  );
};

TransactionAttribute.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.node,
};

const TransactionNoteLabel = ({ noteStatus }) => (
  <Row alignItems="center">
    <Row.Item>
      <div className="attribute-label margin--bottom--xs">Notes</div>
    </Row.Item>
    <Row.Item shrink>
      <div className="fontSize--s fontColor--secondary">{noteStatus}</div>
    </Row.Item>
  </Row>
);
TransactionNoteLabel.propTypes = {
  noteStatus: PropTypes.string.isRequired,
};

const TransactionNoteInput = ({ error, field, noteErrors, ...otherProps }) => (
  /* Purpose of this wrapper is so we can pass an error that is not managed by ContextForm */
  <TextInput multiline={true} error={noteErrors} {...otherProps} />
);
TransactionNoteInput.propTypes = {
  error: PropTypes.string,
  field: PropTypes.string,
  noteErrors: PropTypes.string,
};

const TransactionDetailFooter = ({
  transaction,
  closeDialog,
  ReportIssueCallback,
}) => {
  if (!transaction.posted_date) return null;

  const { sendNotificationToParent } = useContext(NotificationContext);
  const userFeatures = React.useContext(UserFeaturesContext);
  const actions = [
    <Button
      as="a"
      key="Download"
      kind="plain"
      label="Download"
      href={`${API_VERSION}transactions/${transaction.id}?file_format=pdf`}
      onClick={() => {
        closeDialog();
        sendNotificationToParent({
          type: "success",
          text: "Download started.",
        });
      }}
    />,
  ];
  if (userFeatures.show_transaction_contact_support) {
    actions.push(
      <Button
        key="Report an issue"
        as="a"
        target="_blank"
        kind="plain"
        label="Report an issue"
        onClick={() => {
          closeDialog();
          ReportIssueCallback();
        }}
      />,
    );
  }

  return <SeparatorList items={actions} />;
};
TransactionDetailFooter.propTypes = {
  transaction: PropTypes.instanceOf(Transaction).isRequired,
  closeDialog: PropTypes.func.isRequired,
  ReportIssueCallback: PropTypes.func.isRequired,
};

const updateTransactionNote = (
  transaction,
  note,
  setNoteStatus,
  setNoteErrors,
) =>
  /* returns a debounced function pointer that updates the transaction's note field */
  debounce(() => {
    setNoteErrors("");
    setNoteStatus(NOTE_SAVE_STATUS.SAVING);
    transaction
      .update({ note })
      .then(() => {
        setNoteStatus(NOTE_SAVE_STATUS.SAVED);
      })
      .catch((error) => {
        setNoteErrors(error);
        setNoteStatus("");
      });
  }, NOTE_DEBOUNCE_TIME);

const updateTransactionTags = (transaction, tags, setTagErrors) =>
  /* returns a debounced function pointer that updates the transaction's note field */
  debounce(() => {
    setTagErrors("");
    transaction.update({ tags }).catch((error) => {
      setTagErrors(error);
    });
  }, TAG_DEBOUNCE_TIME);

const TransactionDetailsDialog = ({
  isDialogOpen,
  displayedAmount,
  displayedResultingBalance,
  isSourceMultipleAccounts,
  closeDialog,
  transaction,
  transactionTitle,
  ReportIssueCallback,
}) => {
  /* dialog that displays the details of non-pending transactions */
  const {
    account,
    amount,
    check,
    category,
    creation_date,
    raw_description,
    location,
    note,
    posted_date,
    source,
    tags,
    overview: {
      principal,
      interest,
      escrow,
      fees,
      feesLate,
      feesNotLate,
      suspense,
    },
  } = transaction;
  const [checkUrl, setCheckUrl] = useState({});
  const [noteStatus, setNoteStatus] = useState("");
  const [noteErrors, setNoteErrors] = useState("");
  const [tagErrors, setTagErrors] = useState("");
  const { formData, onChange } = useFormData({ note, tags });

  // if already in the account page, render the account display name as plain text
  const accountDisplayNameNode = isSourceMultipleAccounts ? (
    <Button
      as="a"
      kind="plain"
      label={account.nickname || account.name}
      href={`/accounts/${account.id}`}
    />
  ) : (
    account.nickname || account.name
  );

  /* retrieving check image if there are images to retrieve, and if we have not already retrieved them */
  useEffect(() => {
    if (
      isDialogOpen &&
      Object.keys(checkUrl).length === 0 &&
      Object.keys(check).length > 0
    ) {
      transaction
        .getTransactionImage()
        .then((response) => {
          if (response?.check_images) setCheckUrl(response.check_images);
        })
        .catch(() => {});
    }
  }, [isDialogOpen]);

  /* auto-save changes to the transaction tags in a debounced manner */
  useEffectSkipFirstRender(() => {
    const debouncer = updateTransactionTags(
      transaction,
      formData.tags,
      setTagErrors,
    );
    debouncer();
    return debouncer.cancel;
  }, [JSON.stringify(formData?.tags)]);

  /* auto-save changes to the transaction note in a debounced manner */
  useEffectSkipFirstRender(() => {
    const debouncer = updateTransactionNote(
      transaction,
      formData.note,
      setNoteStatus,
      setNoteErrors,
    );
    debouncer();
    return debouncer.cancel;
  }, [formData?.note]);

  /* dismiss the successful "saved" message after a pre-set amount of time */
  useTimeout(
    () => {
      setNoteStatus((prev) => {
        if (prev === NOTE_SAVE_STATUS.SAVED) return "";
        return prev;
      });
    },
    CLEAR_SUCCESSFUL_NOTE_SAVE_TIME,
    [noteStatus],
  );

  /* clear the note status and errors when the dialog is closed */
  useEffect(() => {
    if (!isDialogOpen) {
      setNoteStatus("");
      setNoteErrors("");
      setTagErrors("");
    }
  }, [noteStatus, isDialogOpen]);

  return (
    isDialogOpen && (
      <Dialog
        isOpen={isDialogOpen}
        headerStyle="plain"
        onUserDismiss={closeDialog}
        title={transactionTitle}
      >
        <div className="transaction-dialog">
          <div
            className={cc([
              "amount",
              { negative: amount < 0, positive: amount >= 0 },
            ])}
          >
            {displayedAmount}
          </div>
          <CheckImages front={checkUrl?.front} back={checkUrl?.back} />
          <TransactionMap className="map" location={location} />
          <div className="transaction-attributes">
            <TransactionAttribute label="Principal" content={principal} />
            <TransactionAttribute label="Interest" content={interest} />
            <TransactionAttribute label="Escrow" content={escrow} />
            <TransactionAttribute label="Fees" content={fees} />
            <TransactionAttribute label="Late fees" content={feesLate} />
            <TransactionAttribute label="Non-late fees" content={feesNotLate} />
            <TransactionAttribute label="Suspense" content={suspense} />
            <TransactionAttribute
              label="Transaction date"
              content={creation_date}
            />
            <TransactionAttribute label="Posted date" content={posted_date} />
            <TransactionAttribute
              label="Resulting balance"
              content={displayedResultingBalance}
            />
            <TransactionAttribute
              label="Account"
              content={accountDisplayNameNode}
            />
            <TransactionAttribute label="Transaction type" content={source} />
            <TransactionAttribute label="Category" content={category} />
            <TransactionAttribute
              label="Description"
              content={raw_description}
            />
          </div>
          {posted_date && (
            <ContextForm data={formData} onChange={onChange} nativeForm={false}>
              <ContextForm.Field>
                <TransactionTagsSection field="tags" tagErrors={tagErrors} />
              </ContextForm.Field>
              <TransactionNoteLabel noteStatus={noteStatus} />
              <ContextForm.Field style={{ marginBottom: "var(--space-s)" }}>
                <TransactionNoteInput
                  field="note"
                  noteErrors={noteErrors}
                  placeholder="Add a note..."
                />
              </ContextForm.Field>
            </ContextForm>
          )}
          <TransactionDetailFooter
            transaction={transaction}
            closeDialog={closeDialog}
            ReportIssueCallback={ReportIssueCallback}
          />
        </div>
      </Dialog>
    )
  );
};
TransactionDetailsDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  displayedAmount: PropTypes.string.isRequired,
  displayedResultingBalance: PropTypes.string,
  isSourceMultipleAccounts: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  transaction: PropTypes.instanceOf(Transaction).isRequired,
  transactionTitle: PropTypes.node.isRequired,
  ReportIssueCallback: PropTypes.func.isRequired,
};

export default TransactionDetailsDialog;
