import { Button } from "@narmi/design_system";
import { Dialog, Row } from "cerulean";

interface DeleteDialogProps {
  recipientName: string;
  recipientType: string;
  onDelete: () => void;
  isOpen: boolean;
  closeDialog: () => void;
}

const DeleteDialog = ({
  recipientName,
  recipientType,
  onDelete,
  isOpen,
  closeDialog,
}: DeleteDialogProps) => (
  <Dialog
    isOpen={isOpen}
    onUserDismiss={closeDialog}
    title={`Delete ${recipientType}?`}
    width={"385px"}
    footer={
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button onClick={closeDialog} kind="negative" label="Cancel" />
        </Row.Item>
        <Row.Item shrink>
          <Button onClick={onDelete} kind="primary" label="Yes, delete" />
        </Row.Item>
      </Row>
    }
  >
    <div style={{ overflowWrap: "anywhere" }}>
      Are you sure that you want to delete {recipientName}?
    </div>
  </Dialog>
);

export default DeleteDialog;
