import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocalization, Localized } from "@fluent/react";
import { LoadingShim, RadioButtons } from "@narmi/design_system"
import Feature from "byzantine/src/Feature";
import User from "byzantine/src/User";
import {
  AccountNumberTextInput,
  ContextForm,
  Dialog,
  ProgressButtons,
  SimpleDateInput,
  TaxIDTextInput,
  useFormData,
  validateTaxID,
  EmailFormField,
} from "cerulean";
import ReCAPTCHAFormField from "../form/ReCAPTCHAFormField";

const AccountNumberField = () => {
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const { l10n } = useLocalization();
  return (
    <div className="account-number-field-wrapper">
      <ContextForm.Field required key="primary_account_number">
        <AccountNumberTextInput
          field="primary_account_number"
          label={l10n.getString("labelAccountNumber", null, "Account number")}
        />
        <button
          onClick={(event) => {
            event.preventDefault();
            setIsInfoDialogOpen(true);
          }}
          className="account-number-field-info narmi-icon-info"
          type="button"
          label="Account number info"
        />
        <Dialog
          title="Account number"
          isOpen={isInfoDialogOpen}
          onUserDismiss={() => {
            setIsInfoDialogOpen(false);
          }}
        >
          <div>
            <p>
              <Localized id="account-number-field-info-1">
                Enter only the digits preceding punctuation or letters. For
                example, if your account number is 998877=90 or 998877 S90,
                enter 998877.
              </Localized>
            </p>
            <p>
              <Localized id="account-number-field-info-2">
                Printed checks may have extra digits following the account
                number. For example, if your account number on a check is
                998877913, enter 998877.
              </Localized>
            </p>
          </div>
        </Dialog>
      </ContextForm.Field>
    </div>
  );
};

// we only need to ask for email if the core doesn't have emails
const EmailField = () => <EmailFormField required key="email" />;

const TaxIdField = () => {
  const { l10n } = useLocalization();
  return (
    <ContextForm.Field required validate={validateTaxID} key="tin">
      <TaxIDTextInput
        field="tin"
        label={l10n.getString("label-ssn", null, "Social security number")}
      />
    </ContextForm.Field>
  );
};

const DobField = () => {
  const { l10n } = useLocalization();
  return (
    <ContextForm.Field required key="dob">
      <SimpleDateInput
        field="dob"
        label={l10n.getString("label-dob", null, "Date of birth (mm/dd/yyyy)")}
      />
    </ContextForm.Field>
  );
};

const EinField = () => {
  const { l10n } = useLocalization();
  return (
    <ContextForm.Field required validate={validateTaxID} key="ein">
      <TaxIDTextInput
        ein
        field="ein"
        label={l10n.getString("label-tax-id", null, "EIN/Tax ID")}
      />
    </ContextForm.Field>
  );
};

const TellUsMoreAboutYou = ({ updateUser, encodedPayload, institution }) => {
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({});
  const [isDonePostingEncodedPayload, setIsDonePostingEncodedPayload] =
    useState(false);
  const allFields = [<TaxIdField key="tax" />, <DobField key="dob" />];

  let recaptchaPublicKey;
  if (document.getElementById("recaptcha_public_key")) {
    recaptchaPublicKey = JSON.parse(
      document.getElementById("recaptcha_public_key").textContent
    );
  }

  // if core doesn't support emails, add the email field as the first field
  const coreHasEmails =
    document.getElementById("core_has_emails") &&
    JSON.parse(document.getElementById("core_has_emails").textContent);

  if (!coreHasEmails) allFields.unshift(<EmailField key="email" />);

  // if core prompts for account numbers during enrollment, add the account number field as the first field
  if (institution.prompt_account_number)
    allFields.unshift(<AccountNumberField key="account" />);

  // if this is a business enrollment, show the ein field as the final field
  if (formData.user_category === User.BUSINESS)
    allFields.push(<EinField key="ein" />);

  const showCaptcha = institution.show_enrollment_captcha;

  const organizedInputs = () => {
    if (allFields.length >= 4) {
      const lastField = allFields.pop();
      const penultimateField = allFields.pop();
      const lastTwoFieldsOnSingleLine = (
        <div className="last-two-elements" key="last_two_elements">
          {penultimateField}
          {lastField}
        </div>
      );
      allFields.push(lastTwoFieldsOnSingleLine);
    }
    return allFields;
  };

  const nextHandler = async (callback) => {
    let enrolledUser;
    try {
      enrolledUser = await User.enroll(formData);
      callback();
      updateUser(enrolledUser);
    } catch (err) {
      if (err?.email && err?.id === "blank" && coreHasEmails) {
        callback(
          l10n.getString(
            "error-no-account",
            null,
            "We could not find an email address associated with this account, which is required for Digital Banking. Please contact Support."
          )
        );
      } else {
        callback(err);
      }
    }
  };

  useEffect(() => {
    // default to personal if FI has personal, even if FI also has business banking
    if (institution.features.personal_banking) {
      onChange({ user_category: User.PERSONAL });
    } else if (institution.features.business_banking) {
      onChange({ user_category: User.BUSINESS });
    }
    if (!encodedPayload) {
      setIsDonePostingEncodedPayload(true);
      return;
    }
    User.enroll({ payload: encodedPayload })
      .then((u) => {
        updateUser(u);
      })
      .finally(() => {
        // whether this call succeeds or fails, we should redirect to /enroll and finish loading
        window.history.replaceState({}, "", "/enroll");
        setIsDonePostingEncodedPayload(true);
      });
  }, []);

  useEffect(() => {
    if (formData.user_category === User.PERSONAL) onChange({ ein: "" });
  }, [formData.user_category]);

  if (!isDonePostingEncodedPayload)
    return (
      <LoadingShim isLoading>
        <div style={{ height: "100vh" }} />
      </LoadingShim>
    );

  return (
    <>
      <h1>
        <Localized id="heading-about-you">Tell us more about you</Localized>
      </h1>
      <div className="margin--top--xs" data-testid="enrollment-page-subtitle">
        <Localized id="subheading-account-information"></Localized>
      </div>
      <ContextForm data={formData} onChange={onChange}>
        <Feature
          features={institution.features}
          and={["personal_banking", "business_banking"]}
        >
          <div className="account-type-container question">
            <div className="margin--bottom--l fontWeight--bold">
              <Localized id="label-your-account-type">
                What type of account do you have?
              </Localized>
            </div>
            <ContextForm.Field required>
              <RadioButtons
                field="user_category"
                name="user_category"
                options={{
                  Personal: User.PERSONAL,
                  Business: User.BUSINESS,
                }}
              />
            </ContextForm.Field>
          </div>
        </Feature>
        <div className="margin--top--xl fontWeight--bold margin--bottom--l">
          <Localized id="heading-account-details">Account details</Localized>
        </div>
        <>{organizedInputs()}</>
        <ReCAPTCHAFormField
          showCaptcha={showCaptcha}
          recaptchaPublicKey={recaptchaPublicKey}
        />
        <div className="progress-buttons-container">
          <ProgressButtons
            onNext={nextHandler}
            onBack={() => window.location.assign("/login")}
            backLabel={l10n.getString("button-cancel", null, "Cancel")}
          />
        </div>
      </ContextForm>
    </>
  );
};

TellUsMoreAboutYou.propTypes = {
  updateUser: PropTypes.func,
  encodedPayload: PropTypes.string,
  institution: PropTypes.object,
};

export default TellUsMoreAboutYou;
