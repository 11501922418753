import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, SeparatorList } from "@narmi/design_system";
import {
  useLoadingContext,
  useNotificationContext,
  Row,
} from "cerulean";
import { useLocalization } from "@fluent/react";
import AutomaticSavings from "byzantine/src/AutomaticSavings";
import App from "byzantine/src/App";
import EditRuleDialog from "./EditRuleDialog";
import DeleteRuleDialog from "./DeleteRuleDialog";
import AppAuthorize from "../AppAuthorize";

const IneligibleRule = ({
  accountOpeningUrl,
  hasDigitalBankingAccountOpening,
  daoApp,
}) => {
  const { l10n } = useLocalization();
  let button;
  if (accountOpeningUrl && !daoApp) {
    button = (
      <Button
        kind="primary"
        type="button"
        testId="open-account-button-url"
        label={l10n.getString("button-open-an-account")}
        onClick={() => {
          window.location.href = accountOpeningUrl;
        }}
      />
    );
  } else if (daoApp && !hasDigitalBankingAccountOpening) {
    button = (
      <AppAuthorize app={daoApp} dataClassnames="open-an-account-button">
        <Button
          kind="primary"
          type="button"
          testId="open-account-button-dao"
          label={l10n.getString("button-open-an-account")}
        />
      </AppAuthorize>
    );
  } else {
    button = (
      <Button
        kind="primary"
        type="button"
        testId="contact-support-button"
        label={l10n.getString("button-contact-support")}
        onClick={() => {
          window.location.href = "/messages";
        }}
      />
    );
  }
  return (
    <div data-testid="ineligible-state">
      <div className="fontColor--secondary">
        {l10n.getString("auto-savings-eligibility")}
      </div>
      <div className="margin--top--l">{button}</div>
    </div>
  );
};
IneligibleRule.propTypes = {
  accountOpeningUrl: PropTypes.string.isRequired,
  hasDigitalBankingAccountOpening: PropTypes.bool.isRequired,
  daoApp: PropTypes.instanceOf(App),
};

const NoRule = ({ openEditDialog }) => {
  const { l10n } = useLocalization();
  return (
    <div data-testid="inactive-state">
      <div className="fontColor--secondary margin--bottom--l">
        {l10n.getString("set-auto-saving-rule")}
      </div>
      <Button
        label={l10n.getString("button-choose-auto-save-accounts")}
        type="button"
        kind="primary"
        testId="dashboard-start-saving-button"
        data-testid="open-edit-dialog"
        onClick={openEditDialog}
      />
    </div>
  );
};
NoRule.propTypes = {
  openEditDialog: PropTypes.func.isRequired,
};

const ActiveRule = ({ openEditDialog, openDeleteDialog }) => {
  const { l10n } = useLocalization();
  return (
  <div data-testid="active-state">
    <div className="margin--bottom--s">
      <Row justifyContent="space-between" alignItems="center" gapSize="m">
        <Row.Item>
          <div className="fontWeight--semibold fontColor--theme--primary">
            {l10n.getString("auto-save-rule-percent")}
          </div>
        </Row.Item>
        <Row.Item shrink>
          <SeparatorList
            items={[
              <Button
                key="edit"
                kind="plain"
                type="button"
                label={l10n.getString("button-edit")}
                testId="open-edit-dialog"
                onClick={openEditDialog}
              />,
              <Button
                key="delete"
                kind="plain"
                type="button"
                label={l10n.getString("button-delete")}
                testId="open-delete-dialog"
                onClick={openDeleteDialog}
              />,
            ]}
          />
        </Row.Item>
      </Row>
    </div>
    <div className="fontColor--secondary">
      {l10n.getString("auto-save-rule-description")}
    </div>
  </div>
  );
}
ActiveRule.propTypes = {
  openEditDialog: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
};

const SavingsRuleCard = ({
  isActive,
  isEligible,
  accountOpeningUrl,
  accounts,
  initialSourceUuid,
  initialDestinationUuid,
  hasDigitalBankingAccountOpening,
  appsJson,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const [sourceUuid, setSourceUuid] = useState(initialSourceUuid);
  const [destinationUuid, setDestinationUuid] = useState(
    initialDestinationUuid
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  let daoApp = JSON.parse(appsJson).find((app) => app.client_id === "DAO");
  daoApp = daoApp ? App.deserialize(daoApp) : null;

  let successToastText = "";
  let savingsRule = "";

  if (!isEligible) {
    savingsRule = (
      <IneligibleRule
        accountOpeningUrl={accountOpeningUrl}
        hasDigitalBankingAccountOpening={hasDigitalBankingAccountOpening}
        daoApp={daoApp}
      />
    );
  } else if (!isActive) {
    successToastText = l10n.getString("notification-auto-save-active");
    savingsRule = <NoRule openEditDialog={() => setIsEditDialogOpen(true)} />;
  } else {
    successToastText = l10n.getString("notification-auto-save-updated");
    savingsRule = (
      <ActiveRule
        openEditDialog={() => setIsEditDialogOpen(true)}
        openDeleteDialog={() => setIsDeleteDialogOpen(true)}
      />
    );
  }

  return (
    <>
      <div className="savings-rules-card-wrapper">
        <h4 className="nds-sans">{l10n.getString("heading-savings-rule")}</h4>
        {savingsRule}
      </div>
      <EditRuleDialog
        isEditDialogOpen={isEditDialogOpen}
        closeDialog={() => {
          setIsEditDialogOpen(false);
          setSourceUuid(initialSourceUuid);
          setDestinationUuid(initialDestinationUuid);
        }}
        accounts={accounts}
        isNewRule={!isActive}
        onSave={async () => {
          try {
            setIsEditDialogOpen(false);
            setIsLoading(true);
            await AutomaticSavings.saveRule(
              sourceUuid,
              destinationUuid,
              successToastText
            );
          } catch {
            sendNotification({
              type: "negative",
              text: l10n.getString("error-unknown-try-again"),
            });
            setIsLoading(false);
          }
        }}
        sourceUuid={sourceUuid}
        onSourceChange={setSourceUuid}
        destinationUuid={destinationUuid}
        onDestinationChange={setDestinationUuid}
      />
      <DeleteRuleDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDialog={() => setIsDeleteDialogOpen(false)}
        onDelete={async () => {
          try {
            setIsDeleteDialogOpen(false);
            setIsLoading(true);
            await AutomaticSavings.deleteRule(
              initialSourceUuid,
              initialDestinationUuid
            );
          } catch {
            sendNotification({
              type: "negative",
              text: l10n.getString("error-unknown-try-again"),
            });
            setIsLoading(false);
          }
        }}
      />
    </>
  );
};
SavingsRuleCard.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isEligible: PropTypes.bool.isRequired,
  accountOpeningUrl: PropTypes.string.isRequired,
  accounts: PropTypes.object.isRequired,
  initialSourceUuid: PropTypes.string.isRequired,
  initialDestinationUuid: PropTypes.string.isRequired,
  hasDigitalBankingAccountOpening: PropTypes.bool.isRequired,
  appsJson: PropTypes.string,
};

export default SavingsRuleCard;
