import React, { useState, useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { Select } from "@narmi/design_system";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import BillPaySchedule from "byzantine/src/BillPaySchedule";
import AddPayeeModal from "./AddPayeeModal";
import utils from "../../utils";

export default function PayeeSelector({
  field,
  payees,
  value,
  onChange,
  updatePayees,
  setMinDate,
  setDisabledDates,
  updateDatepickerValue,
  ...props
}) {
  const { l10n } = useLocalization();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState(null);
  let minDate = DateTime.now().toFormat("MM/dd/yyyy");

  useEffect(() => {
    if (value) {
      const matchingPayees = payees.filter((payee) => payee.id === value);
      if (matchingPayees.length > 0) {
        setSelectedPayee(matchingPayees[0]);
      }
    }
  }, [value, payees]);

  useEffect(() => {
    BillPaySchedule.getHolidays().then((holidays) => {
      if (holidays && holidays.map) {
        const disabled = holidays.map((holiday) =>
          DateTime.fromISO(holiday.start_time).toJSDate(),
        );
        // also disable weekends
        disabled.push((date) => [0, 6].includes(date.getDay()));
        setDisabledDates(disabled);
      }
      /* set the minDate based on the selected Payee */
      if (
        selectedPayee &&
        selectedPayee.delivery_options &&
        selectedPayee.delivery_options.length > 0
      ) {
        const minDateForPayee = DateTime.fromISO(
          selectedPayee.delivery_options[0].date,
        ).toJSDate();
        /* minDate can't be earlier than today */
        if (minDateForPayee > utils.localIsoDate()) {
          minDate = DateTime.fromJSDate(minDateForPayee).toFormat("MM/dd/yyyy");
          setMinDate(minDate);
        }
        updateDatepickerValue(minDate);
      }
    });
  }, [selectedPayee]);

  return (
    <>
      <Select
        id="payeeSelector"
        label={props.label}
        onChange={onChange}
        value={value}
      >
        {payees.map((payee) => (
          <Select.Item key={payee.id} value={payee.id} searchValue={payee.name}>
            {payee.getDisplayName()}
          </Select.Item>
        ))}
        <Select.Action onSelect={() => setModalOpen(true)}>
          <span className="fontColor--pine fontWeight--bold">
            <span className="narmi-icon-plus padding--right--xs" />
            {l10n.getString("button-link-payee", null, "Link a new payee")}
          </span>
        </Select.Action>
      </Select>
      <AddPayeeModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        updatePayees={updatePayees}
      />
    </>
  );
}
PayeeSelector.propTypes = {
  field: PropTypes.string,
  payees: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  updatePayees: PropTypes.func,
  setMinDate: PropTypes.func,
  setDisabledDates: PropTypes.func,
  updateDatepickerValue: PropTypes.func,
};
