/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

/**
 * useThrottledEffect
 *
 * A custom React hook that is used for throttling the callback execution so that it runs
 * at most once every throttleDelay milliseconds.
 *
 * This hook ensures that rapid dependency changes don't cause the callback to fire excessively,
 * and that the last change is eventually processed after updates settle.
 *
 * @param {() => void} callback - The function to execute after the delays.
 * @param {any[]} dependencies - An array of dependencies that trigger the effect.
 * @param {number} throttleDelay - The minimum time in milliseconds between callback executions.
 *
 * @example
 * useThrottledDelayedEffect(callback, [...callbackDeps], 1000);
 */
export function useThrottledEffect<T extends unknown[]>(
  callback: () => void,
  dependencies: T,
  throttleDelay: number = 5000,
) {
  const lastExecuted = useRef<number>(Date.now());
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Throttle the callback: on each dependency change (after initialDelay),
  // call {callback} if throttleDelay has passed, or reschedule it.
  useEffect(() => {
    const now = Date.now();
    const timeSinceLastExecution = now - lastExecuted.current;

    if (timeSinceLastExecution >= throttleDelay) {
      // Enough time has passed: call immediately.
      callback();
      lastExecuted.current = now;
    } else {
      // Not enough time has passed: clear any existing timer.
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // Reschedule the callback to run after the remaining time.
      timerRef.current = setTimeout(() => {
        callback();
        timerRef.current = null;
        lastExecuted.current = Date.now();
      }, throttleDelay - timeSinceLastExecution);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  // Clean up any pending timer when the component unmounts.
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        callback();
      }
    };
  }, []);
}
