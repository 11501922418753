import { useCallback, useMemo } from "react";
import { useLocalization } from "@fluent/react";
import { MultiSelect, TruncatedAccount } from "@narmi/design_system";
import Account from "byzantine/src/Account";

// Helper to split account description into a name and last-four (if present)
function getTruncatedAccountValues(account: Account, isDestination: boolean) {
  const description = account.getDescription(isDestination);
  const splittingIndex = description.indexOf("(");
  if (splittingIndex === -1) {
    return { name: description, lastFour: "" };
  }
  return {
    name: description.substring(0, splittingIndex),
    lastFour: description.substring(splittingIndex),
  };
}

export interface MultiAccountSelectProps {
  label?: string;
  accounts: Account[];
  selectedAccountIds: string[];
  onSelectionChange: (selectedIds: string[]) => void;
  isDestination?: boolean;
  error?: string;
  errorText?: string;
}

export default function MultiAccountSelect({
  label,
  accounts,
  selectedAccountIds,
  onSelectionChange,
  isDestination = false,
  error,
  errorText,
}: MultiAccountSelectProps) {
  const { l10n } = useLocalization();

  const accountsLabel =
    label || l10n.getString("label-accounts-selector", {}, "Accounts");
  // Memoize the name so it only recalculates when label changes.
  const name = useMemo(
    () =>
      `multi-account-selector-${accountsLabel
        .replace(/\s+/g, "-")
        .toLowerCase()}`,
    []
  );

  // Only depend on accounts.length (and l10n) since that's all that matters here.
  const summaryFormatter = useCallback(
    ({ selectedItems }: { selectedItems: string[] }) => {
      if (selectedItems.length === 0) {
        return accountsLabel;
      }

      if (selectedItems.length === accounts.length) {
        return l10n.getString(
          "label-accounts-selector-all-selected",
          {},
          "All accounts selected"
        );
      }
      const plural = selectedItems.length > 1 ? "s" : "";
      const totalAccountsSelected = selectedItems.length;

      return l10n.getString(
        "label-accounts-selector-total",
        {
          plural,
          totalAccountsSelected,
        },
        `${totalAccountsSelected} account${plural} selected`
      );
    },
    []
  );

  // Memoize the rendered accounts to avoid recreating them on every render.
  const renderedAccounts = useMemo(
    () =>
      accounts.map((account) => {
        const { name: accountName, lastFour } = getTruncatedAccountValues(
          account,
          isDestination
        );

        return (
          <MultiSelect.Item
            key={account.id}
            value={account.id}
            tokenLabel={`${accountName}${lastFour ? ` ${lastFour}` : ""}`}
          >
            <TruncatedAccount name={accountName} lastFour={lastFour} />
          </MultiSelect.Item>
        );
      }),
    [accounts]
  );

  return (
    <MultiSelect
      name={name}
      label={label}
      selectedItems={selectedAccountIds}
      onSelectedItemsChange={onSelectionChange}
      errorText={errorText || error}
      summaryFormatter={summaryFormatter}
      isClearable
    >
      {renderedAccounts}
    </MultiSelect>
  );
}
