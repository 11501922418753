import { memo, useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import { LoadingShim } from "@narmi/design_system";
import { ComposeProviders, modules } from "byzantine";

import TemplatesList from "./TemplatesList";
import SendViaTemplate from "./SendViaTemplate";

const Providers = [
  modules.wireTemplates.bootstrap.Provider,
  modules.wireTemplates.methodSelectorForm.Provider,
  modules.wireTemplates.sendViaTemplateForm.Provider,
];

const WireTemplatesScreen = memo(function WireTemplatesScreen() {
  const { loading, load } = modules.wireTemplates.bootstrap.useBootstrap();
  useEffect(() => {
    load();
  }, [load]);

  if (loading) {
    return (
      <LoadingShim isLoading>
        <div style={{ height: "100vh" }} />
      </LoadingShim>
    );
  }
  return (
    <Routes>
      <Route path="/templates/*" element={<TemplatesList />} />
      <Route path="/" element={<SendViaTemplate />} />
    </Routes>
  );
});

function Bootstrapped() {
  return (
    <ComposeProviders components={Providers}>
      <WireTemplatesScreen />
    </ComposeProviders>
  );
}

export default memo(Bootstrapped);
