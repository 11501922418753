import React, { useState } from "react";
import PropTypes from "prop-types";
import OrganizationUser from "byzantine/src/OrganizationUser";
import { Button } from "@narmi/design_system";
import { ContextForm, Dialog, Row } from "cerulean";

const OrganizationDeactivateUser = ({ organizationUser }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const deactivate = (callback) => {
    organizationUser
      .deactivate()
      .then(() =>
        window.location.assign("/manage_users?success=User+deactivated.")
      )
      .catch(callback);
  };

  const { first_name, last_name } = organizationUser.user;

  return (
    <div
      className="nds-typography deactivate-modal"
      data-testid={isDialogOpen ? "dialog open" : "dialog closed"}
    >
      <Button
        kind="plain"
        type="button"
        onClick={openDialog}
        label="Deactivate user"
      />
      {isDialogOpen && (
        <Dialog
          isOpen={isDialogOpen}
          onUserDismiss={closeDialog}
          title="Deactivate user"
        >
          <ContextForm nativeForm={false}>
            <div className="margin--bottom--xl">
              {`Are you sure you want to deactivate ${first_name} ${last_name}? They will no longer be able to access their account.`}
            </div>
            <Row justifyContent="end" alignItems="center" gapSize="m">
              <Row.Item shrink>
                <Button
                  kind="negative"
                  type="button"
                  label="Cancel"
                  onClick={closeDialog}
                />
              </Row.Item>
              <Row.Item shrink>
                <ContextForm.Action onSubmit={deactivate}>
                  <Button kind="primary" label="Yes" />
                </ContextForm.Action>
              </Row.Item>
            </Row>
          </ContextForm>
        </Dialog>
      )}
    </div>
  );
};

OrganizationDeactivateUser.propTypes = {
  organizationUser: PropTypes.instanceOf(OrganizationUser).isRequired,
};

export default OrganizationDeactivateUser;
