import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import ApiHttp from "byzantine/src/ApiHttp";
import { Button } from "@narmi/design_system";
import {
  ContextForm,
  Dialog,
  useFormData,
  useNotificationContext,
} from "cerulean";
import {
  presetPeriods,
  Period,
} from "byzantine/src/TransactionSearchAndFilter";
import DateRangeFormField from "../form/DateRangeFormField";
import DropdownField from "../form/DropdownField";

const FILE_FORMATS = [
  { displayName: "Spreadsheet (CSV)", value: "csv" },
  { displayName: "Microsoft money (OFX v1)", value: "ofx" },
  { displayName: "Personal finance (QIF)", value: "qif" },
  { displayName: "Quickbooks (QBO)", value: "qbo" },
  { displayName: "Quicken (QFX)", value: "qfx" },
];

interface DownloadFormProps {
  closeDialog: () => void;
  endpoint: string;
}

type Format = "csv" | "ofx" | "qif" | "qbo" | "qfx";

type DownloadFormData = {
  min_date?: string;
  max_date?: string;
  period?: Period;
  format?: Format;
};

const DownloadForm = ({ closeDialog, endpoint }: DownloadFormProps) => {
  const { formData, setFormData, onChange } = useFormData({});
  const { sendNotificationToParent } = useNotificationContext();

  useEffect(() => {
    if (formData?.period !== "custom") {
      setFormData((prevState: DownloadFormData) => {
        const newState = { ...prevState };
        delete newState.min_date;
        delete newState.max_date;
        return newState;
      });
    }
  }, [formData?.period]);

  const onSubmit = (callback: (error?: Error) => void) => {
    closeDialog();

    const formatDateForApi = (date?: string) =>
      DateTime.fromFormat(date || "", "M/d/yyyy")?.toFormat("yyyy-MM-dd");

    const payload = {
      file_format: formData.file_format,
      period: "custom",
      min_date: formatDateForApi(formData?.min_date),
      max_date: formatDateForApi(formData?.max_date),
    };
    if (formData.period !== "custom") {
      const [periodStart, periodEnd] = formData.period;
      payload.min_date = formatDateForApi(periodStart);
      payload.max_date = formatDateForApi(periodEnd);
    }

    ApiHttp.fetch(endpoint, { method: "GET" }, payload)
      .then((response) => {
        sendNotificationToParent({
          type: "success",
          text: "Download started.",
        });
        callback();

        // create temporary download link
        const tempDownloadUrl = URL.createObjectURL(new Blob([response.data]));
        const tempLink = document.createElement("a");
        tempLink.href = tempDownloadUrl;

        // e.g. `attachment; filename="Primary Checking-02_18_22.csv"`
        const fileName = decodeURI(
          response.headers["content-disposition"]
            .split(";")
            .find((n: string) => n.includes('filename="'))
            .replace("filename=", "")
            .replace(/"/g, "") // remove quotation marks
            .trim(),
        );
        tempLink.setAttribute("download", fileName);
        document.body.appendChild(tempLink);
        tempLink.click();

        // cleanup
        tempLink.parentNode?.removeChild(tempLink);
        URL.revokeObjectURL(tempDownloadUrl);
      })
      .catch((error) => {
        callback(error);
      });
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field required>
        <DropdownField
          options={FILE_FORMATS}
          field="file_format"
          label="File type"
        />
      </ContextForm.Field>
      <ContextForm.Field required>
        <DropdownField
          options={presetPeriods()}
          field="period"
          label="Date range"
        />
      </ContextForm.Field>
      <DateRangeFormField
        hidden={formData?.period !== "custom"}
        required={true}
      />
      <ContextForm.ActionBar>
        <ContextForm.Action
          noValidation
          onSubmit={closeDialog}
          dangerouslyDisableShowLoading
        >
          <div style={{ margin: "auto var(--space-m) auto auto" }}>
            <Button kind="negative" label="Cancel" />
          </div>
        </ContextForm.Action>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button kind="primary" label="Download" />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};

interface TransactionsDownloaderProps {
  accountUuid: string;
}
const TransactionsDownloader = ({
  accountUuid,
}: TransactionsDownloaderProps) => {
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  const closeDialog = () => {
    setIsDownloadDialogOpen(false);
  };
  const openDialog = () => {
    setIsDownloadDialogOpen(true);
  };
  const endpoint = `accounts/${accountUuid}/transactions_download`;

  return (
    <>
      <Button kind="plain" label="Download" onClick={openDialog} />
      <Dialog
        isOpen={isDownloadDialogOpen}
        onUserDismiss={closeDialog}
        title="Download transaction history"
      >
        <div className="margin--top--s">
          <DownloadForm closeDialog={closeDialog} endpoint={endpoint} />
        </div>
      </Dialog>
    </>
  );
};
TransactionsDownloader.propTypes = {
  accountUuid: PropTypes.string.isRequired,
};

export default TransactionsDownloader;
