/* eslint-disable camelcase */
import { useState } from "react";
import { Toggle } from "@narmi/design_system";
import { useNotificationContext, ContentCard } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization } from "@fluent/react";

import styles from "./LockCard.module.scss";

interface FreezeCardResponse {
  bin: string;
  core_user_id: string;
  id: string;
  last_four_digits: string;
  state: API.Card["state"];
}

interface LockCardProps {
  cardId: string;
  state: string;
  isLocked: boolean;
  updateCardStateById: (cardId: string, cardState: API.Card["state"]) => void;
}

const LockCard = ({ cardId, isLocked, updateCardStateById }: LockCardProps) => {
  const { sendNotification, clearNotifications } = useNotificationContext();
  const { l10n } = useLocalization();
  const [isSaving, setIsSaving] = useState(false);

  const changeCardState = async () => {
    clearNotifications();
    setIsSaving(true);

    try {
      const response = await ApiHttp.fetch<FreezeCardResponse>(
        `cards/${cardId}/${isLocked ? "unlock" : "lock"}`,
        { method: "POST" },
        {},
      );
      updateCardStateById(cardId, response.state);
    } catch (error) {
      sendNotification({
        type: "negative",
        text: l10n.getString("error-unknown"),
      });
    }
    setIsSaving(false);
  };

  return (
    <div className={styles.toggleCardContainer}>
      <ContentCard kind="elevated">
        <label id="lock-label" className={styles.toggleContainer}>
          <span>
            {l10n.getString(
              isLocked ? "card-feature-unfreeze" : "card-feature-freeze",
            )}
          </span>
          <Toggle
            labelledBy="lock-label"
            isActive={isLocked}
            isLoading={isSaving}
            onChange={() => {
              changeCardState();
            }}
          />
        </label>
        <p
          className={`fontColor--secondary fontSize--s ${styles.toggleDescription}`}
        >
          {l10n.getString(
            isLocked
              ? "card-feature-unfreeze-description"
              : "card-feature-freeze-description",
          )}
        </p>
      </ContentCard>
    </div>
  );
};

export default LockCard;
