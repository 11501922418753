import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocalization } from "@fluent/react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { LoadingShim, ResponsiveFlex, Row } from "@narmi/design_system";
import {
  TruncatedAccount,
  ProgressButtons,
} from "cerulean";
import { modules } from "byzantine";

import { DateTime } from "luxon";
import { useSudoContext } from "../../SudoContext";
import HeaderWithSteps from "../../HeaderWithSteps";
import { TOTAL_STEPS } from "./AccountSelectScreen";
import { TRANSFER_DETAILS_ROUTE, TRANSFER_RECEIPT_ROUTE, TRANSFERS_ROUTE } from "./TransferRoutes";
import styles from "./TransferReview.module.scss";


export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function DetailRow({ name, value }: { name: string, value: string | ReactNode | undefined }) {
  return <Row>
    <Row.Item>
      <p className="fontColor--secondary padding--top--s">{name}</p>
    </Row.Item>
    <Row.Item shrink><p className="padding--top--s">{value || "-"}</p></Row.Item>
  </Row>
}

export default function TransferReview() {
  const form = modules.transfers.TransferForm.useForm();
  const sourceAccount = modules.transfers.useSelectedSourceAccount();
  const destinationAccount = modules.transfers.useSelectedDestinationAccount();
  const isExternalTransfer = modules.transfers.useIsExternalTransfer();
  const receiptReady = modules.transfers.useReceiptReady();
  const fee = modules.transfers.useCalculateInstantTransferFee();
  const paymentId = modules.transfers.useSubmittedTransferId();
  const idempotencyKey = useMemo(() => uuidv4() as UUID, []);
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const { submitTransfer, loading } = modules.transfers.useSubmitTransfer(idempotencyKey);
  const { getInstantPayment } = modules.transfers.useGetDownstreamInstantPayment();
  const [polling, setPolling] = useState(false);
  const [pollCount, setPollCount] = useState(0);

  const { totalSteps: totalStepsWithSudo } = useSudoContext();
  const ONE_SECOND = 1000;
  const MAX_POLL_COUNT = 5;

  useEffect(() => {
    if (receiptReady || pollCount > MAX_POLL_COUNT) {
      setPolling(false);
      navigate(`/${TRANSFERS_ROUTE}/${TRANSFER_RECEIPT_ROUTE}`);
    }
  }, [receiptReady, pollCount])

  useEffect(() => {
    if(polling && paymentId){
      const intervalId = setInterval(() => {
        getInstantPayment();
        setPollCount(pollCount + 1);
        if(pollCount > MAX_POLL_COUNT){
          clearInterval(intervalId);
        }
      }, ONE_SECOND);
      // clear on un-mount
      return () => clearInterval(intervalId);
    }
    return () => {};
  }, [polling, paymentId, pollCount]);


  useEffect(() => {
    form.submitForm().then(({success}) => {
      if (!success) {
        navigate(`/${TRANSFERS_ROUTE}`);
      }
    })
  }, [])

  return <LoadingShim isLoading={loading || polling}>
    <ResponsiveFlex gapSize="m">
      <HeaderWithSteps
        headerText={l10n.getString("header-review-your-transfer")}
        step={isExternalTransfer ? totalStepsWithSudo : TOTAL_STEPS}
        totalSteps={isExternalTransfer ? totalStepsWithSudo : TOTAL_STEPS}
      />
      <div className="padding--all margin--bottom bgColor--white rounded--all">
        <div className={`${styles.transferSummaryContainer}`}>
          <span className={`${styles.transferSummarySpan}`}>{form.values.instant ? "Instant t" : "T"}ransfer from </span>
          <span className={`${styles.transferSummarySpan}`}>{<TruncatedAccount isInline name={sourceAccount?.name} lastFour={sourceAccount?.getMaskedNumber()} />}</span>
          <span className={`${styles.transferSummarySpan}`}> to </span>
          <span className={`${styles.transferSummarySpan}`}>{<TruncatedAccount isInline name={destinationAccount?.name} lastFour={destinationAccount?.getMaskedNumber()} />}</span>
        </div>
        <h2 className="padding--top">{moneyFormatter.format(form.values.amount / 100)}</h2>
        <hr className="margin--y" />
        <p className="fontWeight--bold">{l10n.getString("header-transfer-details-caps")}</p>
        <DetailRow name={l10n.getString("label-from")} value={<TruncatedAccount name={sourceAccount?.name} lastFour={sourceAccount?.getMaskedNumber()} />} />
        <DetailRow name={l10n.getString("label-to")} value={<TruncatedAccount name={destinationAccount?.name} lastFour={destinationAccount?.getMaskedNumber()} />} />
        <DetailRow name={l10n.getString("label-delivery-speed")} value={!isExternalTransfer || form.values.instant ? l10n.getString("label-instant") : l10n.getString("label-ach-speed-short")} />
        {form.values.instant && <DetailRow name={l10n.getString("label-fee")} value={fee === 0 ? l10n.getString("label-no-fee") : moneyFormatter.format(fee)} />}
        <DetailRow name={l10n.getString("label-memo")} value={form.values.memo} />
        {!form.values.instant && <>
          <DetailRow name={l10n.getString("label-frequency")} value={form.values.frequency} />
          <DetailRow name={l10n.getString("label-date")} value={DateTime.fromISO(form.values.date as string).toFormat("M/d/yyyy")} />
        </>}
      </div>

      <ProgressButtons
        backLabel={l10n.getString("button-back")}
        nextLabel={l10n.getString("label-send-money")}
        onBack={() => {
          navigate(`/${TRANSFERS_ROUTE}/${TRANSFER_DETAILS_ROUTE}`);
        }}
        onNext={() => {
          setPolling(true);
          submitTransfer();
        }}
      />
    </ResponsiveFlex>
  </LoadingShim>
}
