import React from "react";

import { ResponsiveFlex } from "@narmi/design_system";
import { ContentCard, Select, TextInput } from "cerulean";
import { entities, modules } from "byzantine";

import styles from "../Form.module.scss";
import * as formAdapters from "../../../../../../../adapters";
import LimitsDialog from "../../../../../../../../components/transfer/LimitsDialog";

import {
  useDetailsSelector,
  useLimitsForDisplay,
} from "./DetailsSelector.container";

function DetailsSelector() {
  const { wireReasons, method, form } = useDetailsSelector();

  const limits = entities.limits.useLimits();
  const limitsForDisplay = useLimitsForDisplay(limits);
  const {
    templateSelected,
    additionalDetailsRequired,
    requiresAmount,
    requiresMemo,
    requiresWireReason,
  } = modules.wireTemplates.useConditionalDetails();

  if (
    !method ||
    !templateSelected ||
    (!additionalDetailsRequired && Object.keys(limitsForDisplay).length === 0)
  ) {
    return null;
  }

  return (
    <ContentCard kind="bordered">
      {additionalDetailsRequired ? (
        <p className={styles.wireTemplatesFormSubHeader}>Wire details</p>
      ) : null}
      <ResponsiveFlex gapSize="m">
        {requiresAmount ? (
          <TextInput
            label="Amount"
            {...formAdapters.amountInput(form, "amount")}
          />
        ) : null}
        {requiresMemo ? (
          <TextInput label="Memo" {...formAdapters.input(form, "memo")} />
        ) : null}
        {requiresWireReason ? (
          <Select
            label="Wire reason"
            {...formAdapters.select(form, "wireReason")}
          >
            {wireReasons.map((reason) => {
              return (
                <Select.Item key={reason} value={reason}>
                  {reason}
                </Select.Item>
              );
            })}
          </Select>
        ) : null}
        <LimitsDialog isWire={true} limitsForDisplay={limitsForDisplay} />
      </ResponsiveFlex>
    </ContentCard>
  );
}

export default React.memo(DetailsSelector);
