import { Button } from "@narmi/design_system";
import { memo } from "react";
import { useInRouterContext, Link } from "react-router-dom";

const PositivePayItem = () =>
  useInRouterContext() ? (
    <Link to="/positive_pay">
      <Button label="Positive Pay" kind="menu" key="positive_pay" />
    </Link>
  ) : (
    <Button as="a" label="Positive Pay" kind="menu" href="/positive_pay" />
  );

export default memo(PositivePayItem);
