import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { LoadingSkeleton, Tooltip } from "@narmi/design_system";
import { Checkbox, Confirm } from "semantic-ui-react";
import { ContentCard, Row } from "cerulean";
import Organization from "byzantine/src/Organization";
import OrganizationUser from "byzantine/src/OrganizationUser";
import InstitutionSettingsContext, {
  InstitutionSettingsContextProvider,
} from "../contexts/InstitutionSettingsContext";

const DualApprovalOptIn = () => {
  const { l10n } = useLocalization();
  const [orgUuid, setOrgUuid] = useState(undefined);
  const [orgRole, setOrgRole] = useState(undefined);
  const [organization, setOrganization] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showWireConfirmationModal, setShowWireConfirmationModal] =
    useState(false);
  const [showACHConfirmationModal, setShowACHConfirmationModal] =
    useState(false);
  const { short_name, features } = useContext(InstitutionSettingsContext);

  // load org on mount and store it in the DOM (if not already populated)
  useEffect(() => {
    const bootstrapOrgData = async () => {
      setLoading(true);
      const currentOrgUser = await OrganizationUser.currentOrgUser();
      // check if member of an org
      if (!currentOrgUser) {
        setLoading(false);
        return;
      }

      setOrgUuid(currentOrgUser.organization_uuid);
      setOrgRole(currentOrgUser.role.name);

      // load dualApproval status
      const org = await Organization.dualApprovalRequired(
        currentOrgUser.organization_uuid
      );
      // load achDualApprovalStatus
      const orgDetails = await Organization.achDualApprovalRequired(
        currentOrgUser.organization_uuid
      );

      // load if org has multiple users
      const { organization_users: orgUsers } =
        await OrganizationUser.forOrganization(
          currentOrgUser.organization_uuid
        );

      const hasMultipleUsers = orgUsers.length > 1;

      const { ach_dual_approval_required } = orgDetails;
      const updatedOrg = {
        ...org,
        ach_dual_approval_required,
        hasMultipleUsers,
      };
      setOrganization(() => updatedOrg);
      setLoading(false);
    };
    bootstrapOrgData();
  }, []); // no dependencies, trigger only once

  /*
   *  Component Methods
   */
  const showWireModal = () => setShowWireConfirmationModal(true);
  const closeWireModal = () => setShowWireConfirmationModal(false);
  const showACHModal = () => setShowACHConfirmationModal(true);
  const closeACHModal = () => setShowACHConfirmationModal(false);

  const toggleWireDualApproval = async () => {
    const org = await Organization.dualApprovalRequired(
      orgUuid,
      "POST",
      !organization.dual_approval_required
    );
    const updatedOrg = {
      ...organization,
      ...org,
    };
    setOrganization(() => updatedOrg);
    closeWireModal();
    return updatedOrg;
  };

  const toggleACHDualApproval = async () => {
    const orgDetails = await Organization.achDualApprovalRequired(
      orgUuid,
      "PATCH",
      !organization.ach_dual_approval_required
    );
    const { ach_dual_approval_required } = orgDetails;
    const updatedOrg = {
      ...organization,
      ach_dual_approval_required,
      // if toggling ach dual approval on, set dual_approval_user_control to false so user can't toggle off
      // this mimics the behavior of wire dual approvals
      ...(ach_dual_approval_required && { dual_approval_user_control: false }),
    };
    setOrganization(() => updatedOrg);
    closeACHModal();
  };

  const handleToggleWireDualApproval = () => {
    // can't turn off unless dual_approval_user_control is enabled
    if (
      organization.dual_approval_required &&
      !organization.dual_approval_user_control
    ) {
      return false;
    }

    if (!organization.dual_approval_required) {
      return showWireModal();
    }
    return toggleWireDualApproval();
  };

  const handleToggleACHDualApproval = () => {
    // dual_approval_user_control gives user the ability to turn off either ACH or wire dual approval
    if (
      organization.ach_dual_approval_required &&
      !organization.dual_approval_user_control
    ) {
      return false;
    }

    // if org doesn't have ach_dual_approval_required turned on, then show the modal
    if (!organization.ach_dual_approval_required) {
      return showACHModal();
    }

    return toggleACHDualApproval();
  };

  const hasDualApprovals =
    features && (features.dual_approval || features.ach_dual_approvals);

  // if user doesn't have feature flag for any dual approvals, return nothing
  if (
    !(
      orgRole &&
      OrganizationUser.ROLES_WITH_EDIT_PERMISSION.includes(orgRole) &&
      features &&
      hasDualApprovals
    )
  ) {
    return null;
  }

  const {
    dual_approval: wireDualApprovalFeature,
    ach_dual_approvals: achDualApprovalFeature,
  } = features;

  const DualApprovalCheckbox = ({ required, toggle }) => {
    const displayCheckbox = () =>
      organization.hasMultipleUsers ? (
        <Checkbox
          data-testid="dual-approval-checkbox"
          toggle
          checked={required}
          onChange={toggle}
        />
      ) : (
        // if user does not have multiple users, show the create additional user account tooltip
        <Tooltip text={l10n.getString("additional-user-tooltip")}>
          <Checkbox
            data-testid="dual-approval-checkbox"
            toggle
            checked={required}
            onChange={toggle}
            disabled
          />
        </Tooltip>
      );

    // if dual approval is required and user hovers to turn off toggle, show the please contact support to turn off tooltip
    return required ? (
      <Tooltip text={l10n.getString("approval-disable-support")}>
        <Checkbox
          data-testid="dual-approval-checkbox"
          toggle
          checked={required}
          onChange={toggle}
        />
      </Tooltip>
    ) : (
      displayCheckbox()
    );
  };

  DualApprovalCheckbox.propTypes = {
    required: PropTypes.bool,
    toggle: PropTypes.func,
  };

  return (
    <div id="dual-approvals">
      <ContentCard>
        <h3>{l10n.getString("heading-dual-approvals")}</h3>
        <LoadingSkeleton isLoading={loading} content={"paragraph"}>
          <p className="margin--bottom--l margin--top--s">
            {l10n.getString("dual-approval-description", {
              shortName: short_name,
            })}
          </p>
        </LoadingSkeleton>
        {achDualApprovalFeature ? (
          <div className="margin--bottom--m">
            <Row>
              <Row.Item>
                <span className="fontSize--l fontWeight--bold">
                  ACH payments
                </span>
              </Row.Item>
              <Row.Item shrink>
                <LoadingSkeleton isLoading={loading} lines={1}>
                  <DualApprovalCheckbox
                    required={organization?.ach_dual_approval_required}
                    toggle={handleToggleACHDualApproval}
                  />
                  <Confirm
                    data-testid="ach-dual-approval-confirm"
                    header={l10n.getString("heading-dual-approvals")}
                    content={l10n.getString("confirm-enable-dual-approvals", {
                      shortName: short_name,
                    })}
                    cancelButton={l10n.getString("button-cancel")}
                    confirmButton={l10n.getString("button-confirm-turn-on")}
                    open={showACHConfirmationModal}
                    onConfirm={toggleACHDualApproval}
                    onCancel={closeACHModal}
                  />
                </LoadingSkeleton>
              </Row.Item>
            </Row>
          </div>
        ) : null}
        {wireDualApprovalFeature ? (
          <div className="margin--bottom--l">
            <Row>
              <Row.Item>
                <span className="fontSize--l fontWeight--bold">Wires</span>
              </Row.Item>
              <Row.Item shrink>
                <LoadingSkeleton isLoading={loading} lines={1}>
                  <DualApprovalCheckbox
                    required={organization?.dual_approval_required}
                    toggle={handleToggleWireDualApproval}
                  />
                  <Confirm
                    data-testid="dual-approval-confirm"
                    header={l10n.getString("heading-dual-approvals")}
                    content={l10n.getString("confirm-enable-dual-approvals", {
                      shortName: short_name,
                    })}
                    cancelButton={l10n.getString("button-cancel")}
                    confirmButton={l10n.getString("button-confirm-turn-on")}
                    open={showWireConfirmationModal}
                    s
                    onConfirm={toggleWireDualApproval}
                    onCancel={closeWireModal}
                  />
                </LoadingSkeleton>
              </Row.Item>
            </Row>
          </div>
        ) : null}
        <hr />
        <LoadingSkeleton isLoading={loading} content={"paragraph"}>
          <p className="fontSize--xs">
            {l10n.getString("dual-approval-disclosure", {
              shortName: short_name,
            })}
          </p>
        </LoadingSkeleton>
      </ContentCard>
    </div>
  );
};

const DualApprovalOptInContainer = () => (
  <InstitutionSettingsContextProvider>
    <DualApprovalOptIn />
  </InstitutionSettingsContextProvider>
);

export default DualApprovalOptInContainer;
